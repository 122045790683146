
export const oandaSymbolsData = [
    {
        "description": "EUR/HUF",
        "displaySymbol": "EUR/HUF",
        "hasWM": true,
        "symbol": "OANDA:EUR_HUF"
    },
    {
        "description": "France 40",
        "displaySymbol": "FR40/EUR",
        "hasWM": true,
        "symbol": "OANDA:FR40_EUR"
    },
    {
        "description": "EUR/DKK",
        "displaySymbol": "EUR/DKK",
        "hasWM": true,
        "symbol": "OANDA:EUR_DKK"
    },
    {
        "description": "Gold/CHF",
        "displaySymbol": "XAU/CHF",
        "hasWM": true,
        "symbol": "OANDA:XAU_CHF"
    },
    {
        "description": "Palladium",
        "displaySymbol": "XPD/USD",
        "hasWM": true,
        "symbol": "OANDA:XPD_USD"
    },
    {
        "description": "Brent Crude Oil",
        "displaySymbol": "BCO/USD",
        "hasWM": true,
        "symbol": "OANDA:BCO_USD"
    },
    {
        "description": "India 50",
        "displaySymbol": "IN50/USD",
        "hasWM": true,
        "symbol": "OANDA:IN50_USD"
    },
    {
        "description": "Natural Gas",
        "displaySymbol": "NATGAS/USD",
        "hasWM": true,
        "symbol": "OANDA:NATGAS_USD"
    },
    {
        "description": "USD/MXN",
        "displaySymbol": "USD/MXN",
        "hasWM": true,
        "symbol": "OANDA:USD_MXN"
    },
    {
        "description": "GBP/USD",
        "displaySymbol": "GBP/USD",
        "hasWM": true,
        "symbol": "OANDA:GBP_USD"
    },
    {
        "description": "CAD/CHF",
        "displaySymbol": "CAD/CHF",
        "hasWM": true,
        "symbol": "OANDA:CAD_CHF"
    },
    {
        "description": "EUR/GBP",
        "displaySymbol": "EUR/GBP",
        "hasWM": true,
        "symbol": "OANDA:EUR_GBP"
    },
    {
        "description": "Silver/SGD",
        "displaySymbol": "XAG/SGD",
        "hasWM": true,
        "symbol": "OANDA:XAG_SGD"
    },
    {
        "description": "Japan 225",
        "displaySymbol": "JP225/USD",
        "hasWM": true,
        "symbol": "OANDA:JP225_USD"
    },
    {
        "description": "Silver/CAD",
        "displaySymbol": "XAG/CAD",
        "hasWM": true,
        "symbol": "OANDA:XAG_CAD"
    },
    {
        "description": "China A50",
        "displaySymbol": "CN50/USD",
        "hasWM": true,
        "symbol": "OANDA:CN50_USD"
    },
    {
        "description": "Europe 50",
        "displaySymbol": "EU50/EUR",
        "hasWM": true,
        "symbol": "OANDA:EU50_EUR"
    },
    {
        "description": "GBP/CHF",
        "displaySymbol": "GBP/CHF",
        "hasWM": true,
        "symbol": "OANDA:GBP_CHF"
    },
    {
        "description": "USD/THB",
        "displaySymbol": "USD/THB",
        "hasWM": true,
        "symbol": "OANDA:USD_THB"
    },
    {
        "description": "USD/ZAR",
        "displaySymbol": "USD/ZAR",
        "hasWM": true,
        "symbol": "OANDA:USD_ZAR"
    },
    {
        "description": "Gold",
        "displaySymbol": "XAU/USD",
        "hasWM": true,
        "symbol": "OANDA:XAU_USD"
    },
    {
        "description": "Platinum",
        "displaySymbol": "XPT/USD",
        "hasWM": true,
        "symbol": "OANDA:XPT_USD"
    },
    {
        "description": "Gold/EUR",
        "displaySymbol": "XAU/EUR",
        "hasWM": true,
        "symbol": "OANDA:XAU_EUR"
    },
    {
        "description": "EUR/NOK",
        "displaySymbol": "EUR/NOK",
        "hasWM": true,
        "symbol": "OANDA:EUR_NOK"
    },
    {
        "description": "Germany 30",
        "displaySymbol": "DE30/EUR",
        "hasWM": true,
        "symbol": "OANDA:DE30_EUR"
    },
    {
        "description": "GBP/PLN",
        "displaySymbol": "GBP/PLN",
        "hasWM": true,
        "symbol": "OANDA:GBP_PLN"
    },
    {
        "description": "Gold/HKD",
        "displaySymbol": "XAU/HKD",
        "hasWM": true,
        "symbol": "OANDA:XAU_HKD"
    },
    {
        "description": "EUR/HKD",
        "displaySymbol": "EUR/HKD",
        "hasWM": true,
        "symbol": "OANDA:EUR_HKD"
    },
    {
        "description": "Gold/AUD",
        "displaySymbol": "XAU/AUD",
        "hasWM": true,
        "symbol": "OANDA:XAU_AUD"
    },
    {
        "description": "CHF/JPY",
        "displaySymbol": "CHF/JPY",
        "hasWM": true,
        "symbol": "OANDA:CHF_JPY"
    },
    {
        "description": "USD/SEK",
        "displaySymbol": "USD/SEK",
        "hasWM": true,
        "symbol": "OANDA:USD_SEK"
    },
    {
        "description": "GBP/SGD",
        "displaySymbol": "GBP/SGD",
        "hasWM": true,
        "symbol": "OANDA:GBP_SGD"
    },
    {
        "description": "GBP/HKD",
        "displaySymbol": "GBP/HKD",
        "hasWM": true,
        "symbol": "OANDA:GBP_HKD"
    },
    {
        "description": "EUR/NZD",
        "displaySymbol": "EUR/NZD",
        "hasWM": true,
        "symbol": "OANDA:EUR_NZD"
    },
    {
        "description": "Silver/AUD",
        "displaySymbol": "XAG/AUD",
        "hasWM": true,
        "symbol": "OANDA:XAG_AUD"
    },
    {
        "description": "West Texas Oil",
        "displaySymbol": "WTICO/USD",
        "hasWM": true,
        "symbol": "OANDA:WTICO_USD"
    },
    {
        "description": "Silver/NZD",
        "displaySymbol": "XAG/NZD",
        "hasWM": true,
        "symbol": "OANDA:XAG_NZD"
    },
    {
        "description": "SGD/CHF",
        "displaySymbol": "SGD/CHF",
        "hasWM": true,
        "symbol": "OANDA:SGD_CHF"
    },
    {
        "description": "AUD/SGD",
        "displaySymbol": "AUD/SGD",
        "hasWM": true,
        "symbol": "OANDA:AUD_SGD"
    },
    {
        "description": "EUR/JPY",
        "displaySymbol": "EUR/JPY",
        "hasWM": true,
        "symbol": "OANDA:EUR_JPY"
    },
    {
        "description": "Taiwan Index",
        "displaySymbol": "TWIX/USD",
        "hasWM": true,
        "symbol": "OANDA:TWIX_USD"
    },
    {
        "description": "USD/CHF",
        "displaySymbol": "USD/CHF",
        "hasWM": true,
        "symbol": "OANDA:USD_CHF"
    },
    {
        "description": "USD/TRY",
        "displaySymbol": "USD/TRY",
        "hasWM": true,
        "symbol": "OANDA:USD_TRY"
    },
    {
        "description": "GBP/JPY",
        "displaySymbol": "GBP/JPY",
        "hasWM": true,
        "symbol": "OANDA:GBP_JPY"
    },
    {
        "description": "EUR/CZK",
        "displaySymbol": "EUR/CZK",
        "hasWM": true,
        "symbol": "OANDA:EUR_CZK"
    },
    {
        "description": "EUR/TRY",
        "displaySymbol": "EUR/TRY",
        "hasWM": true,
        "symbol": "OANDA:EUR_TRY"
    },
    {
        "description": "USD/JPY",
        "displaySymbol": "USD/JPY",
        "hasWM": true,
        "symbol": "OANDA:USD_JPY"
    },
    {
        "description": "GBP/ZAR",
        "displaySymbol": "GBP/ZAR",
        "hasWM": true,
        "symbol": "OANDA:GBP_ZAR"
    },
    {
        "description": "SGD/JPY",
        "displaySymbol": "SGD/JPY",
        "hasWM": true,
        "symbol": "OANDA:SGD_JPY"
    },
    {
        "description": "Gold/SGD",
        "displaySymbol": "XAU/SGD",
        "hasWM": true,
        "symbol": "OANDA:XAU_SGD"
    },
    {
        "description": "USD/CZK",
        "displaySymbol": "USD/CZK",
        "hasWM": true,
        "symbol": "OANDA:USD_CZK"
    },
    {
        "description": "Gold/JPY",
        "displaySymbol": "XAU/JPY",
        "hasWM": true,
        "symbol": "OANDA:XAU_JPY"
    },
    {
        "description": "Silver/JPY",
        "displaySymbol": "XAG/JPY",
        "hasWM": true,
        "symbol": "OANDA:XAG_JPY"
    },
    {
        "description": "ZAR/JPY",
        "displaySymbol": "ZAR/JPY",
        "hasWM": true,
        "symbol": "OANDA:ZAR_JPY"
    },
    {
        "description": "TRY/JPY",
        "displaySymbol": "TRY/JPY",
        "hasWM": true,
        "symbol": "OANDA:TRY_JPY"
    },
    {
        "description": "USD/DKK",
        "displaySymbol": "USD/DKK",
        "hasWM": true,
        "symbol": "OANDA:USD_DKK"
    },
    {
        "description": "Japan 225 (JPY)",
        "displaySymbol": "JP225Y/JPY",
        "hasWM": true,
        "symbol": "OANDA:JP225Y_JPY"
    },
    {
        "description": "EUR/PLN",
        "displaySymbol": "EUR/PLN",
        "hasWM": true,
        "symbol": "OANDA:EUR_PLN"
    },
    {
        "description": "Sugar",
        "displaySymbol": "SUGAR/USD",
        "hasWM": true,
        "symbol": "OANDA:SUGAR_USD"
    },
    {
        "description": "AUD/CAD",
        "displaySymbol": "AUD/CAD",
        "hasWM": true,
        "symbol": "OANDA:AUD_CAD"
    },
    {
        "description": "USD/HKD",
        "displaySymbol": "USD/HKD",
        "hasWM": true,
        "symbol": "OANDA:USD_HKD"
    },
    {
        "description": "CAD/SGD",
        "displaySymbol": "CAD/SGD",
        "hasWM": true,
        "symbol": "OANDA:CAD_SGD"
    },
    {
        "description": "US Russ 2000",
        "displaySymbol": "US2000/USD",
        "hasWM": true,
        "symbol": "OANDA:US2000_USD"
    },
    {
        "description": "Bund",
        "displaySymbol": "DE10YB/EUR",
        "hasWM": true,
        "symbol": "OANDA:DE10YB_EUR"
    },
    {
        "description": "US 2Y T-Note",
        "displaySymbol": "USB02Y/USD",
        "hasWM": true,
        "symbol": "OANDA:USB02Y_USD"
    },
    {
        "description": "US 5Y T-Note",
        "displaySymbol": "USB05Y/USD",
        "hasWM": true,
        "symbol": "OANDA:USB05Y_USD"
    },
    {
        "description": "Hong Kong 33",
        "displaySymbol": "HK33/HKD",
        "hasWM": true,
        "symbol": "OANDA:HK33_HKD"
    },
    {
        "description": "US 10Y T-Note",
        "displaySymbol": "USB10Y/USD",
        "hasWM": true,
        "symbol": "OANDA:USB10Y_USD"
    },
    {
        "description": "GBP/NZD",
        "displaySymbol": "GBP/NZD",
        "hasWM": true,
        "symbol": "OANDA:GBP_NZD"
    },
    {
        "description": "CHF/ZAR",
        "displaySymbol": "CHF/ZAR",
        "hasWM": true,
        "symbol": "OANDA:CHF_ZAR"
    },
    {
        "description": "USD/CNH",
        "displaySymbol": "USD/CNH",
        "hasWM": true,
        "symbol": "OANDA:USD_CNH"
    },
    {
        "description": "Gold/NZD",
        "displaySymbol": "XAU/NZD",
        "hasWM": true,
        "symbol": "OANDA:XAU_NZD"
    },
    {
        "description": "NZD/USD",
        "displaySymbol": "NZD/USD",
        "hasWM": true,
        "symbol": "OANDA:NZD_USD"
    },
    {
        "description": "Silver/GBP",
        "displaySymbol": "XAG/GBP",
        "hasWM": true,
        "symbol": "OANDA:XAG_GBP"
    },
    {
        "description": "Silver/EUR",
        "displaySymbol": "XAG/EUR",
        "hasWM": true,
        "symbol": "OANDA:XAG_EUR"
    },
    {
        "description": "Corn",
        "displaySymbol": "CORN/USD",
        "hasWM": true,
        "symbol": "OANDA:CORN_USD"
    },
    {
        "description": "Silver",
        "displaySymbol": "XAG/USD",
        "hasWM": true,
        "symbol": "OANDA:XAG_USD"
    },
    {
        "description": "AUD/NZD",
        "displaySymbol": "AUD/NZD",
        "hasWM": true,
        "symbol": "OANDA:AUD_NZD"
    },
    {
        "description": "UK 100",
        "displaySymbol": "UK100/GBP",
        "hasWM": true,
        "symbol": "OANDA:UK100_GBP"
    },
    {
        "description": "NZD/HKD",
        "displaySymbol": "NZD/HKD",
        "hasWM": true,
        "symbol": "OANDA:NZD_HKD"
    },
    {
        "description": "USD/NOK",
        "displaySymbol": "USD/NOK",
        "hasWM": true,
        "symbol": "OANDA:USD_NOK"
    },
    {
        "description": "US SPX 500",
        "displaySymbol": "SPX500/USD",
        "hasWM": true,
        "symbol": "OANDA:SPX500_USD"
    },
    {
        "description": "Wheat",
        "displaySymbol": "WHEAT/USD",
        "hasWM": true,
        "symbol": "OANDA:WHEAT_USD"
    },
    {
        "description": "NZD/SGD",
        "displaySymbol": "NZD/SGD",
        "hasWM": true,
        "symbol": "OANDA:NZD_SGD"
    },
    {
        "description": "Copper",
        "displaySymbol": "XCU/USD",
        "hasWM": true,
        "symbol": "OANDA:XCU_USD"
    },
    {
        "description": "CAD/JPY",
        "displaySymbol": "CAD/JPY",
        "hasWM": true,
        "symbol": "OANDA:CAD_JPY"
    },
    {
        "description": "AUD/CHF",
        "displaySymbol": "AUD/CHF",
        "hasWM": true,
        "symbol": "OANDA:AUD_CHF"
    },
    {
        "description": "NZD/CHF",
        "displaySymbol": "NZD/CHF",
        "hasWM": true,
        "symbol": "OANDA:NZD_CHF"
    },
    {
        "description": "Silver/CHF",
        "displaySymbol": "XAG/CHF",
        "hasWM": true,
        "symbol": "OANDA:XAG_CHF"
    },
    {
        "description": "Gold/Silver",
        "displaySymbol": "XAU/XAG",
        "hasWM": true,
        "symbol": "OANDA:XAU_XAG"
    },
    {
        "description": "USD/SGD",
        "displaySymbol": "USD/SGD",
        "hasWM": true,
        "symbol": "OANDA:USD_SGD"
    },
    {
        "description": "EUR/SEK",
        "displaySymbol": "EUR/SEK",
        "hasWM": true,
        "symbol": "OANDA:EUR_SEK"
    },
    {
        "description": "HKD/JPY",
        "displaySymbol": "HKD/JPY",
        "hasWM": true,
        "symbol": "OANDA:HKD_JPY"
    },
    {
        "description": "AUD/HKD",
        "displaySymbol": "AUD/HKD",
        "hasWM": true,
        "symbol": "OANDA:AUD_HKD"
    },
    {
        "description": "US Nas 100",
        "displaySymbol": "NAS100/USD",
        "hasWM": true,
        "symbol": "OANDA:NAS100_USD"
    },
    {
        "description": "AUD/JPY",
        "displaySymbol": "AUD/JPY",
        "hasWM": true,
        "symbol": "OANDA:AUD_JPY"
    },
    {
        "description": "EUR/ZAR",
        "displaySymbol": "EUR/ZAR",
        "hasWM": true,
        "symbol": "OANDA:EUR_ZAR"
    },
    {
        "description": "Gold/CAD",
        "displaySymbol": "XAU/CAD",
        "hasWM": true,
        "symbol": "OANDA:XAU_CAD"
    },
    {
        "description": "EUR/AUD",
        "displaySymbol": "EUR/AUD",
        "hasWM": true,
        "symbol": "OANDA:EUR_AUD"
    },
    {
        "description": "NZD/JPY",
        "displaySymbol": "NZD/JPY",
        "hasWM": true,
        "symbol": "OANDA:NZD_JPY"
    },
    {
        "description": "Silver/HKD",
        "displaySymbol": "XAG/HKD",
        "hasWM": true,
        "symbol": "OANDA:XAG_HKD"
    },
    {
        "description": "Gold/GBP",
        "displaySymbol": "XAU/GBP",
        "hasWM": true,
        "symbol": "OANDA:XAU_GBP"
    },
    {
        "description": "CHF/HKD",
        "displaySymbol": "CHF/HKD",
        "hasWM": true,
        "symbol": "OANDA:CHF_HKD"
    },
    {
        "description": "USD/CAD",
        "displaySymbol": "USD/CAD",
        "hasWM": true,
        "symbol": "OANDA:USD_CAD"
    },
    {
        "description": "USD/INR",
        "displaySymbol": "USD/INR",
        "hasWM": true,
        "symbol": "OANDA:USD_INR"
    },
    {
        "description": "UK 10Y Gilt",
        "displaySymbol": "UK10YB/GBP",
        "hasWM": true,
        "symbol": "OANDA:UK10YB_GBP"
    },
    {
        "description": "EUR/CHF",
        "displaySymbol": "EUR/CHF",
        "hasWM": true,
        "symbol": "OANDA:EUR_CHF"
    },
    {
        "description": "Netherlands 25",
        "displaySymbol": "NL25/EUR",
        "hasWM": true,
        "symbol": "OANDA:NL25_EUR"
    },
    {
        "description": "EUR/CAD",
        "displaySymbol": "EUR/CAD",
        "hasWM": true,
        "symbol": "OANDA:EUR_CAD"
    },
    {
        "description": "USD/HUF",
        "displaySymbol": "USD/HUF",
        "hasWM": true,
        "symbol": "OANDA:USD_HUF"
    },
    {
        "description": "US T-Bond",
        "displaySymbol": "USB30Y/USD",
        "hasWM": true,
        "symbol": "OANDA:USB30Y_USD"
    },
    {
        "description": "NZD/CAD",
        "displaySymbol": "NZD/CAD",
        "hasWM": true,
        "symbol": "OANDA:NZD_CAD"
    },
    {
        "description": "EUR/SGD",
        "displaySymbol": "EUR/SGD",
        "hasWM": true,
        "symbol": "OANDA:EUR_SGD"
    },
    {
        "description": "AUD/USD",
        "displaySymbol": "AUD/USD",
        "hasWM": true,
        "symbol": "OANDA:AUD_USD"
    },
    {
        "description": "Soybeans",
        "displaySymbol": "SOYBN/USD",
        "hasWM": true,
        "symbol": "OANDA:SOYBN_USD"
    },
    {
        "description": "EUR/USD",
        "displaySymbol": "EUR/USD",
        "hasWM": true,
        "symbol": "OANDA:EUR_USD"
    },
    {
        "description": "Singapore 30",
        "displaySymbol": "SG30/SGD",
        "hasWM": true,
        "symbol": "OANDA:SG30_SGD"
    },
    {
        "description": "GBP/AUD",
        "displaySymbol": "GBP/AUD",
        "hasWM": true,
        "symbol": "OANDA:GBP_AUD"
    },
    {
        "description": "USD/PLN",
        "displaySymbol": "USD/PLN",
        "hasWM": true,
        "symbol": "OANDA:USD_PLN"
    },
    {
        "description": "Australia 200",
        "displaySymbol": "AU200/AUD",
        "hasWM": true,
        "symbol": "OANDA:AU200_AUD"
    },
    {
        "description": "CAD/HKD",
        "displaySymbol": "CAD/HKD",
        "hasWM": true,
        "symbol": "OANDA:CAD_HKD"
    },
    {
        "description": "US Wall St 30",
        "displaySymbol": "US30/USD",
        "hasWM": true,
        "symbol": "OANDA:US30_USD"
    },
    {
        "description": "GBP/CAD",
        "displaySymbol": "GBP/CAD",
        "hasWM": true,
        "symbol": "OANDA:GBP_CAD"
    }
];

export const binanceSymbolsData = [
    {
        "description": "Binance ETHBTC",
        "displaySymbol": "ETH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ETHBTC"
    },
    {
        "description": "Binance LTCBTC",
        "displaySymbol": "LTC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LTCBTC"
    },
    {
        "description": "Binance BNBBTC",
        "displaySymbol": "BNB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BNBBTC"
    },
    {
        "description": "Binance NEOBTC",
        "displaySymbol": "NEO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NEOBTC"
    },
    {
        "description": "Binance QTUMETH",
        "displaySymbol": "QTUM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:QTUMETH"
    },
    {
        "description": "Binance EOSETH",
        "displaySymbol": "EOS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:EOSETH"
    },
    {
        "description": "Binance SNTETH",
        "displaySymbol": "SNT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SNTETH"
    },
    {
        "description": "Binance BNTETH",
        "displaySymbol": "BNT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BNTETH"
    },
    {
        "description": "Binance BCCBTC",
        "displaySymbol": "BCC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BCCBTC"
    },
    {
        "description": "Binance GASBTC",
        "displaySymbol": "GAS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GASBTC"
    },
    {
        "description": "Binance BNBETH",
        "displaySymbol": "BNB/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BNBETH"
    },
    {
        "description": "Binance BTCUSDT",
        "displaySymbol": "BTC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTCUSDT"
    },
    {
        "description": "Binance ETHUSDT",
        "displaySymbol": "ETH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ETHUSDT"
    },
    {
        "description": "Binance HSRBTC",
        "displaySymbol": "HSR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HSRBTC"
    },
    {
        "description": "Binance OAXETH",
        "displaySymbol": "OAX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:OAXETH"
    },
    {
        "description": "Binance DNTETH",
        "displaySymbol": "DNT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DNTETH"
    },
    {
        "description": "Binance MCOETH",
        "displaySymbol": "MCO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MCOETH"
    },
    {
        "description": "Binance ICNETH",
        "displaySymbol": "ICN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ICNETH"
    },
    {
        "description": "Binance MCOBTC",
        "displaySymbol": "MCO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MCOBTC"
    },
    {
        "description": "Binance WTCBTC",
        "displaySymbol": "WTC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WTCBTC"
    },
    {
        "description": "Binance WTCETH",
        "displaySymbol": "WTC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:WTCETH"
    },
    {
        "description": "Binance LRCBTC",
        "displaySymbol": "LRC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LRCBTC"
    },
    {
        "description": "Binance LRCETH",
        "displaySymbol": "LRC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LRCETH"
    },
    {
        "description": "Binance QTUMBTC",
        "displaySymbol": "QTUM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:QTUMBTC"
    },
    {
        "description": "Binance YOYOBTC",
        "displaySymbol": "YOYO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:YOYOBTC"
    },
    {
        "description": "Binance OMGBTC",
        "displaySymbol": "OMG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OMGBTC"
    },
    {
        "description": "Binance OMGETH",
        "displaySymbol": "OMG/ETH",
        "hasWM": true,
        "symbol": "BINANCE:OMGETH"
    },
    {
        "description": "Binance ZRXBTC",
        "displaySymbol": "ZRX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ZRXBTC"
    },
    {
        "description": "Binance ZRXETH",
        "displaySymbol": "ZRX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ZRXETH"
    },
    {
        "description": "Binance STRATBTC",
        "displaySymbol": "STRAT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STRATBTC"
    },
    {
        "description": "Binance STRATETH",
        "displaySymbol": "STRAT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:STRATETH"
    },
    {
        "description": "Binance SNGLSBTC",
        "displaySymbol": "SNGLS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SNGLSBTC"
    },
    {
        "description": "Binance SNGLSETH",
        "displaySymbol": "SNGLS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SNGLSETH"
    },
    {
        "description": "Binance BQXBTC",
        "displaySymbol": "BQX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BQXBTC"
    },
    {
        "description": "Binance BQXETH",
        "displaySymbol": "BQX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BQXETH"
    },
    {
        "description": "Binance KNCBTC",
        "displaySymbol": "KNC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KNCBTC"
    },
    {
        "description": "Binance KNCETH",
        "displaySymbol": "KNC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:KNCETH"
    },
    {
        "description": "Binance FUNBTC",
        "displaySymbol": "FUN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FUNBTC"
    },
    {
        "description": "Binance FUNETH",
        "displaySymbol": "FUN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:FUNETH"
    },
    {
        "description": "Binance SNMBTC",
        "displaySymbol": "SNM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SNMBTC"
    },
    {
        "description": "Binance SNMETH",
        "displaySymbol": "SNM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SNMETH"
    },
    {
        "description": "Binance NEOETH",
        "displaySymbol": "NEO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NEOETH"
    },
    {
        "description": "Binance IOTABTC",
        "displaySymbol": "IOTA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:IOTABTC"
    },
    {
        "description": "Binance IOTAETH",
        "displaySymbol": "IOTA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:IOTAETH"
    },
    {
        "description": "Binance LINKBTC",
        "displaySymbol": "LINK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LINKBTC"
    },
    {
        "description": "Binance LINKETH",
        "displaySymbol": "LINK/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LINKETH"
    },
    {
        "description": "Binance XVGBTC",
        "displaySymbol": "XVG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XVGBTC"
    },
    {
        "description": "Binance XVGETH",
        "displaySymbol": "XVG/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XVGETH"
    },
    {
        "description": "Binance SALTBTC",
        "displaySymbol": "SALT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SALTBTC"
    },
    {
        "description": "Binance SALTETH",
        "displaySymbol": "SALT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SALTETH"
    },
    {
        "description": "Binance MDABTC",
        "displaySymbol": "MDA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MDABTC"
    },
    {
        "description": "Binance MDAETH",
        "displaySymbol": "MDA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MDAETH"
    },
    {
        "description": "Binance MTLBTC",
        "displaySymbol": "MTL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MTLBTC"
    },
    {
        "description": "Binance MTLETH",
        "displaySymbol": "MTL/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MTLETH"
    },
    {
        "description": "Binance SUBBTC",
        "displaySymbol": "SUB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SUBBTC"
    },
    {
        "description": "Binance SUBETH",
        "displaySymbol": "SUB/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SUBETH"
    },
    {
        "description": "Binance EOSBTC",
        "displaySymbol": "EOS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:EOSBTC"
    },
    {
        "description": "Binance SNTBTC",
        "displaySymbol": "SNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SNTBTC"
    },
    {
        "description": "Binance ETCETH",
        "displaySymbol": "ETC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ETCETH"
    },
    {
        "description": "Binance ETCBTC",
        "displaySymbol": "ETC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ETCBTC"
    },
    {
        "description": "Binance MTHBTC",
        "displaySymbol": "MTH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MTHBTC"
    },
    {
        "description": "Binance MTHETH",
        "displaySymbol": "MTH/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MTHETH"
    },
    {
        "description": "Binance ENGBTC",
        "displaySymbol": "ENG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ENGBTC"
    },
    {
        "description": "Binance ENGETH",
        "displaySymbol": "ENG/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ENGETH"
    },
    {
        "description": "Binance DNTBTC",
        "displaySymbol": "DNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DNTBTC"
    },
    {
        "description": "Binance ZECBTC",
        "displaySymbol": "ZEC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ZECBTC"
    },
    {
        "description": "Binance ZECETH",
        "displaySymbol": "ZEC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ZECETH"
    },
    {
        "description": "Binance BNTBTC",
        "displaySymbol": "BNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BNTBTC"
    },
    {
        "description": "Binance ASTBTC",
        "displaySymbol": "AST/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ASTBTC"
    },
    {
        "description": "Binance ASTETH",
        "displaySymbol": "AST/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ASTETH"
    },
    {
        "description": "Binance DASHBTC",
        "displaySymbol": "DASH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DASHBTC"
    },
    {
        "description": "Binance DASHETH",
        "displaySymbol": "DASH/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DASHETH"
    },
    {
        "description": "Binance OAXBTC",
        "displaySymbol": "OAX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OAXBTC"
    },
    {
        "description": "Binance ICNBTC",
        "displaySymbol": "ICN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ICNBTC"
    },
    {
        "description": "Binance BTGBTC",
        "displaySymbol": "BTG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BTGBTC"
    },
    {
        "description": "Binance BTGETH",
        "displaySymbol": "BTG/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BTGETH"
    },
    {
        "description": "Binance EVXBTC",
        "displaySymbol": "EVX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:EVXBTC"
    },
    {
        "description": "Binance EVXETH",
        "displaySymbol": "EVX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:EVXETH"
    },
    {
        "description": "Binance REQBTC",
        "displaySymbol": "REQ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:REQBTC"
    },
    {
        "description": "Binance REQETH",
        "displaySymbol": "REQ/ETH",
        "hasWM": true,
        "symbol": "BINANCE:REQETH"
    },
    {
        "description": "Binance VIBBTC",
        "displaySymbol": "VIB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VIBBTC"
    },
    {
        "description": "Binance VIBETH",
        "displaySymbol": "VIB/ETH",
        "hasWM": true,
        "symbol": "BINANCE:VIBETH"
    },
    {
        "description": "Binance HSRETH",
        "displaySymbol": "HSR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:HSRETH"
    },
    {
        "description": "Binance TRXBTC",
        "displaySymbol": "TRX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TRXBTC"
    },
    {
        "description": "Binance TRXETH",
        "displaySymbol": "TRX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:TRXETH"
    },
    {
        "description": "Binance POWRBTC",
        "displaySymbol": "POWR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:POWRBTC"
    },
    {
        "description": "Binance POWRETH",
        "displaySymbol": "POWR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:POWRETH"
    },
    {
        "description": "Binance ARKBTC",
        "displaySymbol": "ARK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ARKBTC"
    },
    {
        "description": "Binance ARKETH",
        "displaySymbol": "ARK/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ARKETH"
    },
    {
        "description": "Binance YOYOETH",
        "displaySymbol": "YOYO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:YOYOETH"
    },
    {
        "description": "Binance XRPBTC",
        "displaySymbol": "XRP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XRPBTC"
    },
    {
        "description": "Binance XRPETH",
        "displaySymbol": "XRP/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XRPETH"
    },
    {
        "description": "Binance MODBTC",
        "displaySymbol": "MOD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MODBTC"
    },
    {
        "description": "Binance MODETH",
        "displaySymbol": "MOD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MODETH"
    },
    {
        "description": "Binance ENJBTC",
        "displaySymbol": "ENJ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ENJBTC"
    },
    {
        "description": "Binance ENJETH",
        "displaySymbol": "ENJ/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ENJETH"
    },
    {
        "description": "Binance STORJBTC",
        "displaySymbol": "STORJ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STORJBTC"
    },
    {
        "description": "Binance STORJETH",
        "displaySymbol": "STORJ/ETH",
        "hasWM": true,
        "symbol": "BINANCE:STORJETH"
    },
    {
        "description": "Binance BNBUSDT",
        "displaySymbol": "BNB/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BNBUSDT"
    },
    {
        "description": "Binance VENBNB",
        "displaySymbol": "VEN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:VENBNB"
    },
    {
        "description": "Binance YOYOBNB",
        "displaySymbol": "YOYO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:YOYOBNB"
    },
    {
        "description": "Binance POWRBNB",
        "displaySymbol": "POWR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:POWRBNB"
    },
    {
        "description": "Binance VENBTC",
        "displaySymbol": "VEN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VENBTC"
    },
    {
        "description": "Binance VENETH",
        "displaySymbol": "VEN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:VENETH"
    },
    {
        "description": "Binance KMDBTC",
        "displaySymbol": "KMD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KMDBTC"
    },
    {
        "description": "Binance KMDETH",
        "displaySymbol": "KMD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:KMDETH"
    },
    {
        "description": "Binance NULSBNB",
        "displaySymbol": "NULS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NULSBNB"
    },
    {
        "description": "Binance RCNBTC",
        "displaySymbol": "RCN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RCNBTC"
    },
    {
        "description": "Binance RCNETH",
        "displaySymbol": "RCN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:RCNETH"
    },
    {
        "description": "Binance RCNBNB",
        "displaySymbol": "RCN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RCNBNB"
    },
    {
        "description": "Binance NULSBTC",
        "displaySymbol": "NULS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NULSBTC"
    },
    {
        "description": "Binance NULSETH",
        "displaySymbol": "NULS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NULSETH"
    },
    {
        "description": "Binance RDNBTC",
        "displaySymbol": "RDN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RDNBTC"
    },
    {
        "description": "Binance RDNETH",
        "displaySymbol": "RDN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:RDNETH"
    },
    {
        "description": "Binance RDNBNB",
        "displaySymbol": "RDN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RDNBNB"
    },
    {
        "description": "Binance XMRBTC",
        "displaySymbol": "XMR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XMRBTC"
    },
    {
        "description": "Binance XMRETH",
        "displaySymbol": "XMR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XMRETH"
    },
    {
        "description": "Binance DLTBNB",
        "displaySymbol": "DLT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DLTBNB"
    },
    {
        "description": "Binance WTCBNB",
        "displaySymbol": "WTC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WTCBNB"
    },
    {
        "description": "Binance DLTBTC",
        "displaySymbol": "DLT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DLTBTC"
    },
    {
        "description": "Binance DLTETH",
        "displaySymbol": "DLT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DLTETH"
    },
    {
        "description": "Binance AMBBTC",
        "displaySymbol": "AMB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AMBBTC"
    },
    {
        "description": "Binance AMBETH",
        "displaySymbol": "AMB/ETH",
        "hasWM": true,
        "symbol": "BINANCE:AMBETH"
    },
    {
        "description": "Binance AMBBNB",
        "displaySymbol": "AMB/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AMBBNB"
    },
    {
        "description": "Binance BCCETH",
        "displaySymbol": "BCC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BCCETH"
    },
    {
        "description": "Binance BCCUSDT",
        "displaySymbol": "BCC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BCCUSDT"
    },
    {
        "description": "Binance BCCBNB",
        "displaySymbol": "BCC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BCCBNB"
    },
    {
        "description": "Binance BATBTC",
        "displaySymbol": "BAT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BATBTC"
    },
    {
        "description": "Binance BATETH",
        "displaySymbol": "BAT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BATETH"
    },
    {
        "description": "Binance BATBNB",
        "displaySymbol": "BAT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BATBNB"
    },
    {
        "description": "Binance BCPTBTC",
        "displaySymbol": "BCPT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BCPTBTC"
    },
    {
        "description": "Binance BCPTETH",
        "displaySymbol": "BCPT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BCPTETH"
    },
    {
        "description": "Binance BCPTBNB",
        "displaySymbol": "BCPT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BCPTBNB"
    },
    {
        "description": "Binance ARNBTC",
        "displaySymbol": "ARN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ARNBTC"
    },
    {
        "description": "Binance ARNETH",
        "displaySymbol": "ARN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ARNETH"
    },
    {
        "description": "Binance GVTBTC",
        "displaySymbol": "GVT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GVTBTC"
    },
    {
        "description": "Binance GVTETH",
        "displaySymbol": "GVT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GVTETH"
    },
    {
        "description": "Binance CDTBTC",
        "displaySymbol": "CDT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CDTBTC"
    },
    {
        "description": "Binance CDTETH",
        "displaySymbol": "CDT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CDTETH"
    },
    {
        "description": "Binance GXSBTC",
        "displaySymbol": "GXS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GXSBTC"
    },
    {
        "description": "Binance GXSETH",
        "displaySymbol": "GXS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GXSETH"
    },
    {
        "description": "Binance NEOUSDT",
        "displaySymbol": "NEO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NEOUSDT"
    },
    {
        "description": "Binance NEOBNB",
        "displaySymbol": "NEO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NEOBNB"
    },
    {
        "description": "Binance POEBTC",
        "displaySymbol": "POE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:POEBTC"
    },
    {
        "description": "Binance POEETH",
        "displaySymbol": "POE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:POEETH"
    },
    {
        "description": "Binance QSPBTC",
        "displaySymbol": "QSP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:QSPBTC"
    },
    {
        "description": "Binance QSPETH",
        "displaySymbol": "QSP/ETH",
        "hasWM": true,
        "symbol": "BINANCE:QSPETH"
    },
    {
        "description": "Binance QSPBNB",
        "displaySymbol": "QSP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:QSPBNB"
    },
    {
        "description": "Binance BTSBTC",
        "displaySymbol": "BTS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BTSBTC"
    },
    {
        "description": "Binance BTSETH",
        "displaySymbol": "BTS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BTSETH"
    },
    {
        "description": "Binance BTSBNB",
        "displaySymbol": "BTS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BTSBNB"
    },
    {
        "description": "Binance XZCBTC",
        "displaySymbol": "XZC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XZCBTC"
    },
    {
        "description": "Binance XZCETH",
        "displaySymbol": "XZC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XZCETH"
    },
    {
        "description": "Binance XZCBNB",
        "displaySymbol": "XZC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:XZCBNB"
    },
    {
        "description": "Binance LSKBTC",
        "displaySymbol": "LSK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LSKBTC"
    },
    {
        "description": "Binance LSKETH",
        "displaySymbol": "LSK/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LSKETH"
    },
    {
        "description": "Binance LSKBNB",
        "displaySymbol": "LSK/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LSKBNB"
    },
    {
        "description": "Binance TNTBTC",
        "displaySymbol": "TNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TNTBTC"
    },
    {
        "description": "Binance TNTETH",
        "displaySymbol": "TNT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:TNTETH"
    },
    {
        "description": "Binance FUELBTC",
        "displaySymbol": "FUEL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FUELBTC"
    },
    {
        "description": "Binance FUELETH",
        "displaySymbol": "FUEL/ETH",
        "hasWM": true,
        "symbol": "BINANCE:FUELETH"
    },
    {
        "description": "Binance MANABTC",
        "displaySymbol": "MANA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MANABTC"
    },
    {
        "description": "Binance MANAETH",
        "displaySymbol": "MANA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MANAETH"
    },
    {
        "description": "Binance BCDBTC",
        "displaySymbol": "BCD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BCDBTC"
    },
    {
        "description": "Binance BCDETH",
        "displaySymbol": "BCD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BCDETH"
    },
    {
        "description": "Binance DGDBTC",
        "displaySymbol": "DGD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DGDBTC"
    },
    {
        "description": "Binance DGDETH",
        "displaySymbol": "DGD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DGDETH"
    },
    {
        "description": "Binance IOTABNB",
        "displaySymbol": "IOTA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:IOTABNB"
    },
    {
        "description": "Binance ADXBTC",
        "displaySymbol": "ADX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ADXBTC"
    },
    {
        "description": "Binance ADXETH",
        "displaySymbol": "ADX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ADXETH"
    },
    {
        "description": "Binance ADXBNB",
        "displaySymbol": "ADX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ADXBNB"
    },
    {
        "description": "Binance ADABTC",
        "displaySymbol": "ADA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ADABTC"
    },
    {
        "description": "Binance ADAETH",
        "displaySymbol": "ADA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ADAETH"
    },
    {
        "description": "Binance PPTBTC",
        "displaySymbol": "PPT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PPTBTC"
    },
    {
        "description": "Binance PPTETH",
        "displaySymbol": "PPT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:PPTETH"
    },
    {
        "description": "Binance CMTBTC",
        "displaySymbol": "CMT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CMTBTC"
    },
    {
        "description": "Binance CMTETH",
        "displaySymbol": "CMT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CMTETH"
    },
    {
        "description": "Binance CMTBNB",
        "displaySymbol": "CMT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CMTBNB"
    },
    {
        "description": "Binance XLMBTC",
        "displaySymbol": "XLM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XLMBTC"
    },
    {
        "description": "Binance XLMETH",
        "displaySymbol": "XLM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XLMETH"
    },
    {
        "description": "Binance XLMBNB",
        "displaySymbol": "XLM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:XLMBNB"
    },
    {
        "description": "Binance CNDBTC",
        "displaySymbol": "CND/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CNDBTC"
    },
    {
        "description": "Binance CNDETH",
        "displaySymbol": "CND/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CNDETH"
    },
    {
        "description": "Binance CNDBNB",
        "displaySymbol": "CND/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CNDBNB"
    },
    {
        "description": "Binance LENDBTC",
        "displaySymbol": "LEND/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LENDBTC"
    },
    {
        "description": "Binance LENDETH",
        "displaySymbol": "LEND/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LENDETH"
    },
    {
        "description": "Binance WABIBTC",
        "displaySymbol": "WABI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WABIBTC"
    },
    {
        "description": "Binance WABIETH",
        "displaySymbol": "WABI/ETH",
        "hasWM": true,
        "symbol": "BINANCE:WABIETH"
    },
    {
        "description": "Binance WABIBNB",
        "displaySymbol": "WABI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WABIBNB"
    },
    {
        "description": "Binance LTCETH",
        "displaySymbol": "LTC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LTCETH"
    },
    {
        "description": "Binance LTCUSDT",
        "displaySymbol": "LTC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LTCUSDT"
    },
    {
        "description": "Binance LTCBNB",
        "displaySymbol": "LTC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LTCBNB"
    },
    {
        "description": "Binance TNBBTC",
        "displaySymbol": "TNB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TNBBTC"
    },
    {
        "description": "Binance TNBETH",
        "displaySymbol": "TNB/ETH",
        "hasWM": true,
        "symbol": "BINANCE:TNBETH"
    },
    {
        "description": "Binance WAVESBTC",
        "displaySymbol": "WAVES/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WAVESBTC"
    },
    {
        "description": "Binance WAVESETH",
        "displaySymbol": "WAVES/ETH",
        "hasWM": true,
        "symbol": "BINANCE:WAVESETH"
    },
    {
        "description": "Binance WAVESBNB",
        "displaySymbol": "WAVES/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WAVESBNB"
    },
    {
        "description": "Binance GTOBTC",
        "displaySymbol": "GTO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GTOBTC"
    },
    {
        "description": "Binance GTOETH",
        "displaySymbol": "GTO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GTOETH"
    },
    {
        "description": "Binance GTOBNB",
        "displaySymbol": "GTO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GTOBNB"
    },
    {
        "description": "Binance ICXBTC",
        "displaySymbol": "ICX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ICXBTC"
    },
    {
        "description": "Binance ICXETH",
        "displaySymbol": "ICX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ICXETH"
    },
    {
        "description": "Binance ICXBNB",
        "displaySymbol": "ICX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ICXBNB"
    },
    {
        "description": "Binance OSTBTC",
        "displaySymbol": "OST/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OSTBTC"
    },
    {
        "description": "Binance OSTETH",
        "displaySymbol": "OST/ETH",
        "hasWM": true,
        "symbol": "BINANCE:OSTETH"
    },
    {
        "description": "Binance OSTBNB",
        "displaySymbol": "OST/BNB",
        "hasWM": true,
        "symbol": "BINANCE:OSTBNB"
    },
    {
        "description": "Binance ELFBTC",
        "displaySymbol": "ELF/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ELFBTC"
    },
    {
        "description": "Binance ELFETH",
        "displaySymbol": "ELF/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ELFETH"
    },
    {
        "description": "Binance AIONBTC",
        "displaySymbol": "AION/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AIONBTC"
    },
    {
        "description": "Binance AIONETH",
        "displaySymbol": "AION/ETH",
        "hasWM": true,
        "symbol": "BINANCE:AIONETH"
    },
    {
        "description": "Binance AIONBNB",
        "displaySymbol": "AION/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AIONBNB"
    },
    {
        "description": "Binance NEBLBTC",
        "displaySymbol": "NEBL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NEBLBTC"
    },
    {
        "description": "Binance NEBLBNB",
        "displaySymbol": "NEBL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NEBLBNB"
    },
    {
        "description": "Binance BRDBTC",
        "displaySymbol": "BRD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BRDBTC"
    },
    {
        "description": "Binance BRDETH",
        "displaySymbol": "BRD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BRDETH"
    },
    {
        "description": "Binance BRDBNB",
        "displaySymbol": "BRD/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BRDBNB"
    },
    {
        "description": "Binance MCOBNB",
        "displaySymbol": "MCO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MCOBNB"
    },
    {
        "description": "Binance EDOBTC",
        "displaySymbol": "EDO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:EDOBTC"
    },
    {
        "description": "Binance EDOETH",
        "displaySymbol": "EDO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:EDOETH"
    },
    {
        "description": "Binance WINGSBTC",
        "displaySymbol": "WINGS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WINGSBTC"
    },
    {
        "description": "Binance WINGSETH",
        "displaySymbol": "WINGS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:WINGSETH"
    },
    {
        "description": "Binance NAVBTC",
        "displaySymbol": "NAV/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NAVBTC"
    },
    {
        "description": "Binance NAVETH",
        "displaySymbol": "NAV/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NAVETH"
    },
    {
        "description": "Binance NAVBNB",
        "displaySymbol": "NAV/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NAVBNB"
    },
    {
        "description": "Binance LUNBTC",
        "displaySymbol": "LUN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LUNBTC"
    },
    {
        "description": "Binance LUNETH",
        "displaySymbol": "LUN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LUNETH"
    },
    {
        "description": "Binance TRIGBTC",
        "displaySymbol": "TRIG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TRIGBTC"
    },
    {
        "description": "Binance TRIGETH",
        "displaySymbol": "TRIG/ETH",
        "hasWM": true,
        "symbol": "BINANCE:TRIGETH"
    },
    {
        "description": "Binance TRIGBNB",
        "displaySymbol": "TRIG/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TRIGBNB"
    },
    {
        "description": "Binance APPCBTC",
        "displaySymbol": "APPC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:APPCBTC"
    },
    {
        "description": "Binance APPCETH",
        "displaySymbol": "APPC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:APPCETH"
    },
    {
        "description": "Binance APPCBNB",
        "displaySymbol": "APPC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:APPCBNB"
    },
    {
        "description": "Binance VIBEBTC",
        "displaySymbol": "VIBE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VIBEBTC"
    },
    {
        "description": "Binance VIBEETH",
        "displaySymbol": "VIBE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:VIBEETH"
    },
    {
        "description": "Binance RLCBTC",
        "displaySymbol": "RLC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RLCBTC"
    },
    {
        "description": "Binance RLCETH",
        "displaySymbol": "RLC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:RLCETH"
    },
    {
        "description": "Binance RLCBNB",
        "displaySymbol": "RLC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RLCBNB"
    },
    {
        "description": "Binance INSBTC",
        "displaySymbol": "INS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:INSBTC"
    },
    {
        "description": "Binance INSETH",
        "displaySymbol": "INS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:INSETH"
    },
    {
        "description": "Binance PIVXBTC",
        "displaySymbol": "PIVX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PIVXBTC"
    },
    {
        "description": "Binance PIVXBNB",
        "displaySymbol": "PIVX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PIVXBNB"
    },
    {
        "description": "Binance IOSTBTC",
        "displaySymbol": "IOST/BTC",
        "hasWM": true,
        "symbol": "BINANCE:IOSTBTC"
    },
    {
        "description": "Binance IOSTETH",
        "displaySymbol": "IOST/ETH",
        "hasWM": true,
        "symbol": "BINANCE:IOSTETH"
    },
    {
        "description": "Binance CHATBTC",
        "displaySymbol": "CHAT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CHATBTC"
    },
    {
        "description": "Binance CHATETH",
        "displaySymbol": "CHAT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CHATETH"
    },
    {
        "description": "Binance STEEMBTC",
        "displaySymbol": "STEEM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STEEMBTC"
    },
    {
        "description": "Binance STEEMETH",
        "displaySymbol": "STEEM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:STEEMETH"
    },
    {
        "description": "Binance STEEMBNB",
        "displaySymbol": "STEEM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:STEEMBNB"
    },
    {
        "description": "Binance NANOBTC",
        "displaySymbol": "NANO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NANOBTC"
    },
    {
        "description": "Binance NANOETH",
        "displaySymbol": "NANO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NANOETH"
    },
    {
        "description": "Binance NANOBNB",
        "displaySymbol": "NANO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NANOBNB"
    },
    {
        "description": "Binance VIABTC",
        "displaySymbol": "VIA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VIABTC"
    },
    {
        "description": "Binance VIAETH",
        "displaySymbol": "VIA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:VIAETH"
    },
    {
        "description": "Binance VIABNB",
        "displaySymbol": "VIA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:VIABNB"
    },
    {
        "description": "Binance BLZBTC",
        "displaySymbol": "BLZ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BLZBTC"
    },
    {
        "description": "Binance BLZETH",
        "displaySymbol": "BLZ/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BLZETH"
    },
    {
        "description": "Binance BLZBNB",
        "displaySymbol": "BLZ/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BLZBNB"
    },
    {
        "description": "Binance AEBTC",
        "displaySymbol": "AE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AEBTC"
    },
    {
        "description": "Binance AEETH",
        "displaySymbol": "AE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:AEETH"
    },
    {
        "description": "Binance AEBNB",
        "displaySymbol": "AE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AEBNB"
    },
    {
        "description": "Binance RPXBTC",
        "displaySymbol": "RPX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RPXBTC"
    },
    {
        "description": "Binance RPXETH",
        "displaySymbol": "RPX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:RPXETH"
    },
    {
        "description": "Binance RPXBNB",
        "displaySymbol": "RPX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RPXBNB"
    },
    {
        "description": "Binance NCASHBTC",
        "displaySymbol": "NCASH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NCASHBTC"
    },
    {
        "description": "Binance NCASHETH",
        "displaySymbol": "NCASH/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NCASHETH"
    },
    {
        "description": "Binance NCASHBNB",
        "displaySymbol": "NCASH/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NCASHBNB"
    },
    {
        "description": "Binance POABTC",
        "displaySymbol": "POA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:POABTC"
    },
    {
        "description": "Binance POAETH",
        "displaySymbol": "POA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:POAETH"
    },
    {
        "description": "Binance POABNB",
        "displaySymbol": "POA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:POABNB"
    },
    {
        "description": "Binance ZILBTC",
        "displaySymbol": "ZIL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ZILBTC"
    },
    {
        "description": "Binance ZILETH",
        "displaySymbol": "ZIL/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ZILETH"
    },
    {
        "description": "Binance ZILBNB",
        "displaySymbol": "ZIL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ZILBNB"
    },
    {
        "description": "Binance ONTBTC",
        "displaySymbol": "ONT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ONTBTC"
    },
    {
        "description": "Binance ONTETH",
        "displaySymbol": "ONT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ONTETH"
    },
    {
        "description": "Binance ONTBNB",
        "displaySymbol": "ONT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ONTBNB"
    },
    {
        "description": "Binance STORMBTC",
        "displaySymbol": "STORM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STORMBTC"
    },
    {
        "description": "Binance STORMETH",
        "displaySymbol": "STORM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:STORMETH"
    },
    {
        "description": "Binance STORMBNB",
        "displaySymbol": "STORM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:STORMBNB"
    },
    {
        "description": "Binance QTUMBNB",
        "displaySymbol": "QTUM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:QTUMBNB"
    },
    {
        "description": "Binance QTUMUSDT",
        "displaySymbol": "QTUM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:QTUMUSDT"
    },
    {
        "description": "Binance XEMBTC",
        "displaySymbol": "XEM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XEMBTC"
    },
    {
        "description": "Binance XEMETH",
        "displaySymbol": "XEM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XEMETH"
    },
    {
        "description": "Binance XEMBNB",
        "displaySymbol": "XEM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:XEMBNB"
    },
    {
        "description": "Binance WANBTC",
        "displaySymbol": "WAN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WANBTC"
    },
    {
        "description": "Binance WANETH",
        "displaySymbol": "WAN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:WANETH"
    },
    {
        "description": "Binance WANBNB",
        "displaySymbol": "WAN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WANBNB"
    },
    {
        "description": "Binance WPRBTC",
        "displaySymbol": "WPR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WPRBTC"
    },
    {
        "description": "Binance WPRETH",
        "displaySymbol": "WPR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:WPRETH"
    },
    {
        "description": "Binance QLCBTC",
        "displaySymbol": "QLC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:QLCBTC"
    },
    {
        "description": "Binance QLCETH",
        "displaySymbol": "QLC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:QLCETH"
    },
    {
        "description": "Binance SYSBTC",
        "displaySymbol": "SYS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SYSBTC"
    },
    {
        "description": "Binance SYSETH",
        "displaySymbol": "SYS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SYSETH"
    },
    {
        "description": "Binance SYSBNB",
        "displaySymbol": "SYS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SYSBNB"
    },
    {
        "description": "Binance QLCBNB",
        "displaySymbol": "QLC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:QLCBNB"
    },
    {
        "description": "Binance GRSBTC",
        "displaySymbol": "GRS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GRSBTC"
    },
    {
        "description": "Binance GRSETH",
        "displaySymbol": "GRS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GRSETH"
    },
    {
        "description": "Binance ADAUSDT",
        "displaySymbol": "ADA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ADAUSDT"
    },
    {
        "description": "Binance ADABNB",
        "displaySymbol": "ADA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ADABNB"
    },
    {
        "description": "Binance CLOAKBTC",
        "displaySymbol": "CLOAK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CLOAKBTC"
    },
    {
        "description": "Binance CLOAKETH",
        "displaySymbol": "CLOAK/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CLOAKETH"
    },
    {
        "description": "Binance GNTBTC",
        "displaySymbol": "GNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GNTBTC"
    },
    {
        "description": "Binance GNTETH",
        "displaySymbol": "GNT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GNTETH"
    },
    {
        "description": "Binance GNTBNB",
        "displaySymbol": "GNT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GNTBNB"
    },
    {
        "description": "Binance LOOMBTC",
        "displaySymbol": "LOOM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LOOMBTC"
    },
    {
        "description": "Binance LOOMETH",
        "displaySymbol": "LOOM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LOOMETH"
    },
    {
        "description": "Binance LOOMBNB",
        "displaySymbol": "LOOM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LOOMBNB"
    },
    {
        "description": "Binance XRPUSDT",
        "displaySymbol": "XRP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XRPUSDT"
    },
    {
        "description": "Binance BCNBTC",
        "displaySymbol": "BCN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BCNBTC"
    },
    {
        "description": "Binance BCNETH",
        "displaySymbol": "BCN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BCNETH"
    },
    {
        "description": "Binance BCNBNB",
        "displaySymbol": "BCN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BCNBNB"
    },
    {
        "description": "Binance REPBTC",
        "displaySymbol": "REP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:REPBTC"
    },
    {
        "description": "Binance REPBNB",
        "displaySymbol": "REP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:REPBNB"
    },
    {
        "description": "Binance BTCTUSD",
        "displaySymbol": "BTC/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTCTUSD"
    },
    {
        "description": "Binance TUSDBTC",
        "displaySymbol": "TUSD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TUSDBTC"
    },
    {
        "description": "Binance ETHTUSD",
        "displaySymbol": "ETH/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ETHTUSD"
    },
    {
        "description": "Binance TUSDETH",
        "displaySymbol": "TUSD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:TUSDETH"
    },
    {
        "description": "Binance TUSDBNB",
        "displaySymbol": "TUSD/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TUSDBNB"
    },
    {
        "description": "Binance ZENBTC",
        "displaySymbol": "ZEN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ZENBTC"
    },
    {
        "description": "Binance ZENETH",
        "displaySymbol": "ZEN/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ZENETH"
    },
    {
        "description": "Binance ZENBNB",
        "displaySymbol": "ZEN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ZENBNB"
    },
    {
        "description": "Binance SKYBTC",
        "displaySymbol": "SKY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SKYBTC"
    },
    {
        "description": "Binance SKYETH",
        "displaySymbol": "SKY/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SKYETH"
    },
    {
        "description": "Binance SKYBNB",
        "displaySymbol": "SKY/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SKYBNB"
    },
    {
        "description": "Binance EOSUSDT",
        "displaySymbol": "EOS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EOSUSDT"
    },
    {
        "description": "Binance EOSBNB",
        "displaySymbol": "EOS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:EOSBNB"
    },
    {
        "description": "Binance CVCBTC",
        "displaySymbol": "CVC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CVCBTC"
    },
    {
        "description": "Binance CVCETH",
        "displaySymbol": "CVC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CVCETH"
    },
    {
        "description": "Binance CVCBNB",
        "displaySymbol": "CVC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CVCBNB"
    },
    {
        "description": "Binance THETABTC",
        "displaySymbol": "THETA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:THETABTC"
    },
    {
        "description": "Binance THETAETH",
        "displaySymbol": "THETA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:THETAETH"
    },
    {
        "description": "Binance THETABNB",
        "displaySymbol": "THETA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:THETABNB"
    },
    {
        "description": "Binance XRPBNB",
        "displaySymbol": "XRP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:XRPBNB"
    },
    {
        "description": "Binance TUSDUSDT",
        "displaySymbol": "TUSD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TUSDUSDT"
    },
    {
        "description": "Binance IOTAUSDT",
        "displaySymbol": "IOTA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:IOTAUSDT"
    },
    {
        "description": "Binance XLMUSDT",
        "displaySymbol": "XLM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XLMUSDT"
    },
    {
        "description": "Binance IOTXBTC",
        "displaySymbol": "IOTX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:IOTXBTC"
    },
    {
        "description": "Binance IOTXETH",
        "displaySymbol": "IOTX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:IOTXETH"
    },
    {
        "description": "Binance QKCBTC",
        "displaySymbol": "QKC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:QKCBTC"
    },
    {
        "description": "Binance QKCETH",
        "displaySymbol": "QKC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:QKCETH"
    },
    {
        "description": "Binance AGIBTC",
        "displaySymbol": "AGI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AGIBTC"
    },
    {
        "description": "Binance AGIETH",
        "displaySymbol": "AGI/ETH",
        "hasWM": true,
        "symbol": "BINANCE:AGIETH"
    },
    {
        "description": "Binance AGIBNB",
        "displaySymbol": "AGI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AGIBNB"
    },
    {
        "description": "Binance NXSBTC",
        "displaySymbol": "NXS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NXSBTC"
    },
    {
        "description": "Binance NXSETH",
        "displaySymbol": "NXS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NXSETH"
    },
    {
        "description": "Binance NXSBNB",
        "displaySymbol": "NXS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NXSBNB"
    },
    {
        "description": "Binance ENJBNB",
        "displaySymbol": "ENJ/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ENJBNB"
    },
    {
        "description": "Binance DATABTC",
        "displaySymbol": "DATA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DATABTC"
    },
    {
        "description": "Binance DATAETH",
        "displaySymbol": "DATA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DATAETH"
    },
    {
        "description": "Binance ONTUSDT",
        "displaySymbol": "ONT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ONTUSDT"
    },
    {
        "description": "Binance TRXBNB",
        "displaySymbol": "TRX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TRXBNB"
    },
    {
        "description": "Binance TRXUSDT",
        "displaySymbol": "TRX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TRXUSDT"
    },
    {
        "description": "Binance ETCUSDT",
        "displaySymbol": "ETC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ETCUSDT"
    },
    {
        "description": "Binance ETCBNB",
        "displaySymbol": "ETC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ETCBNB"
    },
    {
        "description": "Binance ICXUSDT",
        "displaySymbol": "ICX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ICXUSDT"
    },
    {
        "description": "Binance SCBTC",
        "displaySymbol": "SC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SCBTC"
    },
    {
        "description": "Binance SCETH",
        "displaySymbol": "SC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SCETH"
    },
    {
        "description": "Binance NPXSBTC",
        "displaySymbol": "NPXS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NPXSBTC"
    },
    {
        "description": "Binance NPXSETH",
        "displaySymbol": "NPXS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NPXSETH"
    },
    {
        "description": "Binance VENUSDT",
        "displaySymbol": "VEN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:VENUSDT"
    },
    {
        "description": "Binance KEYBTC",
        "displaySymbol": "KEY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KEYBTC"
    },
    {
        "description": "Binance KEYETH",
        "displaySymbol": "KEY/ETH",
        "hasWM": true,
        "symbol": "BINANCE:KEYETH"
    },
    {
        "description": "Binance NASBTC",
        "displaySymbol": "NAS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NASBTC"
    },
    {
        "description": "Binance NASETH",
        "displaySymbol": "NAS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NASETH"
    },
    {
        "description": "Binance NASBNB",
        "displaySymbol": "NAS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NASBNB"
    },
    {
        "description": "Binance MFTBTC",
        "displaySymbol": "MFT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MFTBTC"
    },
    {
        "description": "Binance MFTETH",
        "displaySymbol": "MFT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MFTETH"
    },
    {
        "description": "Binance MFTBNB",
        "displaySymbol": "MFT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MFTBNB"
    },
    {
        "description": "Binance DENTBTC",
        "displaySymbol": "DENT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DENTBTC"
    },
    {
        "description": "Binance DENTETH",
        "displaySymbol": "DENT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DENTETH"
    },
    {
        "description": "Binance ARDRBTC",
        "displaySymbol": "ARDR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ARDRBTC"
    },
    {
        "description": "Binance ARDRETH",
        "displaySymbol": "ARDR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ARDRETH"
    },
    {
        "description": "Binance ARDRBNB",
        "displaySymbol": "ARDR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ARDRBNB"
    },
    {
        "description": "Binance NULSUSDT",
        "displaySymbol": "NULS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NULSUSDT"
    },
    {
        "description": "Binance HOTBTC",
        "displaySymbol": "HOT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HOTBTC"
    },
    {
        "description": "Binance HOTETH",
        "displaySymbol": "HOT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:HOTETH"
    },
    {
        "description": "Binance VETBTC",
        "displaySymbol": "VET/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VETBTC"
    },
    {
        "description": "Binance VETETH",
        "displaySymbol": "VET/ETH",
        "hasWM": true,
        "symbol": "BINANCE:VETETH"
    },
    {
        "description": "Binance VETUSDT",
        "displaySymbol": "VET/USDT",
        "hasWM": true,
        "symbol": "BINANCE:VETUSDT"
    },
    {
        "description": "Binance VETBNB",
        "displaySymbol": "VET/BNB",
        "hasWM": true,
        "symbol": "BINANCE:VETBNB"
    },
    {
        "description": "Binance DOCKBTC",
        "displaySymbol": "DOCK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DOCKBTC"
    },
    {
        "description": "Binance DOCKETH",
        "displaySymbol": "DOCK/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DOCKETH"
    },
    {
        "description": "Binance POLYBTC",
        "displaySymbol": "POLY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:POLYBTC"
    },
    {
        "description": "Binance POLYBNB",
        "displaySymbol": "POLY/BNB",
        "hasWM": true,
        "symbol": "BINANCE:POLYBNB"
    },
    {
        "description": "Binance PHXBTC",
        "displaySymbol": "PHX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PHXBTC"
    },
    {
        "description": "Binance PHXETH",
        "displaySymbol": "PHX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:PHXETH"
    },
    {
        "description": "Binance PHXBNB",
        "displaySymbol": "PHX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PHXBNB"
    },
    {
        "description": "Binance HCBTC",
        "displaySymbol": "HC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HCBTC"
    },
    {
        "description": "Binance HCETH",
        "displaySymbol": "HC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:HCETH"
    },
    {
        "description": "Binance GOBTC",
        "displaySymbol": "GO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GOBTC"
    },
    {
        "description": "Binance GOBNB",
        "displaySymbol": "GO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GOBNB"
    },
    {
        "description": "Binance PAXBTC",
        "displaySymbol": "PAX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PAXBTC"
    },
    {
        "description": "Binance PAXBNB",
        "displaySymbol": "PAX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PAXBNB"
    },
    {
        "description": "Binance PAXUSDT",
        "displaySymbol": "PAX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PAXUSDT"
    },
    {
        "description": "Binance PAXETH",
        "displaySymbol": "PAX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:PAXETH"
    },
    {
        "description": "Binance RVNBTC",
        "displaySymbol": "RVN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RVNBTC"
    },
    {
        "description": "Binance DCRBTC",
        "displaySymbol": "DCR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DCRBTC"
    },
    {
        "description": "Binance DCRBNB",
        "displaySymbol": "DCR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DCRBNB"
    },
    {
        "description": "Binance USDCBNB",
        "displaySymbol": "USDC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:USDCBNB"
    },
    {
        "description": "Binance MITHBTC",
        "displaySymbol": "MITH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MITHBTC"
    },
    {
        "description": "Binance MITHBNB",
        "displaySymbol": "MITH/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MITHBNB"
    },
    {
        "description": "Binance BCHABCBTC",
        "displaySymbol": "BCHABC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BCHABCBTC"
    },
    {
        "description": "Binance BCHSVBTC",
        "displaySymbol": "BCHSV/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BCHSVBTC"
    },
    {
        "description": "Binance BCHABCUSDT",
        "displaySymbol": "BCHABC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BCHABCUSDT"
    },
    {
        "description": "Binance BCHSVUSDT",
        "displaySymbol": "BCHSV/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BCHSVUSDT"
    },
    {
        "description": "Binance BNBPAX",
        "displaySymbol": "BNB/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BNBPAX"
    },
    {
        "description": "Binance BTCPAX",
        "displaySymbol": "BTC/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BTCPAX"
    },
    {
        "description": "Binance ETHPAX",
        "displaySymbol": "ETH/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ETHPAX"
    },
    {
        "description": "Binance XRPPAX",
        "displaySymbol": "XRP/PAX",
        "hasWM": true,
        "symbol": "BINANCE:XRPPAX"
    },
    {
        "description": "Binance EOSPAX",
        "displaySymbol": "EOS/PAX",
        "hasWM": true,
        "symbol": "BINANCE:EOSPAX"
    },
    {
        "description": "Binance XLMPAX",
        "displaySymbol": "XLM/PAX",
        "hasWM": true,
        "symbol": "BINANCE:XLMPAX"
    },
    {
        "description": "Binance RENBTC",
        "displaySymbol": "REN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RENBTC"
    },
    {
        "description": "Binance RENBNB",
        "displaySymbol": "REN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RENBNB"
    },
    {
        "description": "Binance BNBTUSD",
        "displaySymbol": "BNB/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BNBTUSD"
    },
    {
        "description": "Binance XRPTUSD",
        "displaySymbol": "XRP/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:XRPTUSD"
    },
    {
        "description": "Binance EOSTUSD",
        "displaySymbol": "EOS/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:EOSTUSD"
    },
    {
        "description": "Binance XLMTUSD",
        "displaySymbol": "XLM/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:XLMTUSD"
    },
    {
        "description": "Binance BNBUSDC",
        "displaySymbol": "BNB/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BNBUSDC"
    },
    {
        "description": "Binance BTCUSDC",
        "displaySymbol": "BTC/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BTCUSDC"
    },
    {
        "description": "Binance ETHUSDC",
        "displaySymbol": "ETH/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ETHUSDC"
    },
    {
        "description": "Binance XRPUSDC",
        "displaySymbol": "XRP/USDC",
        "hasWM": true,
        "symbol": "BINANCE:XRPUSDC"
    },
    {
        "description": "Binance EOSUSDC",
        "displaySymbol": "EOS/USDC",
        "hasWM": true,
        "symbol": "BINANCE:EOSUSDC"
    },
    {
        "description": "Binance XLMUSDC",
        "displaySymbol": "XLM/USDC",
        "hasWM": true,
        "symbol": "BINANCE:XLMUSDC"
    },
    {
        "description": "Binance USDCUSDT",
        "displaySymbol": "USDC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:USDCUSDT"
    },
    {
        "description": "Binance ADATUSD",
        "displaySymbol": "ADA/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ADATUSD"
    },
    {
        "description": "Binance TRXTUSD",
        "displaySymbol": "TRX/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:TRXTUSD"
    },
    {
        "description": "Binance NEOTUSD",
        "displaySymbol": "NEO/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:NEOTUSD"
    },
    {
        "description": "Binance TRXXRP",
        "displaySymbol": "TRX/XRP",
        "hasWM": true,
        "symbol": "BINANCE:TRXXRP"
    },
    {
        "description": "Binance XZCXRP",
        "displaySymbol": "XZC/XRP",
        "hasWM": true,
        "symbol": "BINANCE:XZCXRP"
    },
    {
        "description": "Binance PAXTUSD",
        "displaySymbol": "PAX/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:PAXTUSD"
    },
    {
        "description": "Binance USDCTUSD",
        "displaySymbol": "USDC/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:USDCTUSD"
    },
    {
        "description": "Binance USDCPAX",
        "displaySymbol": "USDC/PAX",
        "hasWM": true,
        "symbol": "BINANCE:USDCPAX"
    },
    {
        "description": "Binance LINKUSDT",
        "displaySymbol": "LINK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LINKUSDT"
    },
    {
        "description": "Binance LINKTUSD",
        "displaySymbol": "LINK/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:LINKTUSD"
    },
    {
        "description": "Binance LINKPAX",
        "displaySymbol": "LINK/PAX",
        "hasWM": true,
        "symbol": "BINANCE:LINKPAX"
    },
    {
        "description": "Binance LINKUSDC",
        "displaySymbol": "LINK/USDC",
        "hasWM": true,
        "symbol": "BINANCE:LINKUSDC"
    },
    {
        "description": "Binance WAVESUSDT",
        "displaySymbol": "WAVES/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WAVESUSDT"
    },
    {
        "description": "Binance WAVESTUSD",
        "displaySymbol": "WAVES/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:WAVESTUSD"
    },
    {
        "description": "Binance WAVESPAX",
        "displaySymbol": "WAVES/PAX",
        "hasWM": true,
        "symbol": "BINANCE:WAVESPAX"
    },
    {
        "description": "Binance WAVESUSDC",
        "displaySymbol": "WAVES/USDC",
        "hasWM": true,
        "symbol": "BINANCE:WAVESUSDC"
    },
    {
        "description": "Binance BCHABCTUSD",
        "displaySymbol": "BCHABC/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BCHABCTUSD"
    },
    {
        "description": "Binance BCHABCPAX",
        "displaySymbol": "BCHABC/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BCHABCPAX"
    },
    {
        "description": "Binance BCHABCUSDC",
        "displaySymbol": "BCHABC/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BCHABCUSDC"
    },
    {
        "description": "Binance BCHSVTUSD",
        "displaySymbol": "BCHSV/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BCHSVTUSD"
    },
    {
        "description": "Binance BCHSVPAX",
        "displaySymbol": "BCHSV/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BCHSVPAX"
    },
    {
        "description": "Binance BCHSVUSDC",
        "displaySymbol": "BCHSV/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BCHSVUSDC"
    },
    {
        "description": "Binance LTCTUSD",
        "displaySymbol": "LTC/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:LTCTUSD"
    },
    {
        "description": "Binance LTCPAX",
        "displaySymbol": "LTC/PAX",
        "hasWM": true,
        "symbol": "BINANCE:LTCPAX"
    },
    {
        "description": "Binance LTCUSDC",
        "displaySymbol": "LTC/USDC",
        "hasWM": true,
        "symbol": "BINANCE:LTCUSDC"
    },
    {
        "description": "Binance TRXPAX",
        "displaySymbol": "TRX/PAX",
        "hasWM": true,
        "symbol": "BINANCE:TRXPAX"
    },
    {
        "description": "Binance TRXUSDC",
        "displaySymbol": "TRX/USDC",
        "hasWM": true,
        "symbol": "BINANCE:TRXUSDC"
    },
    {
        "description": "Binance BTTBTC",
        "displaySymbol": "BTT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BTTBTC"
    },
    {
        "description": "Binance BTTBNB",
        "displaySymbol": "BTT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BTTBNB"
    },
    {
        "description": "Binance BTTUSDT",
        "displaySymbol": "BTT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTTUSDT"
    },
    {
        "description": "Binance BNBUSDS",
        "displaySymbol": "BNB/USDS",
        "hasWM": true,
        "symbol": "BINANCE:BNBUSDS"
    },
    {
        "description": "Binance BTCUSDS",
        "displaySymbol": "BTC/USDS",
        "hasWM": true,
        "symbol": "BINANCE:BTCUSDS"
    },
    {
        "description": "Binance USDSUSDT",
        "displaySymbol": "USDS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:USDSUSDT"
    },
    {
        "description": "Binance USDSPAX",
        "displaySymbol": "USDS/PAX",
        "hasWM": true,
        "symbol": "BINANCE:USDSPAX"
    },
    {
        "description": "Binance USDSTUSD",
        "displaySymbol": "USDS/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:USDSTUSD"
    },
    {
        "description": "Binance USDSUSDC",
        "displaySymbol": "USDS/USDC",
        "hasWM": true,
        "symbol": "BINANCE:USDSUSDC"
    },
    {
        "description": "Binance BTTPAX",
        "displaySymbol": "BTT/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BTTPAX"
    },
    {
        "description": "Binance BTTTUSD",
        "displaySymbol": "BTT/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTTTUSD"
    },
    {
        "description": "Binance BTTUSDC",
        "displaySymbol": "BTT/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BTTUSDC"
    },
    {
        "description": "Binance ONGBNB",
        "displaySymbol": "ONG/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ONGBNB"
    },
    {
        "description": "Binance ONGBTC",
        "displaySymbol": "ONG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ONGBTC"
    },
    {
        "description": "Binance ONGUSDT",
        "displaySymbol": "ONG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ONGUSDT"
    },
    {
        "description": "Binance HOTBNB",
        "displaySymbol": "HOT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:HOTBNB"
    },
    {
        "description": "Binance HOTUSDT",
        "displaySymbol": "HOT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:HOTUSDT"
    },
    {
        "description": "Binance ZILUSDT",
        "displaySymbol": "ZIL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ZILUSDT"
    },
    {
        "description": "Binance ZRXBNB",
        "displaySymbol": "ZRX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ZRXBNB"
    },
    {
        "description": "Binance ZRXUSDT",
        "displaySymbol": "ZRX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ZRXUSDT"
    },
    {
        "description": "Binance FETBNB",
        "displaySymbol": "FET/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FETBNB"
    },
    {
        "description": "Binance FETBTC",
        "displaySymbol": "FET/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FETBTC"
    },
    {
        "description": "Binance FETUSDT",
        "displaySymbol": "FET/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FETUSDT"
    },
    {
        "description": "Binance BATUSDT",
        "displaySymbol": "BAT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BATUSDT"
    },
    {
        "description": "Binance XMRBNB",
        "displaySymbol": "XMR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:XMRBNB"
    },
    {
        "description": "Binance XMRUSDT",
        "displaySymbol": "XMR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XMRUSDT"
    },
    {
        "description": "Binance ZECBNB",
        "displaySymbol": "ZEC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ZECBNB"
    },
    {
        "description": "Binance ZECUSDT",
        "displaySymbol": "ZEC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ZECUSDT"
    },
    {
        "description": "Binance ZECPAX",
        "displaySymbol": "ZEC/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ZECPAX"
    },
    {
        "description": "Binance ZECTUSD",
        "displaySymbol": "ZEC/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ZECTUSD"
    },
    {
        "description": "Binance ZECUSDC",
        "displaySymbol": "ZEC/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ZECUSDC"
    },
    {
        "description": "Binance IOSTUSDT",
        "displaySymbol": "IOST/USDT",
        "hasWM": true,
        "symbol": "BINANCE:IOSTUSDT"
    },
    {
        "description": "Binance CELRBNB",
        "displaySymbol": "CELR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CELRBNB"
    },
    {
        "description": "Binance CELRBTC",
        "displaySymbol": "CELR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CELRBTC"
    },
    {
        "description": "Binance CELRUSDT",
        "displaySymbol": "CELR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CELRUSDT"
    },
    {
        "description": "Binance ADAPAX",
        "displaySymbol": "ADA/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ADAPAX"
    },
    {
        "description": "Binance ADAUSDC",
        "displaySymbol": "ADA/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ADAUSDC"
    },
    {
        "description": "Binance NEOPAX",
        "displaySymbol": "NEO/PAX",
        "hasWM": true,
        "symbol": "BINANCE:NEOPAX"
    },
    {
        "description": "Binance NEOUSDC",
        "displaySymbol": "NEO/USDC",
        "hasWM": true,
        "symbol": "BINANCE:NEOUSDC"
    },
    {
        "description": "Binance DASHBNB",
        "displaySymbol": "DASH/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DASHBNB"
    },
    {
        "description": "Binance DASHUSDT",
        "displaySymbol": "DASH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DASHUSDT"
    },
    {
        "description": "Binance NANOUSDT",
        "displaySymbol": "NANO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NANOUSDT"
    },
    {
        "description": "Binance OMGBNB",
        "displaySymbol": "OMG/BNB",
        "hasWM": true,
        "symbol": "BINANCE:OMGBNB"
    },
    {
        "description": "Binance OMGUSDT",
        "displaySymbol": "OMG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OMGUSDT"
    },
    {
        "description": "Binance THETAUSDT",
        "displaySymbol": "THETA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:THETAUSDT"
    },
    {
        "description": "Binance ENJUSDT",
        "displaySymbol": "ENJ/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ENJUSDT"
    },
    {
        "description": "Binance MITHUSDT",
        "displaySymbol": "MITH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MITHUSDT"
    },
    {
        "description": "Binance MATICBNB",
        "displaySymbol": "MATIC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MATICBNB"
    },
    {
        "description": "Binance MATICBTC",
        "displaySymbol": "MATIC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MATICBTC"
    },
    {
        "description": "Binance MATICUSDT",
        "displaySymbol": "MATIC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MATICUSDT"
    },
    {
        "description": "Binance ATOMBNB",
        "displaySymbol": "ATOM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ATOMBNB"
    },
    {
        "description": "Binance ATOMBTC",
        "displaySymbol": "ATOM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ATOMBTC"
    },
    {
        "description": "Binance ATOMUSDT",
        "displaySymbol": "ATOM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ATOMUSDT"
    },
    {
        "description": "Binance ATOMUSDC",
        "displaySymbol": "ATOM/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ATOMUSDC"
    },
    {
        "description": "Binance ATOMPAX",
        "displaySymbol": "ATOM/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ATOMPAX"
    },
    {
        "description": "Binance ATOMTUSD",
        "displaySymbol": "ATOM/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ATOMTUSD"
    },
    {
        "description": "Binance ETCUSDC",
        "displaySymbol": "ETC/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ETCUSDC"
    },
    {
        "description": "Binance ETCPAX",
        "displaySymbol": "ETC/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ETCPAX"
    },
    {
        "description": "Binance ETCTUSD",
        "displaySymbol": "ETC/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ETCTUSD"
    },
    {
        "description": "Binance BATUSDC",
        "displaySymbol": "BAT/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BATUSDC"
    },
    {
        "description": "Binance BATPAX",
        "displaySymbol": "BAT/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BATPAX"
    },
    {
        "description": "Binance BATTUSD",
        "displaySymbol": "BAT/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BATTUSD"
    },
    {
        "description": "Binance PHBBNB",
        "displaySymbol": "PHB/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PHBBNB"
    },
    {
        "description": "Binance PHBBTC",
        "displaySymbol": "PHB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PHBBTC"
    },
    {
        "description": "Binance PHBUSDC",
        "displaySymbol": "PHB/USDC",
        "hasWM": true,
        "symbol": "BINANCE:PHBUSDC"
    },
    {
        "description": "Binance PHBTUSD",
        "displaySymbol": "PHB/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:PHBTUSD"
    },
    {
        "description": "Binance PHBPAX",
        "displaySymbol": "PHB/PAX",
        "hasWM": true,
        "symbol": "BINANCE:PHBPAX"
    },
    {
        "description": "Binance TFUELBNB",
        "displaySymbol": "TFUEL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TFUELBNB"
    },
    {
        "description": "Binance TFUELBTC",
        "displaySymbol": "TFUEL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TFUELBTC"
    },
    {
        "description": "Binance TFUELUSDT",
        "displaySymbol": "TFUEL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TFUELUSDT"
    },
    {
        "description": "Binance TFUELUSDC",
        "displaySymbol": "TFUEL/USDC",
        "hasWM": true,
        "symbol": "BINANCE:TFUELUSDC"
    },
    {
        "description": "Binance TFUELTUSD",
        "displaySymbol": "TFUEL/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:TFUELTUSD"
    },
    {
        "description": "Binance TFUELPAX",
        "displaySymbol": "TFUEL/PAX",
        "hasWM": true,
        "symbol": "BINANCE:TFUELPAX"
    },
    {
        "description": "Binance ONEBNB",
        "displaySymbol": "ONE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ONEBNB"
    },
    {
        "description": "Binance ONEBTC",
        "displaySymbol": "ONE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ONEBTC"
    },
    {
        "description": "Binance ONEUSDT",
        "displaySymbol": "ONE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ONEUSDT"
    },
    {
        "description": "Binance ONETUSD",
        "displaySymbol": "ONE/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ONETUSD"
    },
    {
        "description": "Binance ONEPAX",
        "displaySymbol": "ONE/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ONEPAX"
    },
    {
        "description": "Binance ONEUSDC",
        "displaySymbol": "ONE/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ONEUSDC"
    },
    {
        "description": "Binance FTMBNB",
        "displaySymbol": "FTM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FTMBNB"
    },
    {
        "description": "Binance FTMBTC",
        "displaySymbol": "FTM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FTMBTC"
    },
    {
        "description": "Binance FTMUSDT",
        "displaySymbol": "FTM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FTMUSDT"
    },
    {
        "description": "Binance FTMTUSD",
        "displaySymbol": "FTM/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:FTMTUSD"
    },
    {
        "description": "Binance FTMPAX",
        "displaySymbol": "FTM/PAX",
        "hasWM": true,
        "symbol": "BINANCE:FTMPAX"
    },
    {
        "description": "Binance FTMUSDC",
        "displaySymbol": "FTM/USDC",
        "hasWM": true,
        "symbol": "BINANCE:FTMUSDC"
    },
    {
        "description": "Binance BTCBBTC",
        "displaySymbol": "BTCB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BTCBBTC"
    },
    {
        "description": "Binance BCPTTUSD",
        "displaySymbol": "BCPT/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BCPTTUSD"
    },
    {
        "description": "Binance BCPTPAX",
        "displaySymbol": "BCPT/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BCPTPAX"
    },
    {
        "description": "Binance BCPTUSDC",
        "displaySymbol": "BCPT/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BCPTUSDC"
    },
    {
        "description": "Binance ALGOBNB",
        "displaySymbol": "ALGO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ALGOBNB"
    },
    {
        "description": "Binance ALGOBTC",
        "displaySymbol": "ALGO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ALGOBTC"
    },
    {
        "description": "Binance ALGOUSDT",
        "displaySymbol": "ALGO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ALGOUSDT"
    },
    {
        "description": "Binance ALGOTUSD",
        "displaySymbol": "ALGO/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ALGOTUSD"
    },
    {
        "description": "Binance ALGOPAX",
        "displaySymbol": "ALGO/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ALGOPAX"
    },
    {
        "description": "Binance ALGOUSDC",
        "displaySymbol": "ALGO/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ALGOUSDC"
    },
    {
        "description": "Binance USDSBUSDT",
        "displaySymbol": "USDSB/USDT",
        "hasWM": true,
        "symbol": "BINANCE:USDSBUSDT"
    },
    {
        "description": "Binance USDSBUSDS",
        "displaySymbol": "USDSB/USDS",
        "hasWM": true,
        "symbol": "BINANCE:USDSBUSDS"
    },
    {
        "description": "Binance GTOUSDT",
        "displaySymbol": "GTO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GTOUSDT"
    },
    {
        "description": "Binance GTOPAX",
        "displaySymbol": "GTO/PAX",
        "hasWM": true,
        "symbol": "BINANCE:GTOPAX"
    },
    {
        "description": "Binance GTOTUSD",
        "displaySymbol": "GTO/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:GTOTUSD"
    },
    {
        "description": "Binance GTOUSDC",
        "displaySymbol": "GTO/USDC",
        "hasWM": true,
        "symbol": "BINANCE:GTOUSDC"
    },
    {
        "description": "Binance ERDBNB",
        "displaySymbol": "ERD/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ERDBNB"
    },
    {
        "description": "Binance ERDBTC",
        "displaySymbol": "ERD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ERDBTC"
    },
    {
        "description": "Binance ERDUSDT",
        "displaySymbol": "ERD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ERDUSDT"
    },
    {
        "description": "Binance ERDPAX",
        "displaySymbol": "ERD/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ERDPAX"
    },
    {
        "description": "Binance ERDUSDC",
        "displaySymbol": "ERD/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ERDUSDC"
    },
    {
        "description": "Binance DOGEBNB",
        "displaySymbol": "DOGE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DOGEBNB"
    },
    {
        "description": "Binance DOGEBTC",
        "displaySymbol": "DOGE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DOGEBTC"
    },
    {
        "description": "Binance DOGEUSDT",
        "displaySymbol": "DOGE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DOGEUSDT"
    },
    {
        "description": "Binance DOGEPAX",
        "displaySymbol": "DOGE/PAX",
        "hasWM": true,
        "symbol": "BINANCE:DOGEPAX"
    },
    {
        "description": "Binance DOGEUSDC",
        "displaySymbol": "DOGE/USDC",
        "hasWM": true,
        "symbol": "BINANCE:DOGEUSDC"
    },
    {
        "description": "Binance DUSKBNB",
        "displaySymbol": "DUSK/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DUSKBNB"
    },
    {
        "description": "Binance DUSKBTC",
        "displaySymbol": "DUSK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DUSKBTC"
    },
    {
        "description": "Binance DUSKUSDT",
        "displaySymbol": "DUSK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DUSKUSDT"
    },
    {
        "description": "Binance DUSKUSDC",
        "displaySymbol": "DUSK/USDC",
        "hasWM": true,
        "symbol": "BINANCE:DUSKUSDC"
    },
    {
        "description": "Binance DUSKPAX",
        "displaySymbol": "DUSK/PAX",
        "hasWM": true,
        "symbol": "BINANCE:DUSKPAX"
    },
    {
        "description": "Binance BGBPUSDC",
        "displaySymbol": "BGBP/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BGBPUSDC"
    },
    {
        "description": "Binance ANKRBNB",
        "displaySymbol": "ANKR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ANKRBNB"
    },
    {
        "description": "Binance ANKRBTC",
        "displaySymbol": "ANKR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ANKRBTC"
    },
    {
        "description": "Binance ANKRUSDT",
        "displaySymbol": "ANKR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ANKRUSDT"
    },
    {
        "description": "Binance ANKRTUSD",
        "displaySymbol": "ANKR/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:ANKRTUSD"
    },
    {
        "description": "Binance ANKRPAX",
        "displaySymbol": "ANKR/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ANKRPAX"
    },
    {
        "description": "Binance ANKRUSDC",
        "displaySymbol": "ANKR/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ANKRUSDC"
    },
    {
        "description": "Binance ONTPAX",
        "displaySymbol": "ONT/PAX",
        "hasWM": true,
        "symbol": "BINANCE:ONTPAX"
    },
    {
        "description": "Binance ONTUSDC",
        "displaySymbol": "ONT/USDC",
        "hasWM": true,
        "symbol": "BINANCE:ONTUSDC"
    },
    {
        "description": "Binance WINBNB",
        "displaySymbol": "WIN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WINBNB"
    },
    {
        "description": "Binance WINBTC",
        "displaySymbol": "WIN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WINBTC"
    },
    {
        "description": "Binance WINUSDT",
        "displaySymbol": "WIN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WINUSDT"
    },
    {
        "description": "Binance WINUSDC",
        "displaySymbol": "WIN/USDC",
        "hasWM": true,
        "symbol": "BINANCE:WINUSDC"
    },
    {
        "description": "Binance COSBNB",
        "displaySymbol": "COS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:COSBNB"
    },
    {
        "description": "Binance COSBTC",
        "displaySymbol": "COS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:COSBTC"
    },
    {
        "description": "Binance COSUSDT",
        "displaySymbol": "COS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:COSUSDT"
    },
    {
        "description": "Binance TUSDBTUSD",
        "displaySymbol": "TUSDB/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:TUSDBTUSD"
    },
    {
        "description": "Binance NPXSUSDT",
        "displaySymbol": "NPXS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NPXSUSDT"
    },
    {
        "description": "Binance NPXSUSDC",
        "displaySymbol": "NPXS/USDC",
        "hasWM": true,
        "symbol": "BINANCE:NPXSUSDC"
    },
    {
        "description": "Binance COCOSBNB",
        "displaySymbol": "COCOS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:COCOSBNB"
    },
    {
        "description": "Binance COCOSBTC",
        "displaySymbol": "COCOS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:COCOSBTC"
    },
    {
        "description": "Binance COCOSUSDT",
        "displaySymbol": "COCOS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:COCOSUSDT"
    },
    {
        "description": "Binance MTLUSDT",
        "displaySymbol": "MTL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MTLUSDT"
    },
    {
        "description": "Binance TOMOBNB",
        "displaySymbol": "TOMO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TOMOBNB"
    },
    {
        "description": "Binance TOMOBTC",
        "displaySymbol": "TOMO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TOMOBTC"
    },
    {
        "description": "Binance TOMOUSDT",
        "displaySymbol": "TOMO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TOMOUSDT"
    },
    {
        "description": "Binance TOMOUSDC",
        "displaySymbol": "TOMO/USDC",
        "hasWM": true,
        "symbol": "BINANCE:TOMOUSDC"
    },
    {
        "description": "Binance PERLBNB",
        "displaySymbol": "PERL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PERLBNB"
    },
    {
        "description": "Binance PERLBTC",
        "displaySymbol": "PERL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PERLBTC"
    },
    {
        "description": "Binance PERLUSDC",
        "displaySymbol": "PERL/USDC",
        "hasWM": true,
        "symbol": "BINANCE:PERLUSDC"
    },
    {
        "description": "Binance PERLUSDT",
        "displaySymbol": "PERL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PERLUSDT"
    },
    {
        "description": "Binance DENTUSDT",
        "displaySymbol": "DENT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DENTUSDT"
    },
    {
        "description": "Binance MFTUSDT",
        "displaySymbol": "MFT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MFTUSDT"
    },
    {
        "description": "Binance KEYUSDT",
        "displaySymbol": "KEY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KEYUSDT"
    },
    {
        "description": "Binance STORMUSDT",
        "displaySymbol": "STORM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STORMUSDT"
    },
    {
        "description": "Binance DOCKUSDT",
        "displaySymbol": "DOCK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DOCKUSDT"
    },
    {
        "description": "Binance WANUSDT",
        "displaySymbol": "WAN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WANUSDT"
    },
    {
        "description": "Binance FUNUSDT",
        "displaySymbol": "FUN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FUNUSDT"
    },
    {
        "description": "Binance CVCUSDT",
        "displaySymbol": "CVC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CVCUSDT"
    },
    {
        "description": "Binance BTTTRX",
        "displaySymbol": "BTT/TRX",
        "hasWM": true,
        "symbol": "BINANCE:BTTTRX"
    },
    {
        "description": "Binance WINTRX",
        "displaySymbol": "WIN/TRX",
        "hasWM": true,
        "symbol": "BINANCE:WINTRX"
    },
    {
        "description": "Binance CHZBNB",
        "displaySymbol": "CHZ/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CHZBNB"
    },
    {
        "description": "Binance CHZBTC",
        "displaySymbol": "CHZ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CHZBTC"
    },
    {
        "description": "Binance CHZUSDT",
        "displaySymbol": "CHZ/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CHZUSDT"
    },
    {
        "description": "Binance BANDBNB",
        "displaySymbol": "BAND/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BANDBNB"
    },
    {
        "description": "Binance BANDBTC",
        "displaySymbol": "BAND/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BANDBTC"
    },
    {
        "description": "Binance BANDUSDT",
        "displaySymbol": "BAND/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BANDUSDT"
    },
    {
        "description": "Binance BNBBUSD",
        "displaySymbol": "BNB/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BNBBUSD"
    },
    {
        "description": "Binance BTCBUSD",
        "displaySymbol": "BTC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTCBUSD"
    },
    {
        "description": "Binance BUSDUSDT",
        "displaySymbol": "BUSD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BUSDUSDT"
    },
    {
        "description": "Binance BEAMBNB",
        "displaySymbol": "BEAM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BEAMBNB"
    },
    {
        "description": "Binance BEAMBTC",
        "displaySymbol": "BEAM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BEAMBTC"
    },
    {
        "description": "Binance BEAMUSDT",
        "displaySymbol": "BEAM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BEAMUSDT"
    },
    {
        "description": "Binance XTZBNB",
        "displaySymbol": "XTZ/BNB",
        "hasWM": true,
        "symbol": "BINANCE:XTZBNB"
    },
    {
        "description": "Binance XTZBTC",
        "displaySymbol": "XTZ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XTZBTC"
    },
    {
        "description": "Binance XTZUSDT",
        "displaySymbol": "XTZ/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XTZUSDT"
    },
    {
        "description": "Binance RENUSDT",
        "displaySymbol": "REN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RENUSDT"
    },
    {
        "description": "Binance RVNUSDT",
        "displaySymbol": "RVN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RVNUSDT"
    },
    {
        "description": "Binance HCUSDT",
        "displaySymbol": "HC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:HCUSDT"
    },
    {
        "description": "Binance HBARBNB",
        "displaySymbol": "HBAR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:HBARBNB"
    },
    {
        "description": "Binance HBARBTC",
        "displaySymbol": "HBAR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HBARBTC"
    },
    {
        "description": "Binance HBARUSDT",
        "displaySymbol": "HBAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:HBARUSDT"
    },
    {
        "description": "Binance NKNBNB",
        "displaySymbol": "NKN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NKNBNB"
    },
    {
        "description": "Binance NKNBTC",
        "displaySymbol": "NKN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NKNBTC"
    },
    {
        "description": "Binance NKNUSDT",
        "displaySymbol": "NKN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NKNUSDT"
    },
    {
        "description": "Binance XRPBUSD",
        "displaySymbol": "XRP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XRPBUSD"
    },
    {
        "description": "Binance ETHBUSD",
        "displaySymbol": "ETH/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ETHBUSD"
    },
    {
        "description": "Binance BCHABCBUSD",
        "displaySymbol": "BCHABC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BCHABCBUSD"
    },
    {
        "description": "Binance LTCBUSD",
        "displaySymbol": "LTC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LTCBUSD"
    },
    {
        "description": "Binance LINKBUSD",
        "displaySymbol": "LINK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LINKBUSD"
    },
    {
        "description": "Binance ETCBUSD",
        "displaySymbol": "ETC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ETCBUSD"
    },
    {
        "description": "Binance STXBNB",
        "displaySymbol": "STX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:STXBNB"
    },
    {
        "description": "Binance STXBTC",
        "displaySymbol": "STX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STXBTC"
    },
    {
        "description": "Binance STXUSDT",
        "displaySymbol": "STX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STXUSDT"
    },
    {
        "description": "Binance KAVABNB",
        "displaySymbol": "KAVA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:KAVABNB"
    },
    {
        "description": "Binance KAVABTC",
        "displaySymbol": "KAVA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KAVABTC"
    },
    {
        "description": "Binance KAVAUSDT",
        "displaySymbol": "KAVA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KAVAUSDT"
    },
    {
        "description": "Binance BUSDNGN",
        "displaySymbol": "BUSD/NGN",
        "hasWM": true,
        "symbol": "BINANCE:BUSDNGN"
    },
    {
        "description": "Binance BNBNGN",
        "displaySymbol": "BNB/NGN",
        "hasWM": true,
        "symbol": "BINANCE:BNBNGN"
    },
    {
        "description": "Binance BTCNGN",
        "displaySymbol": "BTC/NGN",
        "hasWM": true,
        "symbol": "BINANCE:BTCNGN"
    },
    {
        "description": "Binance ARPABNB",
        "displaySymbol": "ARPA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ARPABNB"
    },
    {
        "description": "Binance ARPABTC",
        "displaySymbol": "ARPA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ARPABTC"
    },
    {
        "description": "Binance ARPAUSDT",
        "displaySymbol": "ARPA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ARPAUSDT"
    },
    {
        "description": "Binance TRXBUSD",
        "displaySymbol": "TRX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TRXBUSD"
    },
    {
        "description": "Binance EOSBUSD",
        "displaySymbol": "EOS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:EOSBUSD"
    },
    {
        "description": "Binance IOTXUSDT",
        "displaySymbol": "IOTX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:IOTXUSDT"
    },
    {
        "description": "Binance RLCUSDT",
        "displaySymbol": "RLC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RLCUSDT"
    },
    {
        "description": "Binance MCOUSDT",
        "displaySymbol": "MCO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MCOUSDT"
    },
    {
        "description": "Binance XLMBUSD",
        "displaySymbol": "XLM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XLMBUSD"
    },
    {
        "description": "Binance ADABUSD",
        "displaySymbol": "ADA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ADABUSD"
    },
    {
        "description": "Binance CTXCBNB",
        "displaySymbol": "CTXC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CTXCBNB"
    },
    {
        "description": "Binance CTXCBTC",
        "displaySymbol": "CTXC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CTXCBTC"
    },
    {
        "description": "Binance CTXCUSDT",
        "displaySymbol": "CTXC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CTXCUSDT"
    },
    {
        "description": "Binance BCHBNB",
        "displaySymbol": "BCH/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BCHBNB"
    },
    {
        "description": "Binance BCHBTC",
        "displaySymbol": "BCH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BCHBTC"
    },
    {
        "description": "Binance BCHUSDT",
        "displaySymbol": "BCH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BCHUSDT"
    },
    {
        "description": "Binance BCHUSDC",
        "displaySymbol": "BCH/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BCHUSDC"
    },
    {
        "description": "Binance BCHTUSD",
        "displaySymbol": "BCH/TUSD",
        "hasWM": true,
        "symbol": "BINANCE:BCHTUSD"
    },
    {
        "description": "Binance BCHPAX",
        "displaySymbol": "BCH/PAX",
        "hasWM": true,
        "symbol": "BINANCE:BCHPAX"
    },
    {
        "description": "Binance BCHBUSD",
        "displaySymbol": "BCH/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BCHBUSD"
    },
    {
        "description": "Binance BTCRUB",
        "displaySymbol": "BTC/RUB",
        "hasWM": true,
        "symbol": "BINANCE:BTCRUB"
    },
    {
        "description": "Binance ETHRUB",
        "displaySymbol": "ETH/RUB",
        "hasWM": true,
        "symbol": "BINANCE:ETHRUB"
    },
    {
        "description": "Binance XRPRUB",
        "displaySymbol": "XRP/RUB",
        "hasWM": true,
        "symbol": "BINANCE:XRPRUB"
    },
    {
        "description": "Binance BNBRUB",
        "displaySymbol": "BNB/RUB",
        "hasWM": true,
        "symbol": "BINANCE:BNBRUB"
    },
    {
        "description": "Binance TROYBNB",
        "displaySymbol": "TROY/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TROYBNB"
    },
    {
        "description": "Binance TROYBTC",
        "displaySymbol": "TROY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TROYBTC"
    },
    {
        "description": "Binance TROYUSDT",
        "displaySymbol": "TROY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TROYUSDT"
    },
    {
        "description": "Binance BUSDRUB",
        "displaySymbol": "BUSD/RUB",
        "hasWM": true,
        "symbol": "BINANCE:BUSDRUB"
    },
    {
        "description": "Binance QTUMBUSD",
        "displaySymbol": "QTUM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:QTUMBUSD"
    },
    {
        "description": "Binance VETBUSD",
        "displaySymbol": "VET/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:VETBUSD"
    },
    {
        "description": "Binance VITEBNB",
        "displaySymbol": "VITE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:VITEBNB"
    },
    {
        "description": "Binance VITEBTC",
        "displaySymbol": "VITE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VITEBTC"
    },
    {
        "description": "Binance VITEUSDT",
        "displaySymbol": "VITE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:VITEUSDT"
    },
    {
        "description": "Binance FTTBNB",
        "displaySymbol": "FTT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FTTBNB"
    },
    {
        "description": "Binance FTTBTC",
        "displaySymbol": "FTT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FTTBTC"
    },
    {
        "description": "Binance FTTUSDT",
        "displaySymbol": "FTT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FTTUSDT"
    },
    {
        "description": "Binance BTCTRY",
        "displaySymbol": "BTC/TRY",
        "hasWM": true,
        "symbol": "BINANCE:BTCTRY"
    },
    {
        "description": "Binance BNBTRY",
        "displaySymbol": "BNB/TRY",
        "hasWM": true,
        "symbol": "BINANCE:BNBTRY"
    },
    {
        "description": "Binance BUSDTRY",
        "displaySymbol": "BUSD/TRY",
        "hasWM": true,
        "symbol": "BINANCE:BUSDTRY"
    },
    {
        "description": "Binance ETHTRY",
        "displaySymbol": "ETH/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ETHTRY"
    },
    {
        "description": "Binance XRPTRY",
        "displaySymbol": "XRP/TRY",
        "hasWM": true,
        "symbol": "BINANCE:XRPTRY"
    },
    {
        "description": "Binance USDTTRY",
        "displaySymbol": "USDT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:USDTTRY"
    },
    {
        "description": "Binance USDTRUB",
        "displaySymbol": "USDT/RUB",
        "hasWM": true,
        "symbol": "BINANCE:USDTRUB"
    },
    {
        "description": "Binance BTCEUR",
        "displaySymbol": "BTC/EUR",
        "hasWM": true,
        "symbol": "BINANCE:BTCEUR"
    },
    {
        "description": "Binance ETHEUR",
        "displaySymbol": "ETH/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ETHEUR"
    },
    {
        "description": "Binance BNBEUR",
        "displaySymbol": "BNB/EUR",
        "hasWM": true,
        "symbol": "BINANCE:BNBEUR"
    },
    {
        "description": "Binance XRPEUR",
        "displaySymbol": "XRP/EUR",
        "hasWM": true,
        "symbol": "BINANCE:XRPEUR"
    },
    {
        "description": "Binance EURBUSD",
        "displaySymbol": "EUR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:EURBUSD"
    },
    {
        "description": "Binance EURUSDT",
        "displaySymbol": "EUR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EURUSDT"
    },
    {
        "description": "Binance OGNBNB",
        "displaySymbol": "OGN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:OGNBNB"
    },
    {
        "description": "Binance OGNBTC",
        "displaySymbol": "OGN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OGNBTC"
    },
    {
        "description": "Binance OGNUSDT",
        "displaySymbol": "OGN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OGNUSDT"
    },
    {
        "description": "Binance DREPBNB",
        "displaySymbol": "DREP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DREPBNB"
    },
    {
        "description": "Binance DREPBTC",
        "displaySymbol": "DREP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DREPBTC"
    },
    {
        "description": "Binance DREPUSDT",
        "displaySymbol": "DREP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DREPUSDT"
    },
    {
        "description": "Binance BULLUSDT",
        "displaySymbol": "BULL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BULLUSDT"
    },
    {
        "description": "Binance BULLBUSD",
        "displaySymbol": "BULL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BULLBUSD"
    },
    {
        "description": "Binance BEARUSDT",
        "displaySymbol": "BEAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BEARUSDT"
    },
    {
        "description": "Binance BEARBUSD",
        "displaySymbol": "BEAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BEARBUSD"
    },
    {
        "description": "Binance ETHBULLUSDT",
        "displaySymbol": "ETHBULL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ETHBULLUSDT"
    },
    {
        "description": "Binance ETHBULLBUSD",
        "displaySymbol": "ETHBULL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ETHBULLBUSD"
    },
    {
        "description": "Binance ETHBEARUSDT",
        "displaySymbol": "ETHBEAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ETHBEARUSDT"
    },
    {
        "description": "Binance ETHBEARBUSD",
        "displaySymbol": "ETHBEAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ETHBEARBUSD"
    },
    {
        "description": "Binance TCTBNB",
        "displaySymbol": "TCT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TCTBNB"
    },
    {
        "description": "Binance TCTBTC",
        "displaySymbol": "TCT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TCTBTC"
    },
    {
        "description": "Binance TCTUSDT",
        "displaySymbol": "TCT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TCTUSDT"
    },
    {
        "description": "Binance WRXBNB",
        "displaySymbol": "WRX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WRXBNB"
    },
    {
        "description": "Binance WRXBTC",
        "displaySymbol": "WRX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WRXBTC"
    },
    {
        "description": "Binance WRXUSDT",
        "displaySymbol": "WRX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WRXUSDT"
    },
    {
        "description": "Binance ICXBUSD",
        "displaySymbol": "ICX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ICXBUSD"
    },
    {
        "description": "Binance BTSUSDT",
        "displaySymbol": "BTS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTSUSDT"
    },
    {
        "description": "Binance BTSBUSD",
        "displaySymbol": "BTS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTSBUSD"
    },
    {
        "description": "Binance LSKUSDT",
        "displaySymbol": "LSK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LSKUSDT"
    },
    {
        "description": "Binance BNTUSDT",
        "displaySymbol": "BNT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BNTUSDT"
    },
    {
        "description": "Binance BNTBUSD",
        "displaySymbol": "BNT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BNTBUSD"
    },
    {
        "description": "Binance LTOBNB",
        "displaySymbol": "LTO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LTOBNB"
    },
    {
        "description": "Binance LTOBTC",
        "displaySymbol": "LTO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LTOBTC"
    },
    {
        "description": "Binance LTOUSDT",
        "displaySymbol": "LTO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LTOUSDT"
    },
    {
        "description": "Binance ATOMBUSD",
        "displaySymbol": "ATOM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ATOMBUSD"
    },
    {
        "description": "Binance DASHBUSD",
        "displaySymbol": "DASH/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DASHBUSD"
    },
    {
        "description": "Binance NEOBUSD",
        "displaySymbol": "NEO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:NEOBUSD"
    },
    {
        "description": "Binance WAVESBUSD",
        "displaySymbol": "WAVES/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:WAVESBUSD"
    },
    {
        "description": "Binance XTZBUSD",
        "displaySymbol": "XTZ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XTZBUSD"
    },
    {
        "description": "Binance EOSBULLUSDT",
        "displaySymbol": "EOSBULL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EOSBULLUSDT"
    },
    {
        "description": "Binance EOSBULLBUSD",
        "displaySymbol": "EOSBULL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:EOSBULLBUSD"
    },
    {
        "description": "Binance EOSBEARUSDT",
        "displaySymbol": "EOSBEAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EOSBEARUSDT"
    },
    {
        "description": "Binance EOSBEARBUSD",
        "displaySymbol": "EOSBEAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:EOSBEARBUSD"
    },
    {
        "description": "Binance XRPBULLUSDT",
        "displaySymbol": "XRPBULL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XRPBULLUSDT"
    },
    {
        "description": "Binance XRPBULLBUSD",
        "displaySymbol": "XRPBULL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XRPBULLBUSD"
    },
    {
        "description": "Binance XRPBEARUSDT",
        "displaySymbol": "XRPBEAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XRPBEARUSDT"
    },
    {
        "description": "Binance XRPBEARBUSD",
        "displaySymbol": "XRPBEAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XRPBEARBUSD"
    },
    {
        "description": "Binance BATBUSD",
        "displaySymbol": "BAT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BATBUSD"
    },
    {
        "description": "Binance ENJBUSD",
        "displaySymbol": "ENJ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ENJBUSD"
    },
    {
        "description": "Binance NANOBUSD",
        "displaySymbol": "NANO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:NANOBUSD"
    },
    {
        "description": "Binance ONTBUSD",
        "displaySymbol": "ONT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ONTBUSD"
    },
    {
        "description": "Binance RVNBUSD",
        "displaySymbol": "RVN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RVNBUSD"
    },
    {
        "description": "Binance STRATBUSD",
        "displaySymbol": "STRAT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:STRATBUSD"
    },
    {
        "description": "Binance STRATBNB",
        "displaySymbol": "STRAT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:STRATBNB"
    },
    {
        "description": "Binance STRATUSDT",
        "displaySymbol": "STRAT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STRATUSDT"
    },
    {
        "description": "Binance AIONBUSD",
        "displaySymbol": "AION/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AIONBUSD"
    },
    {
        "description": "Binance AIONUSDT",
        "displaySymbol": "AION/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AIONUSDT"
    },
    {
        "description": "Binance MBLBNB",
        "displaySymbol": "MBL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MBLBNB"
    },
    {
        "description": "Binance MBLBTC",
        "displaySymbol": "MBL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MBLBTC"
    },
    {
        "description": "Binance MBLUSDT",
        "displaySymbol": "MBL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MBLUSDT"
    },
    {
        "description": "Binance COTIBNB",
        "displaySymbol": "COTI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:COTIBNB"
    },
    {
        "description": "Binance COTIBTC",
        "displaySymbol": "COTI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:COTIBTC"
    },
    {
        "description": "Binance COTIUSDT",
        "displaySymbol": "COTI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:COTIUSDT"
    },
    {
        "description": "Binance ALGOBUSD",
        "displaySymbol": "ALGO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ALGOBUSD"
    },
    {
        "description": "Binance BTTBUSD",
        "displaySymbol": "BTT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTTBUSD"
    },
    {
        "description": "Binance TOMOBUSD",
        "displaySymbol": "TOMO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TOMOBUSD"
    },
    {
        "description": "Binance XMRBUSD",
        "displaySymbol": "XMR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XMRBUSD"
    },
    {
        "description": "Binance ZECBUSD",
        "displaySymbol": "ZEC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ZECBUSD"
    },
    {
        "description": "Binance BNBBULLUSDT",
        "displaySymbol": "BNBBULL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BNBBULLUSDT"
    },
    {
        "description": "Binance BNBBULLBUSD",
        "displaySymbol": "BNBBULL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BNBBULLBUSD"
    },
    {
        "description": "Binance BNBBEARUSDT",
        "displaySymbol": "BNBBEAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BNBBEARUSDT"
    },
    {
        "description": "Binance BNBBEARBUSD",
        "displaySymbol": "BNBBEAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BNBBEARBUSD"
    },
    {
        "description": "Binance STPTBNB",
        "displaySymbol": "STPT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:STPTBNB"
    },
    {
        "description": "Binance STPTBTC",
        "displaySymbol": "STPT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STPTBTC"
    },
    {
        "description": "Binance STPTUSDT",
        "displaySymbol": "STPT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STPTUSDT"
    },
    {
        "description": "Binance BTCZAR",
        "displaySymbol": "BTC/ZAR",
        "hasWM": true,
        "symbol": "BINANCE:BTCZAR"
    },
    {
        "description": "Binance ETHZAR",
        "displaySymbol": "ETH/ZAR",
        "hasWM": true,
        "symbol": "BINANCE:ETHZAR"
    },
    {
        "description": "Binance BNBZAR",
        "displaySymbol": "BNB/ZAR",
        "hasWM": true,
        "symbol": "BINANCE:BNBZAR"
    },
    {
        "description": "Binance USDTZAR",
        "displaySymbol": "USDT/ZAR",
        "hasWM": true,
        "symbol": "BINANCE:USDTZAR"
    },
    {
        "description": "Binance BUSDZAR",
        "displaySymbol": "BUSD/ZAR",
        "hasWM": true,
        "symbol": "BINANCE:BUSDZAR"
    },
    {
        "description": "Binance BTCBKRW",
        "displaySymbol": "BTC/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:BTCBKRW"
    },
    {
        "description": "Binance ETHBKRW",
        "displaySymbol": "ETH/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:ETHBKRW"
    },
    {
        "description": "Binance BNBBKRW",
        "displaySymbol": "BNB/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:BNBBKRW"
    },
    {
        "description": "Binance WTCUSDT",
        "displaySymbol": "WTC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WTCUSDT"
    },
    {
        "description": "Binance DATABUSD",
        "displaySymbol": "DATA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DATABUSD"
    },
    {
        "description": "Binance DATAUSDT",
        "displaySymbol": "DATA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DATAUSDT"
    },
    {
        "description": "Binance XZCUSDT",
        "displaySymbol": "XZC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XZCUSDT"
    },
    {
        "description": "Binance SOLBNB",
        "displaySymbol": "SOL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SOLBNB"
    },
    {
        "description": "Binance SOLBTC",
        "displaySymbol": "SOL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SOLBTC"
    },
    {
        "description": "Binance SOLUSDT",
        "displaySymbol": "SOL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SOLUSDT"
    },
    {
        "description": "Binance SOLBUSD",
        "displaySymbol": "SOL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SOLBUSD"
    },
    {
        "description": "Binance BTCIDRT",
        "displaySymbol": "BTC/IDRT",
        "hasWM": true,
        "symbol": "BINANCE:BTCIDRT"
    },
    {
        "description": "Binance BNBIDRT",
        "displaySymbol": "BNB/IDRT",
        "hasWM": true,
        "symbol": "BINANCE:BNBIDRT"
    },
    {
        "description": "Binance USDTIDRT",
        "displaySymbol": "USDT/IDRT",
        "hasWM": true,
        "symbol": "BINANCE:USDTIDRT"
    },
    {
        "description": "Binance BUSDIDRT",
        "displaySymbol": "BUSD/IDRT",
        "hasWM": true,
        "symbol": "BINANCE:BUSDIDRT"
    },
    {
        "description": "Binance CTSIBTC",
        "displaySymbol": "CTSI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CTSIBTC"
    },
    {
        "description": "Binance CTSIUSDT",
        "displaySymbol": "CTSI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CTSIUSDT"
    },
    {
        "description": "Binance CTSIBNB",
        "displaySymbol": "CTSI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CTSIBNB"
    },
    {
        "description": "Binance CTSIBUSD",
        "displaySymbol": "CTSI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CTSIBUSD"
    },
    {
        "description": "Binance HIVEBNB",
        "displaySymbol": "HIVE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:HIVEBNB"
    },
    {
        "description": "Binance HIVEBTC",
        "displaySymbol": "HIVE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HIVEBTC"
    },
    {
        "description": "Binance HIVEUSDT",
        "displaySymbol": "HIVE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:HIVEUSDT"
    },
    {
        "description": "Binance CHRBNB",
        "displaySymbol": "CHR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CHRBNB"
    },
    {
        "description": "Binance CHRBTC",
        "displaySymbol": "CHR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CHRBTC"
    },
    {
        "description": "Binance CHRUSDT",
        "displaySymbol": "CHR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CHRUSDT"
    },
    {
        "description": "Binance BTCUPUSDT",
        "displaySymbol": "BTCUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTCUPUSDT"
    },
    {
        "description": "Binance BTCDOWNUSDT",
        "displaySymbol": "BTCDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTCDOWNUSDT"
    },
    {
        "description": "Binance GXSUSDT",
        "displaySymbol": "GXS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GXSUSDT"
    },
    {
        "description": "Binance ARDRUSDT",
        "displaySymbol": "ARDR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ARDRUSDT"
    },
    {
        "description": "Binance ERDBUSD",
        "displaySymbol": "ERD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ERDBUSD"
    },
    {
        "description": "Binance LENDUSDT",
        "displaySymbol": "LEND/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LENDUSDT"
    },
    {
        "description": "Binance HBARBUSD",
        "displaySymbol": "HBAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:HBARBUSD"
    },
    {
        "description": "Binance MATICBUSD",
        "displaySymbol": "MATIC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MATICBUSD"
    },
    {
        "description": "Binance WRXBUSD",
        "displaySymbol": "WRX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:WRXBUSD"
    },
    {
        "description": "Binance ZILBUSD",
        "displaySymbol": "ZIL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ZILBUSD"
    },
    {
        "description": "Binance MDTBNB",
        "displaySymbol": "MDT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MDTBNB"
    },
    {
        "description": "Binance MDTBTC",
        "displaySymbol": "MDT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MDTBTC"
    },
    {
        "description": "Binance MDTUSDT",
        "displaySymbol": "MDT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MDTUSDT"
    },
    {
        "description": "Binance STMXBTC",
        "displaySymbol": "STMX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STMXBTC"
    },
    {
        "description": "Binance STMXETH",
        "displaySymbol": "STMX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:STMXETH"
    },
    {
        "description": "Binance STMXUSDT",
        "displaySymbol": "STMX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STMXUSDT"
    },
    {
        "description": "Binance KNCBUSD",
        "displaySymbol": "KNC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KNCBUSD"
    },
    {
        "description": "Binance KNCUSDT",
        "displaySymbol": "KNC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KNCUSDT"
    },
    {
        "description": "Binance REPBUSD",
        "displaySymbol": "REP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:REPBUSD"
    },
    {
        "description": "Binance REPUSDT",
        "displaySymbol": "REP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:REPUSDT"
    },
    {
        "description": "Binance LRCBUSD",
        "displaySymbol": "LRC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LRCBUSD"
    },
    {
        "description": "Binance LRCUSDT",
        "displaySymbol": "LRC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LRCUSDT"
    },
    {
        "description": "Binance IQBNB",
        "displaySymbol": "IQ/BNB",
        "hasWM": true,
        "symbol": "BINANCE:IQBNB"
    },
    {
        "description": "Binance IQBUSD",
        "displaySymbol": "IQ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:IQBUSD"
    },
    {
        "description": "Binance PNTBTC",
        "displaySymbol": "PNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PNTBTC"
    },
    {
        "description": "Binance PNTUSDT",
        "displaySymbol": "PNT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PNTUSDT"
    },
    {
        "description": "Binance BTCGBP",
        "displaySymbol": "BTC/GBP",
        "hasWM": true,
        "symbol": "BINANCE:BTCGBP"
    },
    {
        "description": "Binance ETHGBP",
        "displaySymbol": "ETH/GBP",
        "hasWM": true,
        "symbol": "BINANCE:ETHGBP"
    },
    {
        "description": "Binance XRPGBP",
        "displaySymbol": "XRP/GBP",
        "hasWM": true,
        "symbol": "BINANCE:XRPGBP"
    },
    {
        "description": "Binance BNBGBP",
        "displaySymbol": "BNB/GBP",
        "hasWM": true,
        "symbol": "BINANCE:BNBGBP"
    },
    {
        "description": "Binance GBPBUSD",
        "displaySymbol": "GBP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GBPBUSD"
    },
    {
        "description": "Binance DGBBTC",
        "displaySymbol": "DGB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DGBBTC"
    },
    {
        "description": "Binance DGBBUSD",
        "displaySymbol": "DGB/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DGBBUSD"
    },
    {
        "description": "Binance BTCUAH",
        "displaySymbol": "BTC/UAH",
        "hasWM": true,
        "symbol": "BINANCE:BTCUAH"
    },
    {
        "description": "Binance USDTUAH",
        "displaySymbol": "USDT/UAH",
        "hasWM": true,
        "symbol": "BINANCE:USDTUAH"
    },
    {
        "description": "Binance COMPBTC",
        "displaySymbol": "COMP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:COMPBTC"
    },
    {
        "description": "Binance COMPBNB",
        "displaySymbol": "COMP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:COMPBNB"
    },
    {
        "description": "Binance COMPBUSD",
        "displaySymbol": "COMP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:COMPBUSD"
    },
    {
        "description": "Binance COMPUSDT",
        "displaySymbol": "COMP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:COMPUSDT"
    },
    {
        "description": "Binance BTCBIDR",
        "displaySymbol": "BTC/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:BTCBIDR"
    },
    {
        "description": "Binance ETHBIDR",
        "displaySymbol": "ETH/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:ETHBIDR"
    },
    {
        "description": "Binance BNBBIDR",
        "displaySymbol": "BNB/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:BNBBIDR"
    },
    {
        "description": "Binance BUSDBIDR",
        "displaySymbol": "BUSD/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:BUSDBIDR"
    },
    {
        "description": "Binance USDTBIDR",
        "displaySymbol": "USDT/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:USDTBIDR"
    },
    {
        "description": "Binance BKRWUSDT",
        "displaySymbol": "BKRW/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BKRWUSDT"
    },
    {
        "description": "Binance BKRWBUSD",
        "displaySymbol": "BKRW/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BKRWBUSD"
    },
    {
        "description": "Binance SCUSDT",
        "displaySymbol": "SC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SCUSDT"
    },
    {
        "description": "Binance ZENUSDT",
        "displaySymbol": "ZEN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ZENUSDT"
    },
    {
        "description": "Binance SXPBTC",
        "displaySymbol": "SXP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SXPBTC"
    },
    {
        "description": "Binance SXPBNB",
        "displaySymbol": "SXP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SXPBNB"
    },
    {
        "description": "Binance SXPBUSD",
        "displaySymbol": "SXP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SXPBUSD"
    },
    {
        "description": "Binance SNXBTC",
        "displaySymbol": "SNX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SNXBTC"
    },
    {
        "description": "Binance SNXBNB",
        "displaySymbol": "SNX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SNXBNB"
    },
    {
        "description": "Binance SNXBUSD",
        "displaySymbol": "SNX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SNXBUSD"
    },
    {
        "description": "Binance SNXUSDT",
        "displaySymbol": "SNX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SNXUSDT"
    },
    {
        "description": "Binance ETHUPUSDT",
        "displaySymbol": "ETHUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ETHUPUSDT"
    },
    {
        "description": "Binance ETHDOWNUSDT",
        "displaySymbol": "ETHDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ETHDOWNUSDT"
    },
    {
        "description": "Binance ADAUPUSDT",
        "displaySymbol": "ADAUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ADAUPUSDT"
    },
    {
        "description": "Binance ADADOWNUSDT",
        "displaySymbol": "ADADOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ADADOWNUSDT"
    },
    {
        "description": "Binance LINKUPUSDT",
        "displaySymbol": "LINKUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LINKUPUSDT"
    },
    {
        "description": "Binance LINKDOWNUSDT",
        "displaySymbol": "LINKDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LINKDOWNUSDT"
    },
    {
        "description": "Binance VTHOBNB",
        "displaySymbol": "VTHO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:VTHOBNB"
    },
    {
        "description": "Binance VTHOBUSD",
        "displaySymbol": "VTHO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:VTHOBUSD"
    },
    {
        "description": "Binance VTHOUSDT",
        "displaySymbol": "VTHO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:VTHOUSDT"
    },
    {
        "description": "Binance DCRBUSD",
        "displaySymbol": "DCR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DCRBUSD"
    },
    {
        "description": "Binance DGBUSDT",
        "displaySymbol": "DGB/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DGBUSDT"
    },
    {
        "description": "Binance GBPUSDT",
        "displaySymbol": "GBP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GBPUSDT"
    },
    {
        "description": "Binance STORJBUSD",
        "displaySymbol": "STORJ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:STORJBUSD"
    },
    {
        "description": "Binance SXPUSDT",
        "displaySymbol": "SXP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SXPUSDT"
    },
    {
        "description": "Binance IRISBNB",
        "displaySymbol": "IRIS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:IRISBNB"
    },
    {
        "description": "Binance IRISBTC",
        "displaySymbol": "IRIS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:IRISBTC"
    },
    {
        "description": "Binance IRISBUSD",
        "displaySymbol": "IRIS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:IRISBUSD"
    },
    {
        "description": "Binance MKRBNB",
        "displaySymbol": "MKR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MKRBNB"
    },
    {
        "description": "Binance MKRBTC",
        "displaySymbol": "MKR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MKRBTC"
    },
    {
        "description": "Binance MKRUSDT",
        "displaySymbol": "MKR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MKRUSDT"
    },
    {
        "description": "Binance MKRBUSD",
        "displaySymbol": "MKR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MKRBUSD"
    },
    {
        "description": "Binance DAIBNB",
        "displaySymbol": "DAI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DAIBNB"
    },
    {
        "description": "Binance DAIBTC",
        "displaySymbol": "DAI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DAIBTC"
    },
    {
        "description": "Binance DAIUSDT",
        "displaySymbol": "DAI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DAIUSDT"
    },
    {
        "description": "Binance DAIBUSD",
        "displaySymbol": "DAI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DAIBUSD"
    },
    {
        "description": "Binance RUNEBNB",
        "displaySymbol": "RUNE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RUNEBNB"
    },
    {
        "description": "Binance RUNEBTC",
        "displaySymbol": "RUNE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RUNEBTC"
    },
    {
        "description": "Binance RUNEBUSD",
        "displaySymbol": "RUNE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RUNEBUSD"
    },
    {
        "description": "Binance MANABUSD",
        "displaySymbol": "MANA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MANABUSD"
    },
    {
        "description": "Binance DOGEBUSD",
        "displaySymbol": "DOGE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DOGEBUSD"
    },
    {
        "description": "Binance LENDBUSD",
        "displaySymbol": "LEND/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LENDBUSD"
    },
    {
        "description": "Binance ZRXBUSD",
        "displaySymbol": "ZRX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ZRXBUSD"
    },
    {
        "description": "Binance DCRUSDT",
        "displaySymbol": "DCR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DCRUSDT"
    },
    {
        "description": "Binance STORJUSDT",
        "displaySymbol": "STORJ/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STORJUSDT"
    },
    {
        "description": "Binance XRPBKRW",
        "displaySymbol": "XRP/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:XRPBKRW"
    },
    {
        "description": "Binance ADABKRW",
        "displaySymbol": "ADA/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:ADABKRW"
    },
    {
        "description": "Binance BTCAUD",
        "displaySymbol": "BTC/AUD",
        "hasWM": true,
        "symbol": "BINANCE:BTCAUD"
    },
    {
        "description": "Binance ETHAUD",
        "displaySymbol": "ETH/AUD",
        "hasWM": true,
        "symbol": "BINANCE:ETHAUD"
    },
    {
        "description": "Binance AUDBUSD",
        "displaySymbol": "AUD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AUDBUSD"
    },
    {
        "description": "Binance FIOBNB",
        "displaySymbol": "FIO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FIOBNB"
    },
    {
        "description": "Binance FIOBTC",
        "displaySymbol": "FIO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FIOBTC"
    },
    {
        "description": "Binance FIOBUSD",
        "displaySymbol": "FIO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FIOBUSD"
    },
    {
        "description": "Binance BNBUPUSDT",
        "displaySymbol": "BNBUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BNBUPUSDT"
    },
    {
        "description": "Binance BNBDOWNUSDT",
        "displaySymbol": "BNBDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BNBDOWNUSDT"
    },
    {
        "description": "Binance XTZUPUSDT",
        "displaySymbol": "XTZUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XTZUPUSDT"
    },
    {
        "description": "Binance XTZDOWNUSDT",
        "displaySymbol": "XTZDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XTZDOWNUSDT"
    },
    {
        "description": "Binance AVABNB",
        "displaySymbol": "AVA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AVABNB"
    },
    {
        "description": "Binance AVABTC",
        "displaySymbol": "AVA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AVABTC"
    },
    {
        "description": "Binance AVABUSD",
        "displaySymbol": "AVA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AVABUSD"
    },
    {
        "description": "Binance USDTBKRW",
        "displaySymbol": "USDT/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:USDTBKRW"
    },
    {
        "description": "Binance BUSDBKRW",
        "displaySymbol": "BUSD/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:BUSDBKRW"
    },
    {
        "description": "Binance IOTABUSD",
        "displaySymbol": "IOTA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:IOTABUSD"
    },
    {
        "description": "Binance MANAUSDT",
        "displaySymbol": "MANA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MANAUSDT"
    },
    {
        "description": "Binance XRPAUD",
        "displaySymbol": "XRP/AUD",
        "hasWM": true,
        "symbol": "BINANCE:XRPAUD"
    },
    {
        "description": "Binance BNBAUD",
        "displaySymbol": "BNB/AUD",
        "hasWM": true,
        "symbol": "BINANCE:BNBAUD"
    },
    {
        "description": "Binance AUDUSDT",
        "displaySymbol": "AUD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AUDUSDT"
    },
    {
        "description": "Binance BALBNB",
        "displaySymbol": "BAL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BALBNB"
    },
    {
        "description": "Binance BALBTC",
        "displaySymbol": "BAL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BALBTC"
    },
    {
        "description": "Binance BALBUSD",
        "displaySymbol": "BAL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BALBUSD"
    },
    {
        "description": "Binance YFIBNB",
        "displaySymbol": "YFI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:YFIBNB"
    },
    {
        "description": "Binance YFIBTC",
        "displaySymbol": "YFI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:YFIBTC"
    },
    {
        "description": "Binance YFIBUSD",
        "displaySymbol": "YFI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:YFIBUSD"
    },
    {
        "description": "Binance YFIUSDT",
        "displaySymbol": "YFI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:YFIUSDT"
    },
    {
        "description": "Binance BLZBUSD",
        "displaySymbol": "BLZ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BLZBUSD"
    },
    {
        "description": "Binance KMDBUSD",
        "displaySymbol": "KMD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KMDBUSD"
    },
    {
        "description": "Binance BALUSDT",
        "displaySymbol": "BAL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BALUSDT"
    },
    {
        "description": "Binance BLZUSDT",
        "displaySymbol": "BLZ/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BLZUSDT"
    },
    {
        "description": "Binance IRISUSDT",
        "displaySymbol": "IRIS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:IRISUSDT"
    },
    {
        "description": "Binance KMDUSDT",
        "displaySymbol": "KMD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KMDUSDT"
    },
    {
        "description": "Binance BTCDAI",
        "displaySymbol": "BTC/DAI",
        "hasWM": true,
        "symbol": "BINANCE:BTCDAI"
    },
    {
        "description": "Binance ETHDAI",
        "displaySymbol": "ETH/DAI",
        "hasWM": true,
        "symbol": "BINANCE:ETHDAI"
    },
    {
        "description": "Binance BNBDAI",
        "displaySymbol": "BNB/DAI",
        "hasWM": true,
        "symbol": "BINANCE:BNBDAI"
    },
    {
        "description": "Binance USDTDAI",
        "displaySymbol": "USDT/DAI",
        "hasWM": true,
        "symbol": "BINANCE:USDTDAI"
    },
    {
        "description": "Binance BUSDDAI",
        "displaySymbol": "BUSD/DAI",
        "hasWM": true,
        "symbol": "BINANCE:BUSDDAI"
    },
    {
        "description": "Binance JSTBNB",
        "displaySymbol": "JST/BNB",
        "hasWM": true,
        "symbol": "BINANCE:JSTBNB"
    },
    {
        "description": "Binance JSTBTC",
        "displaySymbol": "JST/BTC",
        "hasWM": true,
        "symbol": "BINANCE:JSTBTC"
    },
    {
        "description": "Binance JSTBUSD",
        "displaySymbol": "JST/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:JSTBUSD"
    },
    {
        "description": "Binance JSTUSDT",
        "displaySymbol": "JST/USDT",
        "hasWM": true,
        "symbol": "BINANCE:JSTUSDT"
    },
    {
        "description": "Binance SRMBNB",
        "displaySymbol": "SRM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SRMBNB"
    },
    {
        "description": "Binance SRMBTC",
        "displaySymbol": "SRM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SRMBTC"
    },
    {
        "description": "Binance SRMBUSD",
        "displaySymbol": "SRM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SRMBUSD"
    },
    {
        "description": "Binance SRMUSDT",
        "displaySymbol": "SRM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SRMUSDT"
    },
    {
        "description": "Binance ANTBNB",
        "displaySymbol": "ANT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ANTBNB"
    },
    {
        "description": "Binance ANTBTC",
        "displaySymbol": "ANT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ANTBTC"
    },
    {
        "description": "Binance ANTBUSD",
        "displaySymbol": "ANT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ANTBUSD"
    },
    {
        "description": "Binance ANTUSDT",
        "displaySymbol": "ANT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ANTUSDT"
    },
    {
        "description": "Binance CRVBNB",
        "displaySymbol": "CRV/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CRVBNB"
    },
    {
        "description": "Binance CRVBTC",
        "displaySymbol": "CRV/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CRVBTC"
    },
    {
        "description": "Binance CRVBUSD",
        "displaySymbol": "CRV/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CRVBUSD"
    },
    {
        "description": "Binance CRVUSDT",
        "displaySymbol": "CRV/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CRVUSDT"
    },
    {
        "description": "Binance SANDBNB",
        "displaySymbol": "SAND/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SANDBNB"
    },
    {
        "description": "Binance SANDBTC",
        "displaySymbol": "SAND/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SANDBTC"
    },
    {
        "description": "Binance SANDUSDT",
        "displaySymbol": "SAND/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SANDUSDT"
    },
    {
        "description": "Binance SANDBUSD",
        "displaySymbol": "SAND/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SANDBUSD"
    },
    {
        "description": "Binance OCEANBNB",
        "displaySymbol": "OCEAN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:OCEANBNB"
    },
    {
        "description": "Binance OCEANBTC",
        "displaySymbol": "OCEAN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OCEANBTC"
    },
    {
        "description": "Binance OCEANBUSD",
        "displaySymbol": "OCEAN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OCEANBUSD"
    },
    {
        "description": "Binance OCEANUSDT",
        "displaySymbol": "OCEAN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OCEANUSDT"
    },
    {
        "description": "Binance NMRBTC",
        "displaySymbol": "NMR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NMRBTC"
    },
    {
        "description": "Binance NMRBUSD",
        "displaySymbol": "NMR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:NMRBUSD"
    },
    {
        "description": "Binance NMRUSDT",
        "displaySymbol": "NMR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NMRUSDT"
    },
    {
        "description": "Binance DOTBNB",
        "displaySymbol": "DOT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DOTBNB"
    },
    {
        "description": "Binance DOTBTC",
        "displaySymbol": "DOT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DOTBTC"
    },
    {
        "description": "Binance DOTBUSD",
        "displaySymbol": "DOT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DOTBUSD"
    },
    {
        "description": "Binance DOTUSDT",
        "displaySymbol": "DOT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DOTUSDT"
    },
    {
        "description": "Binance LUNABNB",
        "displaySymbol": "LUNA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LUNABNB"
    },
    {
        "description": "Binance LUNABTC",
        "displaySymbol": "LUNA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LUNABTC"
    },
    {
        "description": "Binance LUNABUSD",
        "displaySymbol": "LUNA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LUNABUSD"
    },
    {
        "description": "Binance LUNAUSDT",
        "displaySymbol": "LUNA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LUNAUSDT"
    },
    {
        "description": "Binance IDEXBTC",
        "displaySymbol": "IDEX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:IDEXBTC"
    },
    {
        "description": "Binance IDEXBUSD",
        "displaySymbol": "IDEX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:IDEXBUSD"
    },
    {
        "description": "Binance RSRBNB",
        "displaySymbol": "RSR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RSRBNB"
    },
    {
        "description": "Binance RSRBTC",
        "displaySymbol": "RSR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RSRBTC"
    },
    {
        "description": "Binance RSRBUSD",
        "displaySymbol": "RSR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RSRBUSD"
    },
    {
        "description": "Binance RSRUSDT",
        "displaySymbol": "RSR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RSRUSDT"
    },
    {
        "description": "Binance PAXGBNB",
        "displaySymbol": "PAXG/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PAXGBNB"
    },
    {
        "description": "Binance PAXGBTC",
        "displaySymbol": "PAXG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PAXGBTC"
    },
    {
        "description": "Binance PAXGBUSD",
        "displaySymbol": "PAXG/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PAXGBUSD"
    },
    {
        "description": "Binance PAXGUSDT",
        "displaySymbol": "PAXG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PAXGUSDT"
    },
    {
        "description": "Binance WNXMBNB",
        "displaySymbol": "WNXM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WNXMBNB"
    },
    {
        "description": "Binance WNXMBTC",
        "displaySymbol": "WNXM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WNXMBTC"
    },
    {
        "description": "Binance WNXMBUSD",
        "displaySymbol": "WNXM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:WNXMBUSD"
    },
    {
        "description": "Binance WNXMUSDT",
        "displaySymbol": "WNXM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WNXMUSDT"
    },
    {
        "description": "Binance TRBBNB",
        "displaySymbol": "TRB/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TRBBNB"
    },
    {
        "description": "Binance TRBBTC",
        "displaySymbol": "TRB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TRBBTC"
    },
    {
        "description": "Binance TRBBUSD",
        "displaySymbol": "TRB/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TRBBUSD"
    },
    {
        "description": "Binance TRBUSDT",
        "displaySymbol": "TRB/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TRBUSDT"
    },
    {
        "description": "Binance ETHNGN",
        "displaySymbol": "ETH/NGN",
        "hasWM": true,
        "symbol": "BINANCE:ETHNGN"
    },
    {
        "description": "Binance DOTBIDR",
        "displaySymbol": "DOT/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:DOTBIDR"
    },
    {
        "description": "Binance LINKAUD",
        "displaySymbol": "LINK/AUD",
        "hasWM": true,
        "symbol": "BINANCE:LINKAUD"
    },
    {
        "description": "Binance SXPAUD",
        "displaySymbol": "SXP/AUD",
        "hasWM": true,
        "symbol": "BINANCE:SXPAUD"
    },
    {
        "description": "Binance BZRXBNB",
        "displaySymbol": "BZRX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BZRXBNB"
    },
    {
        "description": "Binance BZRXBTC",
        "displaySymbol": "BZRX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BZRXBTC"
    },
    {
        "description": "Binance BZRXBUSD",
        "displaySymbol": "BZRX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BZRXBUSD"
    },
    {
        "description": "Binance BZRXUSDT",
        "displaySymbol": "BZRX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BZRXUSDT"
    },
    {
        "description": "Binance WBTCBTC",
        "displaySymbol": "WBTC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WBTCBTC"
    },
    {
        "description": "Binance WBTCETH",
        "displaySymbol": "WBTC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:WBTCETH"
    },
    {
        "description": "Binance SUSHIBNB",
        "displaySymbol": "SUSHI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SUSHIBNB"
    },
    {
        "description": "Binance SUSHIBTC",
        "displaySymbol": "SUSHI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SUSHIBTC"
    },
    {
        "description": "Binance SUSHIBUSD",
        "displaySymbol": "SUSHI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SUSHIBUSD"
    },
    {
        "description": "Binance SUSHIUSDT",
        "displaySymbol": "SUSHI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SUSHIUSDT"
    },
    {
        "description": "Binance YFIIBNB",
        "displaySymbol": "YFII/BNB",
        "hasWM": true,
        "symbol": "BINANCE:YFIIBNB"
    },
    {
        "description": "Binance YFIIBTC",
        "displaySymbol": "YFII/BTC",
        "hasWM": true,
        "symbol": "BINANCE:YFIIBTC"
    },
    {
        "description": "Binance YFIIBUSD",
        "displaySymbol": "YFII/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:YFIIBUSD"
    },
    {
        "description": "Binance YFIIUSDT",
        "displaySymbol": "YFII/USDT",
        "hasWM": true,
        "symbol": "BINANCE:YFIIUSDT"
    },
    {
        "description": "Binance KSMBNB",
        "displaySymbol": "KSM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:KSMBNB"
    },
    {
        "description": "Binance KSMBTC",
        "displaySymbol": "KSM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KSMBTC"
    },
    {
        "description": "Binance KSMBUSD",
        "displaySymbol": "KSM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KSMBUSD"
    },
    {
        "description": "Binance KSMUSDT",
        "displaySymbol": "KSM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KSMUSDT"
    },
    {
        "description": "Binance EGLDBNB",
        "displaySymbol": "EGLD/BNB",
        "hasWM": true,
        "symbol": "BINANCE:EGLDBNB"
    },
    {
        "description": "Binance EGLDBTC",
        "displaySymbol": "EGLD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:EGLDBTC"
    },
    {
        "description": "Binance EGLDBUSD",
        "displaySymbol": "EGLD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:EGLDBUSD"
    },
    {
        "description": "Binance EGLDUSDT",
        "displaySymbol": "EGLD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EGLDUSDT"
    },
    {
        "description": "Binance DIABNB",
        "displaySymbol": "DIA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DIABNB"
    },
    {
        "description": "Binance DIABTC",
        "displaySymbol": "DIA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DIABTC"
    },
    {
        "description": "Binance DIABUSD",
        "displaySymbol": "DIA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DIABUSD"
    },
    {
        "description": "Binance DIAUSDT",
        "displaySymbol": "DIA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DIAUSDT"
    },
    {
        "description": "Binance RUNEUSDT",
        "displaySymbol": "RUNE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RUNEUSDT"
    },
    {
        "description": "Binance FIOUSDT",
        "displaySymbol": "FIO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FIOUSDT"
    },
    {
        "description": "Binance UMABTC",
        "displaySymbol": "UMA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:UMABTC"
    },
    {
        "description": "Binance UMAUSDT",
        "displaySymbol": "UMA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:UMAUSDT"
    },
    {
        "description": "Binance EOSUPUSDT",
        "displaySymbol": "EOSUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EOSUPUSDT"
    },
    {
        "description": "Binance EOSDOWNUSDT",
        "displaySymbol": "EOSDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EOSDOWNUSDT"
    },
    {
        "description": "Binance TRXUPUSDT",
        "displaySymbol": "TRXUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TRXUPUSDT"
    },
    {
        "description": "Binance TRXDOWNUSDT",
        "displaySymbol": "TRXDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TRXDOWNUSDT"
    },
    {
        "description": "Binance XRPUPUSDT",
        "displaySymbol": "XRPUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XRPUPUSDT"
    },
    {
        "description": "Binance XRPDOWNUSDT",
        "displaySymbol": "XRPDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XRPDOWNUSDT"
    },
    {
        "description": "Binance DOTUPUSDT",
        "displaySymbol": "DOTUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DOTUPUSDT"
    },
    {
        "description": "Binance DOTDOWNUSDT",
        "displaySymbol": "DOTDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DOTDOWNUSDT"
    },
    {
        "description": "Binance SRMBIDR",
        "displaySymbol": "SRM/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:SRMBIDR"
    },
    {
        "description": "Binance ONEBIDR",
        "displaySymbol": "ONE/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:ONEBIDR"
    },
    {
        "description": "Binance LINKTRY",
        "displaySymbol": "LINK/TRY",
        "hasWM": true,
        "symbol": "BINANCE:LINKTRY"
    },
    {
        "description": "Binance USDTNGN",
        "displaySymbol": "USDT/NGN",
        "hasWM": true,
        "symbol": "BINANCE:USDTNGN"
    },
    {
        "description": "Binance BELBNB",
        "displaySymbol": "BEL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BELBNB"
    },
    {
        "description": "Binance BELBTC",
        "displaySymbol": "BEL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BELBTC"
    },
    {
        "description": "Binance BELBUSD",
        "displaySymbol": "BEL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BELBUSD"
    },
    {
        "description": "Binance BELUSDT",
        "displaySymbol": "BEL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BELUSDT"
    },
    {
        "description": "Binance WINGBNB",
        "displaySymbol": "WING/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WINGBNB"
    },
    {
        "description": "Binance WINGBTC",
        "displaySymbol": "WING/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WINGBTC"
    },
    {
        "description": "Binance SWRVBNB",
        "displaySymbol": "SWRV/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SWRVBNB"
    },
    {
        "description": "Binance SWRVBUSD",
        "displaySymbol": "SWRV/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SWRVBUSD"
    },
    {
        "description": "Binance WINGBUSD",
        "displaySymbol": "WING/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:WINGBUSD"
    },
    {
        "description": "Binance WINGUSDT",
        "displaySymbol": "WING/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WINGUSDT"
    },
    {
        "description": "Binance LTCUPUSDT",
        "displaySymbol": "LTCUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LTCUPUSDT"
    },
    {
        "description": "Binance LTCDOWNUSDT",
        "displaySymbol": "LTCDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LTCDOWNUSDT"
    },
    {
        "description": "Binance LENDBKRW",
        "displaySymbol": "LEND/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:LENDBKRW"
    },
    {
        "description": "Binance SXPEUR",
        "displaySymbol": "SXP/EUR",
        "hasWM": true,
        "symbol": "BINANCE:SXPEUR"
    },
    {
        "description": "Binance CREAMBNB",
        "displaySymbol": "CREAM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CREAMBNB"
    },
    {
        "description": "Binance CREAMBUSD",
        "displaySymbol": "CREAM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CREAMBUSD"
    },
    {
        "description": "Binance UNIBNB",
        "displaySymbol": "UNI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:UNIBNB"
    },
    {
        "description": "Binance UNIBTC",
        "displaySymbol": "UNI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:UNIBTC"
    },
    {
        "description": "Binance UNIBUSD",
        "displaySymbol": "UNI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:UNIBUSD"
    },
    {
        "description": "Binance UNIUSDT",
        "displaySymbol": "UNI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:UNIUSDT"
    },
    {
        "description": "Binance NBSBTC",
        "displaySymbol": "NBS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NBSBTC"
    },
    {
        "description": "Binance NBSUSDT",
        "displaySymbol": "NBS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NBSUSDT"
    },
    {
        "description": "Binance OXTBTC",
        "displaySymbol": "OXT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OXTBTC"
    },
    {
        "description": "Binance OXTUSDT",
        "displaySymbol": "OXT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OXTUSDT"
    },
    {
        "description": "Binance SUNBTC",
        "displaySymbol": "SUN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SUNBTC"
    },
    {
        "description": "Binance SUNUSDT",
        "displaySymbol": "SUN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SUNUSDT"
    },
    {
        "description": "Binance AVAXBNB",
        "displaySymbol": "AVAX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AVAXBNB"
    },
    {
        "description": "Binance AVAXBTC",
        "displaySymbol": "AVAX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AVAXBTC"
    },
    {
        "description": "Binance AVAXBUSD",
        "displaySymbol": "AVAX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AVAXBUSD"
    },
    {
        "description": "Binance AVAXUSDT",
        "displaySymbol": "AVAX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AVAXUSDT"
    },
    {
        "description": "Binance HNTBTC",
        "displaySymbol": "HNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HNTBTC"
    },
    {
        "description": "Binance HNTUSDT",
        "displaySymbol": "HNT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:HNTUSDT"
    },
    {
        "description": "Binance BAKEBNB",
        "displaySymbol": "BAKE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BAKEBNB"
    },
    {
        "description": "Binance BURGERBNB",
        "displaySymbol": "BURGER/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BURGERBNB"
    },
    {
        "description": "Binance SXPBIDR",
        "displaySymbol": "SXP/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:SXPBIDR"
    },
    {
        "description": "Binance LINKBKRW",
        "displaySymbol": "LINK/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:LINKBKRW"
    },
    {
        "description": "Binance FLMBNB",
        "displaySymbol": "FLM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FLMBNB"
    },
    {
        "description": "Binance FLMBTC",
        "displaySymbol": "FLM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FLMBTC"
    },
    {
        "description": "Binance FLMBUSD",
        "displaySymbol": "FLM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FLMBUSD"
    },
    {
        "description": "Binance FLMUSDT",
        "displaySymbol": "FLM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FLMUSDT"
    },
    {
        "description": "Binance SCRTBTC",
        "displaySymbol": "SCRT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SCRTBTC"
    },
    {
        "description": "Binance SCRTETH",
        "displaySymbol": "SCRT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SCRTETH"
    },
    {
        "description": "Binance CAKEBNB",
        "displaySymbol": "CAKE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CAKEBNB"
    },
    {
        "description": "Binance CAKEBUSD",
        "displaySymbol": "CAKE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CAKEBUSD"
    },
    {
        "description": "Binance SPARTABNB",
        "displaySymbol": "SPARTA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SPARTABNB"
    },
    {
        "description": "Binance UNIUPUSDT",
        "displaySymbol": "UNIUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:UNIUPUSDT"
    },
    {
        "description": "Binance UNIDOWNUSDT",
        "displaySymbol": "UNIDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:UNIDOWNUSDT"
    },
    {
        "description": "Binance ORNBTC",
        "displaySymbol": "ORN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ORNBTC"
    },
    {
        "description": "Binance ORNUSDT",
        "displaySymbol": "ORN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ORNUSDT"
    },
    {
        "description": "Binance TRXNGN",
        "displaySymbol": "TRX/NGN",
        "hasWM": true,
        "symbol": "BINANCE:TRXNGN"
    },
    {
        "description": "Binance SXPTRY",
        "displaySymbol": "SXP/TRY",
        "hasWM": true,
        "symbol": "BINANCE:SXPTRY"
    },
    {
        "description": "Binance UTKBTC",
        "displaySymbol": "UTK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:UTKBTC"
    },
    {
        "description": "Binance UTKUSDT",
        "displaySymbol": "UTK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:UTKUSDT"
    },
    {
        "description": "Binance XVSBNB",
        "displaySymbol": "XVS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:XVSBNB"
    },
    {
        "description": "Binance XVSBTC",
        "displaySymbol": "XVS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XVSBTC"
    },
    {
        "description": "Binance XVSBUSD",
        "displaySymbol": "XVS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XVSBUSD"
    },
    {
        "description": "Binance XVSUSDT",
        "displaySymbol": "XVS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XVSUSDT"
    },
    {
        "description": "Binance ALPHABNB",
        "displaySymbol": "ALPHA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ALPHABNB"
    },
    {
        "description": "Binance ALPHABTC",
        "displaySymbol": "ALPHA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ALPHABTC"
    },
    {
        "description": "Binance ALPHABUSD",
        "displaySymbol": "ALPHA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ALPHABUSD"
    },
    {
        "description": "Binance ALPHAUSDT",
        "displaySymbol": "ALPHA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ALPHAUSDT"
    },
    {
        "description": "Binance VIDTBTC",
        "displaySymbol": "VIDT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VIDTBTC"
    },
    {
        "description": "Binance VIDTBUSD",
        "displaySymbol": "VIDT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:VIDTBUSD"
    },
    {
        "description": "Binance AAVEBNB",
        "displaySymbol": "AAVE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AAVEBNB"
    },
    {
        "description": "Binance BTCBRL",
        "displaySymbol": "BTC/BRL",
        "hasWM": true,
        "symbol": "BINANCE:BTCBRL"
    },
    {
        "description": "Binance USDTBRL",
        "displaySymbol": "USDT/BRL",
        "hasWM": true,
        "symbol": "BINANCE:USDTBRL"
    },
    {
        "description": "Binance AAVEBTC",
        "displaySymbol": "AAVE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AAVEBTC"
    },
    {
        "description": "Binance AAVEETH",
        "displaySymbol": "AAVE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:AAVEETH"
    },
    {
        "description": "Binance AAVEBUSD",
        "displaySymbol": "AAVE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AAVEBUSD"
    },
    {
        "description": "Binance AAVEUSDT",
        "displaySymbol": "AAVE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AAVEUSDT"
    },
    {
        "description": "Binance AAVEBKRW",
        "displaySymbol": "AAVE/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:AAVEBKRW"
    },
    {
        "description": "Binance NEARBNB",
        "displaySymbol": "NEAR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NEARBNB"
    },
    {
        "description": "Binance NEARBTC",
        "displaySymbol": "NEAR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NEARBTC"
    },
    {
        "description": "Binance NEARBUSD",
        "displaySymbol": "NEAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:NEARBUSD"
    },
    {
        "description": "Binance NEARUSDT",
        "displaySymbol": "NEAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NEARUSDT"
    },
    {
        "description": "Binance SXPUPUSDT",
        "displaySymbol": "SXPUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SXPUPUSDT"
    },
    {
        "description": "Binance SXPDOWNUSDT",
        "displaySymbol": "SXPDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SXPDOWNUSDT"
    },
    {
        "description": "Binance DOTBKRW",
        "displaySymbol": "DOT/BKRW",
        "hasWM": true,
        "symbol": "BINANCE:DOTBKRW"
    },
    {
        "description": "Binance SXPGBP",
        "displaySymbol": "SXP/GBP",
        "hasWM": true,
        "symbol": "BINANCE:SXPGBP"
    },
    {
        "description": "Binance FILBNB",
        "displaySymbol": "FIL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FILBNB"
    },
    {
        "description": "Binance FILBTC",
        "displaySymbol": "FIL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FILBTC"
    },
    {
        "description": "Binance FILBUSD",
        "displaySymbol": "FIL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FILBUSD"
    },
    {
        "description": "Binance FILUSDT",
        "displaySymbol": "FIL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FILUSDT"
    },
    {
        "description": "Binance FILUPUSDT",
        "displaySymbol": "FILUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FILUPUSDT"
    },
    {
        "description": "Binance FILDOWNUSDT",
        "displaySymbol": "FILDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FILDOWNUSDT"
    },
    {
        "description": "Binance YFIUPUSDT",
        "displaySymbol": "YFIUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:YFIUPUSDT"
    },
    {
        "description": "Binance YFIDOWNUSDT",
        "displaySymbol": "YFIDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:YFIDOWNUSDT"
    },
    {
        "description": "Binance INJBNB",
        "displaySymbol": "INJ/BNB",
        "hasWM": true,
        "symbol": "BINANCE:INJBNB"
    },
    {
        "description": "Binance INJBTC",
        "displaySymbol": "INJ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:INJBTC"
    },
    {
        "description": "Binance INJBUSD",
        "displaySymbol": "INJ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:INJBUSD"
    },
    {
        "description": "Binance INJUSDT",
        "displaySymbol": "INJ/USDT",
        "hasWM": true,
        "symbol": "BINANCE:INJUSDT"
    },
    {
        "description": "Binance AERGOBTC",
        "displaySymbol": "AERGO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AERGOBTC"
    },
    {
        "description": "Binance AERGOBUSD",
        "displaySymbol": "AERGO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AERGOBUSD"
    },
    {
        "description": "Binance LINKEUR",
        "displaySymbol": "LINK/EUR",
        "hasWM": true,
        "symbol": "BINANCE:LINKEUR"
    },
    {
        "description": "Binance ONEBUSD",
        "displaySymbol": "ONE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ONEBUSD"
    },
    {
        "description": "Binance EASYETH",
        "displaySymbol": "EASY/ETH",
        "hasWM": true,
        "symbol": "BINANCE:EASYETH"
    },
    {
        "description": "Binance AUDIOBTC",
        "displaySymbol": "AUDIO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AUDIOBTC"
    },
    {
        "description": "Binance AUDIOBUSD",
        "displaySymbol": "AUDIO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AUDIOBUSD"
    },
    {
        "description": "Binance AUDIOUSDT",
        "displaySymbol": "AUDIO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AUDIOUSDT"
    },
    {
        "description": "Binance CTKBNB",
        "displaySymbol": "CTK/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CTKBNB"
    },
    {
        "description": "Binance CTKBTC",
        "displaySymbol": "CTK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CTKBTC"
    },
    {
        "description": "Binance CTKBUSD",
        "displaySymbol": "CTK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CTKBUSD"
    },
    {
        "description": "Binance CTKUSDT",
        "displaySymbol": "CTK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CTKUSDT"
    },
    {
        "description": "Binance BCHUPUSDT",
        "displaySymbol": "BCHUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BCHUPUSDT"
    },
    {
        "description": "Binance BCHDOWNUSDT",
        "displaySymbol": "BCHDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BCHDOWNUSDT"
    },
    {
        "description": "Binance BOTBTC",
        "displaySymbol": "BOT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BOTBTC"
    },
    {
        "description": "Binance BOTBUSD",
        "displaySymbol": "BOT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BOTBUSD"
    },
    {
        "description": "Binance ETHBRL",
        "displaySymbol": "ETH/BRL",
        "hasWM": true,
        "symbol": "BINANCE:ETHBRL"
    },
    {
        "description": "Binance DOTEUR",
        "displaySymbol": "DOT/EUR",
        "hasWM": true,
        "symbol": "BINANCE:DOTEUR"
    },
    {
        "description": "Binance AKROBTC",
        "displaySymbol": "AKRO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AKROBTC"
    },
    {
        "description": "Binance AKROUSDT",
        "displaySymbol": "AKRO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AKROUSDT"
    },
    {
        "description": "Binance KP3RBNB",
        "displaySymbol": "KP3R/BNB",
        "hasWM": true,
        "symbol": "BINANCE:KP3RBNB"
    },
    {
        "description": "Binance KP3RBUSD",
        "displaySymbol": "KP3R/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KP3RBUSD"
    },
    {
        "description": "Binance AXSBNB",
        "displaySymbol": "AXS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AXSBNB"
    },
    {
        "description": "Binance AXSBTC",
        "displaySymbol": "AXS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AXSBTC"
    },
    {
        "description": "Binance AXSBUSD",
        "displaySymbol": "AXS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AXSBUSD"
    },
    {
        "description": "Binance AXSUSDT",
        "displaySymbol": "AXS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AXSUSDT"
    },
    {
        "description": "Binance HARDBNB",
        "displaySymbol": "HARD/BNB",
        "hasWM": true,
        "symbol": "BINANCE:HARDBNB"
    },
    {
        "description": "Binance HARDBTC",
        "displaySymbol": "HARD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HARDBTC"
    },
    {
        "description": "Binance HARDBUSD",
        "displaySymbol": "HARD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:HARDBUSD"
    },
    {
        "description": "Binance HARDUSDT",
        "displaySymbol": "HARD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:HARDUSDT"
    },
    {
        "description": "Binance BNBBRL",
        "displaySymbol": "BNB/BRL",
        "hasWM": true,
        "symbol": "BINANCE:BNBBRL"
    },
    {
        "description": "Binance LTCEUR",
        "displaySymbol": "LTC/EUR",
        "hasWM": true,
        "symbol": "BINANCE:LTCEUR"
    },
    {
        "description": "Binance RENBTCBTC",
        "displaySymbol": "RENBTC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RENBTCBTC"
    },
    {
        "description": "Binance RENBTCETH",
        "displaySymbol": "RENBTC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:RENBTCETH"
    },
    {
        "description": "Binance DNTBUSD",
        "displaySymbol": "DNT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DNTBUSD"
    },
    {
        "description": "Binance DNTUSDT",
        "displaySymbol": "DNT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DNTUSDT"
    },
    {
        "description": "Binance SLPETH",
        "displaySymbol": "SLP/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SLPETH"
    },
    {
        "description": "Binance ADAEUR",
        "displaySymbol": "ADA/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ADAEUR"
    },
    {
        "description": "Binance LTCNGN",
        "displaySymbol": "LTC/NGN",
        "hasWM": true,
        "symbol": "BINANCE:LTCNGN"
    },
    {
        "description": "Binance CVPETH",
        "displaySymbol": "CVP/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CVPETH"
    },
    {
        "description": "Binance CVPBUSD",
        "displaySymbol": "CVP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CVPBUSD"
    },
    {
        "description": "Binance STRAXBTC",
        "displaySymbol": "STRAX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:STRAXBTC"
    },
    {
        "description": "Binance STRAXETH",
        "displaySymbol": "STRAX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:STRAXETH"
    },
    {
        "description": "Binance STRAXBUSD",
        "displaySymbol": "STRAX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:STRAXBUSD"
    },
    {
        "description": "Binance STRAXUSDT",
        "displaySymbol": "STRAX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STRAXUSDT"
    },
    {
        "description": "Binance FORBTC",
        "displaySymbol": "FOR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FORBTC"
    },
    {
        "description": "Binance FORBUSD",
        "displaySymbol": "FOR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FORBUSD"
    },
    {
        "description": "Binance UNFIBNB",
        "displaySymbol": "UNFI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:UNFIBNB"
    },
    {
        "description": "Binance UNFIBTC",
        "displaySymbol": "UNFI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:UNFIBTC"
    },
    {
        "description": "Binance UNFIBUSD",
        "displaySymbol": "UNFI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:UNFIBUSD"
    },
    {
        "description": "Binance UNFIUSDT",
        "displaySymbol": "UNFI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:UNFIUSDT"
    },
    {
        "description": "Binance FRONTETH",
        "displaySymbol": "FRONT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:FRONTETH"
    },
    {
        "description": "Binance FRONTBUSD",
        "displaySymbol": "FRONT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FRONTBUSD"
    },
    {
        "description": "Binance BCHABUSD",
        "displaySymbol": "BCHA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BCHABUSD"
    },
    {
        "description": "Binance ROSEBTC",
        "displaySymbol": "ROSE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ROSEBTC"
    },
    {
        "description": "Binance ROSEBUSD",
        "displaySymbol": "ROSE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ROSEBUSD"
    },
    {
        "description": "Binance ROSEUSDT",
        "displaySymbol": "ROSE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ROSEUSDT"
    },
    {
        "description": "Binance AVAXTRY",
        "displaySymbol": "AVAX/TRY",
        "hasWM": true,
        "symbol": "BINANCE:AVAXTRY"
    },
    {
        "description": "Binance BUSDBRL",
        "displaySymbol": "BUSD/BRL",
        "hasWM": true,
        "symbol": "BINANCE:BUSDBRL"
    },
    {
        "description": "Binance AVAUSDT",
        "displaySymbol": "AVA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AVAUSDT"
    },
    {
        "description": "Binance SYSBUSD",
        "displaySymbol": "SYS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SYSBUSD"
    },
    {
        "description": "Binance XEMUSDT",
        "displaySymbol": "XEM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XEMUSDT"
    },
    {
        "description": "Binance HEGICETH",
        "displaySymbol": "HEGIC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:HEGICETH"
    },
    {
        "description": "Binance HEGICBUSD",
        "displaySymbol": "HEGIC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:HEGICBUSD"
    },
    {
        "description": "Binance AAVEUPUSDT",
        "displaySymbol": "AAVEUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AAVEUPUSDT"
    },
    {
        "description": "Binance AAVEDOWNUSDT",
        "displaySymbol": "AAVEDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AAVEDOWNUSDT"
    },
    {
        "description": "Binance PROMBNB",
        "displaySymbol": "PROM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PROMBNB"
    },
    {
        "description": "Binance PROMBUSD",
        "displaySymbol": "PROM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PROMBUSD"
    },
    {
        "description": "Binance XRPBRL",
        "displaySymbol": "XRP/BRL",
        "hasWM": true,
        "symbol": "BINANCE:XRPBRL"
    },
    {
        "description": "Binance XRPNGN",
        "displaySymbol": "XRP/NGN",
        "hasWM": true,
        "symbol": "BINANCE:XRPNGN"
    },
    {
        "description": "Binance SKLBTC",
        "displaySymbol": "SKL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SKLBTC"
    },
    {
        "description": "Binance SKLBUSD",
        "displaySymbol": "SKL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SKLBUSD"
    },
    {
        "description": "Binance SKLUSDT",
        "displaySymbol": "SKL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SKLUSDT"
    },
    {
        "description": "Binance BCHEUR",
        "displaySymbol": "BCH/EUR",
        "hasWM": true,
        "symbol": "BINANCE:BCHEUR"
    },
    {
        "description": "Binance YFIEUR",
        "displaySymbol": "YFI/EUR",
        "hasWM": true,
        "symbol": "BINANCE:YFIEUR"
    },
    {
        "description": "Binance ZILBIDR",
        "displaySymbol": "ZIL/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:ZILBIDR"
    },
    {
        "description": "Binance SUSDBTC",
        "displaySymbol": "SUSD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SUSDBTC"
    },
    {
        "description": "Binance SUSDETH",
        "displaySymbol": "SUSD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SUSDETH"
    },
    {
        "description": "Binance SUSDUSDT",
        "displaySymbol": "SUSD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SUSDUSDT"
    },
    {
        "description": "Binance COVERETH",
        "displaySymbol": "COVER/ETH",
        "hasWM": true,
        "symbol": "BINANCE:COVERETH"
    },
    {
        "description": "Binance COVERBUSD",
        "displaySymbol": "COVER/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:COVERBUSD"
    },
    {
        "description": "Binance GLMBTC",
        "displaySymbol": "GLM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GLMBTC"
    },
    {
        "description": "Binance GLMETH",
        "displaySymbol": "GLM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GLMETH"
    },
    {
        "description": "Binance GHSTETH",
        "displaySymbol": "GHST/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GHSTETH"
    },
    {
        "description": "Binance GHSTBUSD",
        "displaySymbol": "GHST/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GHSTBUSD"
    },
    {
        "description": "Binance SUSHIUPUSDT",
        "displaySymbol": "SUSHIUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SUSHIUPUSDT"
    },
    {
        "description": "Binance SUSHIDOWNUSDT",
        "displaySymbol": "SUSHIDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SUSHIDOWNUSDT"
    },
    {
        "description": "Binance XLMUPUSDT",
        "displaySymbol": "XLMUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XLMUPUSDT"
    },
    {
        "description": "Binance XLMDOWNUSDT",
        "displaySymbol": "XLMDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XLMDOWNUSDT"
    },
    {
        "description": "Binance LINKBRL",
        "displaySymbol": "LINK/BRL",
        "hasWM": true,
        "symbol": "BINANCE:LINKBRL"
    },
    {
        "description": "Binance LINKNGN",
        "displaySymbol": "LINK/NGN",
        "hasWM": true,
        "symbol": "BINANCE:LINKNGN"
    },
    {
        "description": "Binance LTCRUB",
        "displaySymbol": "LTC/RUB",
        "hasWM": true,
        "symbol": "BINANCE:LTCRUB"
    },
    {
        "description": "Binance TRXTRY",
        "displaySymbol": "TRX/TRY",
        "hasWM": true,
        "symbol": "BINANCE:TRXTRY"
    },
    {
        "description": "Binance XLMEUR",
        "displaySymbol": "XLM/EUR",
        "hasWM": true,
        "symbol": "BINANCE:XLMEUR"
    },
    {
        "description": "Binance DFETH",
        "displaySymbol": "DF/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DFETH"
    },
    {
        "description": "Binance DFBUSD",
        "displaySymbol": "DF/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DFBUSD"
    },
    {
        "description": "Binance GRTBTC",
        "displaySymbol": "GRT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GRTBTC"
    },
    {
        "description": "Binance GRTETH",
        "displaySymbol": "GRT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GRTETH"
    },
    {
        "description": "Binance GRTUSDT",
        "displaySymbol": "GRT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GRTUSDT"
    },
    {
        "description": "Binance JUVBTC",
        "displaySymbol": "JUV/BTC",
        "hasWM": true,
        "symbol": "BINANCE:JUVBTC"
    },
    {
        "description": "Binance JUVBUSD",
        "displaySymbol": "JUV/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:JUVBUSD"
    },
    {
        "description": "Binance JUVUSDT",
        "displaySymbol": "JUV/USDT",
        "hasWM": true,
        "symbol": "BINANCE:JUVUSDT"
    },
    {
        "description": "Binance PSGBTC",
        "displaySymbol": "PSG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PSGBTC"
    },
    {
        "description": "Binance PSGBUSD",
        "displaySymbol": "PSG/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PSGBUSD"
    },
    {
        "description": "Binance PSGUSDT",
        "displaySymbol": "PSG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PSGUSDT"
    },
    {
        "description": "Binance BUSDBVND",
        "displaySymbol": "BUSD/BVND",
        "hasWM": true,
        "symbol": "BINANCE:BUSDBVND"
    },
    {
        "description": "Binance USDTBVND",
        "displaySymbol": "USDT/BVND",
        "hasWM": true,
        "symbol": "BINANCE:USDTBVND"
    },
    {
        "description": "Binance 1INCHBTC",
        "displaySymbol": "1INCH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:1INCHBTC"
    },
    {
        "description": "Binance 1INCHUSDT",
        "displaySymbol": "1INCH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:1INCHUSDT"
    },
    {
        "description": "Binance REEFBTC",
        "displaySymbol": "REEF/BTC",
        "hasWM": true,
        "symbol": "BINANCE:REEFBTC"
    },
    {
        "description": "Binance REEFUSDT",
        "displaySymbol": "REEF/USDT",
        "hasWM": true,
        "symbol": "BINANCE:REEFUSDT"
    },
    {
        "description": "Binance OGBTC",
        "displaySymbol": "OG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OGBTC"
    },
    {
        "description": "Binance OGUSDT",
        "displaySymbol": "OG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OGUSDT"
    },
    {
        "description": "Binance ATMBTC",
        "displaySymbol": "ATM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ATMBTC"
    },
    {
        "description": "Binance ATMUSDT",
        "displaySymbol": "ATM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ATMUSDT"
    },
    {
        "description": "Binance ASRBTC",
        "displaySymbol": "ASR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ASRBTC"
    },
    {
        "description": "Binance ASRUSDT",
        "displaySymbol": "ASR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ASRUSDT"
    },
    {
        "description": "Binance CELOBTC",
        "displaySymbol": "CELO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CELOBTC"
    },
    {
        "description": "Binance CELOUSDT",
        "displaySymbol": "CELO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CELOUSDT"
    },
    {
        "description": "Binance RIFBTC",
        "displaySymbol": "RIF/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RIFBTC"
    },
    {
        "description": "Binance RIFUSDT",
        "displaySymbol": "RIF/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RIFUSDT"
    },
    {
        "description": "Binance CHZTRY",
        "displaySymbol": "CHZ/TRY",
        "hasWM": true,
        "symbol": "BINANCE:CHZTRY"
    },
    {
        "description": "Binance XLMTRY",
        "displaySymbol": "XLM/TRY",
        "hasWM": true,
        "symbol": "BINANCE:XLMTRY"
    },
    {
        "description": "Binance LINKGBP",
        "displaySymbol": "LINK/GBP",
        "hasWM": true,
        "symbol": "BINANCE:LINKGBP"
    },
    {
        "description": "Binance GRTEUR",
        "displaySymbol": "GRT/EUR",
        "hasWM": true,
        "symbol": "BINANCE:GRTEUR"
    },
    {
        "description": "Binance BTCSTBTC",
        "displaySymbol": "BTCST/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BTCSTBTC"
    },
    {
        "description": "Binance BTCSTBUSD",
        "displaySymbol": "BTCST/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTCSTBUSD"
    },
    {
        "description": "Binance BTCSTUSDT",
        "displaySymbol": "BTCST/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTCSTUSDT"
    },
    {
        "description": "Binance TRUBTC",
        "displaySymbol": "TRU/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TRUBTC"
    },
    {
        "description": "Binance TRUBUSD",
        "displaySymbol": "TRU/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TRUBUSD"
    },
    {
        "description": "Binance TRUUSDT",
        "displaySymbol": "TRU/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TRUUSDT"
    },
    {
        "description": "Binance DEXEETH",
        "displaySymbol": "DEXE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DEXEETH"
    },
    {
        "description": "Binance DEXEBUSD",
        "displaySymbol": "DEXE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DEXEBUSD"
    },
    {
        "description": "Binance EOSEUR",
        "displaySymbol": "EOS/EUR",
        "hasWM": true,
        "symbol": "BINANCE:EOSEUR"
    },
    {
        "description": "Binance LTCBRL",
        "displaySymbol": "LTC/BRL",
        "hasWM": true,
        "symbol": "BINANCE:LTCBRL"
    },
    {
        "description": "Binance USDCBUSD",
        "displaySymbol": "USDC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:USDCBUSD"
    },
    {
        "description": "Binance TUSDBUSD",
        "displaySymbol": "TUSD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TUSDBUSD"
    },
    {
        "description": "Binance PAXBUSD",
        "displaySymbol": "PAX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PAXBUSD"
    },
    {
        "description": "Binance CKBBTC",
        "displaySymbol": "CKB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CKBBTC"
    },
    {
        "description": "Binance CKBBUSD",
        "displaySymbol": "CKB/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CKBBUSD"
    },
    {
        "description": "Binance CKBUSDT",
        "displaySymbol": "CKB/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CKBUSDT"
    },
    {
        "description": "Binance TWTBTC",
        "displaySymbol": "TWT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TWTBTC"
    },
    {
        "description": "Binance TWTBUSD",
        "displaySymbol": "TWT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TWTBUSD"
    },
    {
        "description": "Binance TWTUSDT",
        "displaySymbol": "TWT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TWTUSDT"
    },
    {
        "description": "Binance FIROBTC",
        "displaySymbol": "FIRO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FIROBTC"
    },
    {
        "description": "Binance FIROETH",
        "displaySymbol": "FIRO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:FIROETH"
    },
    {
        "description": "Binance FIROUSDT",
        "displaySymbol": "FIRO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FIROUSDT"
    },
    {
        "description": "Binance BETHETH",
        "displaySymbol": "BETH/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BETHETH"
    },
    {
        "description": "Binance DOGEEUR",
        "displaySymbol": "DOGE/EUR",
        "hasWM": true,
        "symbol": "BINANCE:DOGEEUR"
    },
    {
        "description": "Binance DOGETRY",
        "displaySymbol": "DOGE/TRY",
        "hasWM": true,
        "symbol": "BINANCE:DOGETRY"
    },
    {
        "description": "Binance DOGEAUD",
        "displaySymbol": "DOGE/AUD",
        "hasWM": true,
        "symbol": "BINANCE:DOGEAUD"
    },
    {
        "description": "Binance DOGEBRL",
        "displaySymbol": "DOGE/BRL",
        "hasWM": true,
        "symbol": "BINANCE:DOGEBRL"
    },
    {
        "description": "Binance DOTNGN",
        "displaySymbol": "DOT/NGN",
        "hasWM": true,
        "symbol": "BINANCE:DOTNGN"
    },
    {
        "description": "Binance PROSETH",
        "displaySymbol": "PROS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:PROSETH"
    },
    {
        "description": "Binance LITBTC",
        "displaySymbol": "LIT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LITBTC"
    },
    {
        "description": "Binance LITBUSD",
        "displaySymbol": "LIT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LITBUSD"
    },
    {
        "description": "Binance LITUSDT",
        "displaySymbol": "LIT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LITUSDT"
    },
    {
        "description": "Binance BTCVAI",
        "displaySymbol": "BTC/VAI",
        "hasWM": true,
        "symbol": "BINANCE:BTCVAI"
    },
    {
        "description": "Binance BUSDVAI",
        "displaySymbol": "BUSD/VAI",
        "hasWM": true,
        "symbol": "BINANCE:BUSDVAI"
    },
    {
        "description": "Binance SFPBTC",
        "displaySymbol": "SFP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SFPBTC"
    },
    {
        "description": "Binance SFPBUSD",
        "displaySymbol": "SFP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SFPBUSD"
    },
    {
        "description": "Binance SFPUSDT",
        "displaySymbol": "SFP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SFPUSDT"
    },
    {
        "description": "Binance DOGEGBP",
        "displaySymbol": "DOGE/GBP",
        "hasWM": true,
        "symbol": "BINANCE:DOGEGBP"
    },
    {
        "description": "Binance DOTTRY",
        "displaySymbol": "DOT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:DOTTRY"
    },
    {
        "description": "Binance FXSBTC",
        "displaySymbol": "FXS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FXSBTC"
    },
    {
        "description": "Binance FXSBUSD",
        "displaySymbol": "FXS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FXSBUSD"
    },
    {
        "description": "Binance DODOBTC",
        "displaySymbol": "DODO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DODOBTC"
    },
    {
        "description": "Binance DODOBUSD",
        "displaySymbol": "DODO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DODOBUSD"
    },
    {
        "description": "Binance DODOUSDT",
        "displaySymbol": "DODO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DODOUSDT"
    },
    {
        "description": "Binance FRONTBTC",
        "displaySymbol": "FRONT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FRONTBTC"
    },
    {
        "description": "Binance EASYBTC",
        "displaySymbol": "EASY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:EASYBTC"
    },
    {
        "description": "Binance CAKEBTC",
        "displaySymbol": "CAKE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CAKEBTC"
    },
    {
        "description": "Binance CAKEUSDT",
        "displaySymbol": "CAKE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CAKEUSDT"
    },
    {
        "description": "Binance BAKEBUSD",
        "displaySymbol": "BAKE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BAKEBUSD"
    },
    {
        "description": "Binance UFTETH",
        "displaySymbol": "UFT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:UFTETH"
    },
    {
        "description": "Binance UFTBUSD",
        "displaySymbol": "UFT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:UFTBUSD"
    },
    {
        "description": "Binance 1INCHBUSD",
        "displaySymbol": "1INCH/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:1INCHBUSD"
    },
    {
        "description": "Binance BANDBUSD",
        "displaySymbol": "BAND/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BANDBUSD"
    },
    {
        "description": "Binance GRTBUSD",
        "displaySymbol": "GRT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GRTBUSD"
    },
    {
        "description": "Binance IOSTBUSD",
        "displaySymbol": "IOST/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:IOSTBUSD"
    },
    {
        "description": "Binance OMGBUSD",
        "displaySymbol": "OMG/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OMGBUSD"
    },
    {
        "description": "Binance REEFBUSD",
        "displaySymbol": "REEF/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:REEFBUSD"
    },
    {
        "description": "Binance ACMBTC",
        "displaySymbol": "ACM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ACMBTC"
    },
    {
        "description": "Binance ACMBUSD",
        "displaySymbol": "ACM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ACMBUSD"
    },
    {
        "description": "Binance ACMUSDT",
        "displaySymbol": "ACM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ACMUSDT"
    },
    {
        "description": "Binance AUCTIONBTC",
        "displaySymbol": "AUCTION/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AUCTIONBTC"
    },
    {
        "description": "Binance AUCTIONBUSD",
        "displaySymbol": "AUCTION/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AUCTIONBUSD"
    },
    {
        "description": "Binance PHABTC",
        "displaySymbol": "PHA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PHABTC"
    },
    {
        "description": "Binance PHABUSD",
        "displaySymbol": "PHA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PHABUSD"
    },
    {
        "description": "Binance DOTGBP",
        "displaySymbol": "DOT/GBP",
        "hasWM": true,
        "symbol": "BINANCE:DOTGBP"
    },
    {
        "description": "Binance ADATRY",
        "displaySymbol": "ADA/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ADATRY"
    },
    {
        "description": "Binance ADABRL",
        "displaySymbol": "ADA/BRL",
        "hasWM": true,
        "symbol": "BINANCE:ADABRL"
    },
    {
        "description": "Binance ADAGBP",
        "displaySymbol": "ADA/GBP",
        "hasWM": true,
        "symbol": "BINANCE:ADAGBP"
    },
    {
        "description": "Binance TVKBTC",
        "displaySymbol": "TVK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TVKBTC"
    },
    {
        "description": "Binance TVKBUSD",
        "displaySymbol": "TVK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TVKBUSD"
    },
    {
        "description": "Binance BADGERBTC",
        "displaySymbol": "BADGER/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BADGERBTC"
    },
    {
        "description": "Binance BADGERBUSD",
        "displaySymbol": "BADGER/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BADGERBUSD"
    },
    {
        "description": "Binance BADGERUSDT",
        "displaySymbol": "BADGER/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BADGERUSDT"
    },
    {
        "description": "Binance FISBTC",
        "displaySymbol": "FIS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FISBTC"
    },
    {
        "description": "Binance FISBUSD",
        "displaySymbol": "FIS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FISBUSD"
    },
    {
        "description": "Binance FISUSDT",
        "displaySymbol": "FIS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FISUSDT"
    },
    {
        "description": "Binance DOTBRL",
        "displaySymbol": "DOT/BRL",
        "hasWM": true,
        "symbol": "BINANCE:DOTBRL"
    },
    {
        "description": "Binance ADAAUD",
        "displaySymbol": "ADA/AUD",
        "hasWM": true,
        "symbol": "BINANCE:ADAAUD"
    },
    {
        "description": "Binance HOTTRY",
        "displaySymbol": "HOT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:HOTTRY"
    },
    {
        "description": "Binance EGLDEUR",
        "displaySymbol": "EGLD/EUR",
        "hasWM": true,
        "symbol": "BINANCE:EGLDEUR"
    },
    {
        "description": "Binance OMBTC",
        "displaySymbol": "OM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OMBTC"
    },
    {
        "description": "Binance OMBUSD",
        "displaySymbol": "OM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OMBUSD"
    },
    {
        "description": "Binance OMUSDT",
        "displaySymbol": "OM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OMUSDT"
    },
    {
        "description": "Binance PONDBTC",
        "displaySymbol": "POND/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PONDBTC"
    },
    {
        "description": "Binance PONDBUSD",
        "displaySymbol": "POND/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PONDBUSD"
    },
    {
        "description": "Binance PONDUSDT",
        "displaySymbol": "POND/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PONDUSDT"
    },
    {
        "description": "Binance DEGOBTC",
        "displaySymbol": "DEGO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DEGOBTC"
    },
    {
        "description": "Binance DEGOBUSD",
        "displaySymbol": "DEGO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DEGOBUSD"
    },
    {
        "description": "Binance DEGOUSDT",
        "displaySymbol": "DEGO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DEGOUSDT"
    },
    {
        "description": "Binance AVAXEUR",
        "displaySymbol": "AVAX/EUR",
        "hasWM": true,
        "symbol": "BINANCE:AVAXEUR"
    },
    {
        "description": "Binance BTTTRY",
        "displaySymbol": "BTT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:BTTTRY"
    },
    {
        "description": "Binance CHZBRL",
        "displaySymbol": "CHZ/BRL",
        "hasWM": true,
        "symbol": "BINANCE:CHZBRL"
    },
    {
        "description": "Binance UNIEUR",
        "displaySymbol": "UNI/EUR",
        "hasWM": true,
        "symbol": "BINANCE:UNIEUR"
    },
    {
        "description": "Binance ALICEBTC",
        "displaySymbol": "ALICE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ALICEBTC"
    },
    {
        "description": "Binance ALICEBUSD",
        "displaySymbol": "ALICE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ALICEBUSD"
    },
    {
        "description": "Binance ALICEUSDT",
        "displaySymbol": "ALICE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ALICEUSDT"
    },
    {
        "description": "Binance CHZBUSD",
        "displaySymbol": "CHZ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CHZBUSD"
    },
    {
        "description": "Binance CHZEUR",
        "displaySymbol": "CHZ/EUR",
        "hasWM": true,
        "symbol": "BINANCE:CHZEUR"
    },
    {
        "description": "Binance CHZGBP",
        "displaySymbol": "CHZ/GBP",
        "hasWM": true,
        "symbol": "BINANCE:CHZGBP"
    },
    {
        "description": "Binance BIFIBNB",
        "displaySymbol": "BIFI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BIFIBNB"
    },
    {
        "description": "Binance BIFIBUSD",
        "displaySymbol": "BIFI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BIFIBUSD"
    },
    {
        "description": "Binance LINABTC",
        "displaySymbol": "LINA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LINABTC"
    },
    {
        "description": "Binance LINABUSD",
        "displaySymbol": "LINA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LINABUSD"
    },
    {
        "description": "Binance LINAUSDT",
        "displaySymbol": "LINA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LINAUSDT"
    },
    {
        "description": "Binance ADARUB",
        "displaySymbol": "ADA/RUB",
        "hasWM": true,
        "symbol": "BINANCE:ADARUB"
    },
    {
        "description": "Binance ENJBRL",
        "displaySymbol": "ENJ/BRL",
        "hasWM": true,
        "symbol": "BINANCE:ENJBRL"
    },
    {
        "description": "Binance ENJEUR",
        "displaySymbol": "ENJ/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ENJEUR"
    },
    {
        "description": "Binance MATICEUR",
        "displaySymbol": "MATIC/EUR",
        "hasWM": true,
        "symbol": "BINANCE:MATICEUR"
    },
    {
        "description": "Binance NEOTRY",
        "displaySymbol": "NEO/TRY",
        "hasWM": true,
        "symbol": "BINANCE:NEOTRY"
    },
    {
        "description": "Binance PERPBTC",
        "displaySymbol": "PERP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PERPBTC"
    },
    {
        "description": "Binance PERPBUSD",
        "displaySymbol": "PERP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PERPBUSD"
    },
    {
        "description": "Binance PERPUSDT",
        "displaySymbol": "PERP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PERPUSDT"
    },
    {
        "description": "Binance RAMPBTC",
        "displaySymbol": "RAMP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RAMPBTC"
    },
    {
        "description": "Binance RAMPBUSD",
        "displaySymbol": "RAMP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RAMPBUSD"
    },
    {
        "description": "Binance RAMPUSDT",
        "displaySymbol": "RAMP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RAMPUSDT"
    },
    {
        "description": "Binance SUPERBTC",
        "displaySymbol": "SUPER/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SUPERBTC"
    },
    {
        "description": "Binance SUPERBUSD",
        "displaySymbol": "SUPER/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SUPERBUSD"
    },
    {
        "description": "Binance SUPERUSDT",
        "displaySymbol": "SUPER/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SUPERUSDT"
    },
    {
        "description": "Binance CFXBTC",
        "displaySymbol": "CFX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CFXBTC"
    },
    {
        "description": "Binance CFXBUSD",
        "displaySymbol": "CFX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CFXBUSD"
    },
    {
        "description": "Binance CFXUSDT",
        "displaySymbol": "CFX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CFXUSDT"
    },
    {
        "description": "Binance ENJGBP",
        "displaySymbol": "ENJ/GBP",
        "hasWM": true,
        "symbol": "BINANCE:ENJGBP"
    },
    {
        "description": "Binance EOSTRY",
        "displaySymbol": "EOS/TRY",
        "hasWM": true,
        "symbol": "BINANCE:EOSTRY"
    },
    {
        "description": "Binance LTCGBP",
        "displaySymbol": "LTC/GBP",
        "hasWM": true,
        "symbol": "BINANCE:LTCGBP"
    },
    {
        "description": "Binance LUNAEUR",
        "displaySymbol": "LUNA/EUR",
        "hasWM": true,
        "symbol": "BINANCE:LUNAEUR"
    },
    {
        "description": "Binance RVNTRY",
        "displaySymbol": "RVN/TRY",
        "hasWM": true,
        "symbol": "BINANCE:RVNTRY"
    },
    {
        "description": "Binance THETAEUR",
        "displaySymbol": "THETA/EUR",
        "hasWM": true,
        "symbol": "BINANCE:THETAEUR"
    },
    {
        "description": "Binance XVGBUSD",
        "displaySymbol": "XVG/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XVGBUSD"
    },
    {
        "description": "Binance EPSBTC",
        "displaySymbol": "EPS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:EPSBTC"
    },
    {
        "description": "Binance EPSBUSD",
        "displaySymbol": "EPS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:EPSBUSD"
    },
    {
        "description": "Binance EPSUSDT",
        "displaySymbol": "EPS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EPSUSDT"
    },
    {
        "description": "Binance AUTOBTC",
        "displaySymbol": "AUTO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AUTOBTC"
    },
    {
        "description": "Binance AUTOBUSD",
        "displaySymbol": "AUTO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AUTOBUSD"
    },
    {
        "description": "Binance AUTOUSDT",
        "displaySymbol": "AUTO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AUTOUSDT"
    },
    {
        "description": "Binance TKOBTC",
        "displaySymbol": "TKO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TKOBTC"
    },
    {
        "description": "Binance TKOBIDR",
        "displaySymbol": "TKO/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:TKOBIDR"
    },
    {
        "description": "Binance TKOBUSD",
        "displaySymbol": "TKO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TKOBUSD"
    },
    {
        "description": "Binance TKOUSDT",
        "displaySymbol": "TKO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TKOUSDT"
    },
    {
        "description": "Binance PUNDIXETH",
        "displaySymbol": "PUNDIX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:PUNDIXETH"
    },
    {
        "description": "Binance PUNDIXUSDT",
        "displaySymbol": "PUNDIX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PUNDIXUSDT"
    },
    {
        "description": "Binance BTTBRL",
        "displaySymbol": "BTT/BRL",
        "hasWM": true,
        "symbol": "BINANCE:BTTBRL"
    },
    {
        "description": "Binance BTTEUR",
        "displaySymbol": "BTT/EUR",
        "hasWM": true,
        "symbol": "BINANCE:BTTEUR"
    },
    {
        "description": "Binance HOTEUR",
        "displaySymbol": "HOT/EUR",
        "hasWM": true,
        "symbol": "BINANCE:HOTEUR"
    },
    {
        "description": "Binance WINEUR",
        "displaySymbol": "WIN/EUR",
        "hasWM": true,
        "symbol": "BINANCE:WINEUR"
    },
    {
        "description": "Binance TLMBTC",
        "displaySymbol": "TLM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TLMBTC"
    },
    {
        "description": "Binance TLMBUSD",
        "displaySymbol": "TLM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TLMBUSD"
    },
    {
        "description": "Binance TLMUSDT",
        "displaySymbol": "TLM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TLMUSDT"
    },
    {
        "description": "Binance 1INCHUPUSDT",
        "displaySymbol": "1INCHUP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:1INCHUPUSDT"
    },
    {
        "description": "Binance 1INCHDOWNUSDT",
        "displaySymbol": "1INCHDOWN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:1INCHDOWNUSDT"
    },
    {
        "description": "Binance BTGBUSD",
        "displaySymbol": "BTG/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTGBUSD"
    },
    {
        "description": "Binance BTGUSDT",
        "displaySymbol": "BTG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTGUSDT"
    },
    {
        "description": "Binance HOTBUSD",
        "displaySymbol": "HOT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:HOTBUSD"
    },
    {
        "description": "Binance BNBUAH",
        "displaySymbol": "BNB/UAH",
        "hasWM": true,
        "symbol": "BINANCE:BNBUAH"
    },
    {
        "description": "Binance ONTTRY",
        "displaySymbol": "ONT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ONTTRY"
    },
    {
        "description": "Binance VETEUR",
        "displaySymbol": "VET/EUR",
        "hasWM": true,
        "symbol": "BINANCE:VETEUR"
    },
    {
        "description": "Binance VETGBP",
        "displaySymbol": "VET/GBP",
        "hasWM": true,
        "symbol": "BINANCE:VETGBP"
    },
    {
        "description": "Binance WINBRL",
        "displaySymbol": "WIN/BRL",
        "hasWM": true,
        "symbol": "BINANCE:WINBRL"
    },
    {
        "description": "Binance MIRBTC",
        "displaySymbol": "MIR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MIRBTC"
    },
    {
        "description": "Binance MIRBUSD",
        "displaySymbol": "MIR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MIRBUSD"
    },
    {
        "description": "Binance MIRUSDT",
        "displaySymbol": "MIR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MIRUSDT"
    },
    {
        "description": "Binance BARBTC",
        "displaySymbol": "BAR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BARBTC"
    },
    {
        "description": "Binance BARBUSD",
        "displaySymbol": "BAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BARBUSD"
    },
    {
        "description": "Binance BARUSDT",
        "displaySymbol": "BAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BARUSDT"
    },
    {
        "description": "Binance FORTHBTC",
        "displaySymbol": "FORTH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FORTHBTC"
    },
    {
        "description": "Binance FORTHBUSD",
        "displaySymbol": "FORTH/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FORTHBUSD"
    },
    {
        "description": "Binance FORTHUSDT",
        "displaySymbol": "FORTH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FORTHUSDT"
    },
    {
        "description": "Binance CAKEGBP",
        "displaySymbol": "CAKE/GBP",
        "hasWM": true,
        "symbol": "BINANCE:CAKEGBP"
    },
    {
        "description": "Binance DOGERUB",
        "displaySymbol": "DOGE/RUB",
        "hasWM": true,
        "symbol": "BINANCE:DOGERUB"
    },
    {
        "description": "Binance HOTBRL",
        "displaySymbol": "HOT/BRL",
        "hasWM": true,
        "symbol": "BINANCE:HOTBRL"
    },
    {
        "description": "Binance WRXEUR",
        "displaySymbol": "WRX/EUR",
        "hasWM": true,
        "symbol": "BINANCE:WRXEUR"
    },
    {
        "description": "Binance EZBTC",
        "displaySymbol": "EZ/BTC",
        "hasWM": true,
        "symbol": "BINANCE:EZBTC"
    },
    {
        "description": "Binance EZETH",
        "displaySymbol": "EZ/ETH",
        "hasWM": true,
        "symbol": "BINANCE:EZETH"
    },
    {
        "description": "Binance BAKEUSDT",
        "displaySymbol": "BAKE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BAKEUSDT"
    },
    {
        "description": "Binance BURGERBUSD",
        "displaySymbol": "BURGER/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BURGERBUSD"
    },
    {
        "description": "Binance BURGERUSDT",
        "displaySymbol": "BURGER/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BURGERUSDT"
    },
    {
        "description": "Binance SLPBUSD",
        "displaySymbol": "SLP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SLPBUSD"
    },
    {
        "description": "Binance SLPUSDT",
        "displaySymbol": "SLP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SLPUSDT"
    },
    {
        "description": "Binance TRXAUD",
        "displaySymbol": "TRX/AUD",
        "hasWM": true,
        "symbol": "BINANCE:TRXAUD"
    },
    {
        "description": "Binance TRXEUR",
        "displaySymbol": "TRX/EUR",
        "hasWM": true,
        "symbol": "BINANCE:TRXEUR"
    },
    {
        "description": "Binance VETTRY",
        "displaySymbol": "VET/TRY",
        "hasWM": true,
        "symbol": "BINANCE:VETTRY"
    },
    {
        "description": "Binance SHIBUSDT",
        "displaySymbol": "SHIB/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SHIBUSDT"
    },
    {
        "description": "Binance SHIBBUSD",
        "displaySymbol": "SHIB/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SHIBBUSD"
    },
    {
        "description": "Binance ICPBTC",
        "displaySymbol": "ICP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ICPBTC"
    },
    {
        "description": "Binance ICPBNB",
        "displaySymbol": "ICP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ICPBNB"
    },
    {
        "description": "Binance ICPBUSD",
        "displaySymbol": "ICP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ICPBUSD"
    },
    {
        "description": "Binance ICPUSDT",
        "displaySymbol": "ICP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ICPUSDT"
    },
    {
        "description": "Binance BTCGYEN",
        "displaySymbol": "BTC/GYEN",
        "hasWM": true,
        "symbol": "BINANCE:BTCGYEN"
    },
    {
        "description": "Binance USDTGYEN",
        "displaySymbol": "USDT/GYEN",
        "hasWM": true,
        "symbol": "BINANCE:USDTGYEN"
    },
    {
        "description": "Binance SHIBEUR",
        "displaySymbol": "SHIB/EUR",
        "hasWM": true,
        "symbol": "BINANCE:SHIBEUR"
    },
    {
        "description": "Binance SHIBRUB",
        "displaySymbol": "SHIB/RUB",
        "hasWM": true,
        "symbol": "BINANCE:SHIBRUB"
    },
    {
        "description": "Binance ETCEUR",
        "displaySymbol": "ETC/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ETCEUR"
    },
    {
        "description": "Binance ETCBRL",
        "displaySymbol": "ETC/BRL",
        "hasWM": true,
        "symbol": "BINANCE:ETCBRL"
    },
    {
        "description": "Binance DOGEBIDR",
        "displaySymbol": "DOGE/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:DOGEBIDR"
    },
    {
        "description": "Binance ARBTC",
        "displaySymbol": "AR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ARBTC"
    },
    {
        "description": "Binance ARBNB",
        "displaySymbol": "AR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ARBNB"
    },
    {
        "description": "Binance ARBUSD",
        "displaySymbol": "AR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ARBUSD"
    },
    {
        "description": "Binance ARUSDT",
        "displaySymbol": "AR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ARUSDT"
    },
    {
        "description": "Binance POLSBTC",
        "displaySymbol": "POLS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:POLSBTC"
    },
    {
        "description": "Binance POLSBNB",
        "displaySymbol": "POLS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:POLSBNB"
    },
    {
        "description": "Binance POLSBUSD",
        "displaySymbol": "POLS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:POLSBUSD"
    },
    {
        "description": "Binance POLSUSDT",
        "displaySymbol": "POLS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:POLSUSDT"
    },
    {
        "description": "Binance MDXBTC",
        "displaySymbol": "MDX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MDXBTC"
    },
    {
        "description": "Binance MDXBNB",
        "displaySymbol": "MDX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MDXBNB"
    },
    {
        "description": "Binance MDXBUSD",
        "displaySymbol": "MDX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MDXBUSD"
    },
    {
        "description": "Binance MDXUSDT",
        "displaySymbol": "MDX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MDXUSDT"
    },
    {
        "description": "Binance MASKBNB",
        "displaySymbol": "MASK/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MASKBNB"
    },
    {
        "description": "Binance MASKBUSD",
        "displaySymbol": "MASK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MASKBUSD"
    },
    {
        "description": "Binance MASKUSDT",
        "displaySymbol": "MASK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MASKUSDT"
    },
    {
        "description": "Binance LPTBTC",
        "displaySymbol": "LPT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LPTBTC"
    },
    {
        "description": "Binance LPTBNB",
        "displaySymbol": "LPT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LPTBNB"
    },
    {
        "description": "Binance LPTBUSD",
        "displaySymbol": "LPT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LPTBUSD"
    },
    {
        "description": "Binance LPTUSDT",
        "displaySymbol": "LPT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LPTUSDT"
    },
    {
        "description": "Binance ETHUAH",
        "displaySymbol": "ETH/UAH",
        "hasWM": true,
        "symbol": "BINANCE:ETHUAH"
    },
    {
        "description": "Binance MATICBRL",
        "displaySymbol": "MATIC/BRL",
        "hasWM": true,
        "symbol": "BINANCE:MATICBRL"
    },
    {
        "description": "Binance SOLEUR",
        "displaySymbol": "SOL/EUR",
        "hasWM": true,
        "symbol": "BINANCE:SOLEUR"
    },
    {
        "description": "Binance SHIBBRL",
        "displaySymbol": "SHIB/BRL",
        "hasWM": true,
        "symbol": "BINANCE:SHIBBRL"
    },
    {
        "description": "Binance AGIXBTC",
        "displaySymbol": "AGIX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AGIXBTC"
    },
    {
        "description": "Binance ICPEUR",
        "displaySymbol": "ICP/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ICPEUR"
    },
    {
        "description": "Binance MATICGBP",
        "displaySymbol": "MATIC/GBP",
        "hasWM": true,
        "symbol": "BINANCE:MATICGBP"
    },
    {
        "description": "Binance SHIBTRY",
        "displaySymbol": "SHIB/TRY",
        "hasWM": true,
        "symbol": "BINANCE:SHIBTRY"
    },
    {
        "description": "Binance MATICBIDR",
        "displaySymbol": "MATIC/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:MATICBIDR"
    },
    {
        "description": "Binance MATICRUB",
        "displaySymbol": "MATIC/RUB",
        "hasWM": true,
        "symbol": "BINANCE:MATICRUB"
    },
    {
        "description": "Binance NUBTC",
        "displaySymbol": "NU/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NUBTC"
    },
    {
        "description": "Binance NUBNB",
        "displaySymbol": "NU/BNB",
        "hasWM": true,
        "symbol": "BINANCE:NUBNB"
    },
    {
        "description": "Binance NUBUSD",
        "displaySymbol": "NU/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:NUBUSD"
    },
    {
        "description": "Binance NUUSDT",
        "displaySymbol": "NU/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NUUSDT"
    },
    {
        "description": "Binance XVGUSDT",
        "displaySymbol": "XVG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XVGUSDT"
    },
    {
        "description": "Binance RLCBUSD",
        "displaySymbol": "RLC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RLCBUSD"
    },
    {
        "description": "Binance CELRBUSD",
        "displaySymbol": "CELR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CELRBUSD"
    },
    {
        "description": "Binance ATMBUSD",
        "displaySymbol": "ATM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ATMBUSD"
    },
    {
        "description": "Binance ZENBUSD",
        "displaySymbol": "ZEN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ZENBUSD"
    },
    {
        "description": "Binance FTMBUSD",
        "displaySymbol": "FTM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FTMBUSD"
    },
    {
        "description": "Binance THETABUSD",
        "displaySymbol": "THETA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:THETABUSD"
    },
    {
        "description": "Binance WINBUSD",
        "displaySymbol": "WIN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:WINBUSD"
    },
    {
        "description": "Binance KAVABUSD",
        "displaySymbol": "KAVA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KAVABUSD"
    },
    {
        "description": "Binance XEMBUSD",
        "displaySymbol": "XEM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XEMBUSD"
    },
    {
        "description": "Binance ATABTC",
        "displaySymbol": "ATA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ATABTC"
    },
    {
        "description": "Binance ATABNB",
        "displaySymbol": "ATA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ATABNB"
    },
    {
        "description": "Binance ATABUSD",
        "displaySymbol": "ATA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ATABUSD"
    },
    {
        "description": "Binance ATAUSDT",
        "displaySymbol": "ATA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ATAUSDT"
    },
    {
        "description": "Binance GTCBTC",
        "displaySymbol": "GTC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GTCBTC"
    },
    {
        "description": "Binance GTCBNB",
        "displaySymbol": "GTC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GTCBNB"
    },
    {
        "description": "Binance GTCBUSD",
        "displaySymbol": "GTC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GTCBUSD"
    },
    {
        "description": "Binance GTCUSDT",
        "displaySymbol": "GTC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GTCUSDT"
    },
    {
        "description": "Binance TORNBTC",
        "displaySymbol": "TORN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TORNBTC"
    },
    {
        "description": "Binance TORNBNB",
        "displaySymbol": "TORN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TORNBNB"
    },
    {
        "description": "Binance TORNBUSD",
        "displaySymbol": "TORN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TORNBUSD"
    },
    {
        "description": "Binance TORNUSDT",
        "displaySymbol": "TORN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TORNUSDT"
    },
    {
        "description": "Binance MATICTRY",
        "displaySymbol": "MATIC/TRY",
        "hasWM": true,
        "symbol": "BINANCE:MATICTRY"
    },
    {
        "description": "Binance ETCGBP",
        "displaySymbol": "ETC/GBP",
        "hasWM": true,
        "symbol": "BINANCE:ETCGBP"
    },
    {
        "description": "Binance SOLGBP",
        "displaySymbol": "SOL/GBP",
        "hasWM": true,
        "symbol": "BINANCE:SOLGBP"
    },
    {
        "description": "Binance BAKEBTC",
        "displaySymbol": "BAKE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BAKEBTC"
    },
    {
        "description": "Binance COTIBUSD",
        "displaySymbol": "COTI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:COTIBUSD"
    },
    {
        "description": "Binance KEEPBTC",
        "displaySymbol": "KEEP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KEEPBTC"
    },
    {
        "description": "Binance KEEPBNB",
        "displaySymbol": "KEEP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:KEEPBNB"
    },
    {
        "description": "Binance KEEPBUSD",
        "displaySymbol": "KEEP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KEEPBUSD"
    },
    {
        "description": "Binance KEEPUSDT",
        "displaySymbol": "KEEP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KEEPUSDT"
    },
    {
        "description": "Binance SOLTRY",
        "displaySymbol": "SOL/TRY",
        "hasWM": true,
        "symbol": "BINANCE:SOLTRY"
    },
    {
        "description": "Binance RUNEGBP",
        "displaySymbol": "RUNE/GBP",
        "hasWM": true,
        "symbol": "BINANCE:RUNEGBP"
    },
    {
        "description": "Binance SOLBRL",
        "displaySymbol": "SOL/BRL",
        "hasWM": true,
        "symbol": "BINANCE:SOLBRL"
    },
    {
        "description": "Binance SCBUSD",
        "displaySymbol": "SC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SCBUSD"
    },
    {
        "description": "Binance CHRBUSD",
        "displaySymbol": "CHR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CHRBUSD"
    },
    {
        "description": "Binance STMXBUSD",
        "displaySymbol": "STMX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:STMXBUSD"
    },
    {
        "description": "Binance HNTBUSD",
        "displaySymbol": "HNT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:HNTBUSD"
    },
    {
        "description": "Binance FTTBUSD",
        "displaySymbol": "FTT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FTTBUSD"
    },
    {
        "description": "Binance DOCKBUSD",
        "displaySymbol": "DOCK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DOCKBUSD"
    },
    {
        "description": "Binance ADABIDR",
        "displaySymbol": "ADA/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:ADABIDR"
    },
    {
        "description": "Binance ERNBNB",
        "displaySymbol": "ERN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ERNBNB"
    },
    {
        "description": "Binance ERNBUSD",
        "displaySymbol": "ERN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ERNBUSD"
    },
    {
        "description": "Binance ERNUSDT",
        "displaySymbol": "ERN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ERNUSDT"
    },
    {
        "description": "Binance KLAYBTC",
        "displaySymbol": "KLAY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KLAYBTC"
    },
    {
        "description": "Binance KLAYBNB",
        "displaySymbol": "KLAY/BNB",
        "hasWM": true,
        "symbol": "BINANCE:KLAYBNB"
    },
    {
        "description": "Binance KLAYBUSD",
        "displaySymbol": "KLAY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KLAYBUSD"
    },
    {
        "description": "Binance KLAYUSDT",
        "displaySymbol": "KLAY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KLAYUSDT"
    },
    {
        "description": "Binance RUNEEUR",
        "displaySymbol": "RUNE/EUR",
        "hasWM": true,
        "symbol": "BINANCE:RUNEEUR"
    },
    {
        "description": "Binance MATICAUD",
        "displaySymbol": "MATIC/AUD",
        "hasWM": true,
        "symbol": "BINANCE:MATICAUD"
    },
    {
        "description": "Binance DOTRUB",
        "displaySymbol": "DOT/RUB",
        "hasWM": true,
        "symbol": "BINANCE:DOTRUB"
    },
    {
        "description": "Binance UTKBUSD",
        "displaySymbol": "UTK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:UTKBUSD"
    },
    {
        "description": "Binance IOTXBUSD",
        "displaySymbol": "IOTX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:IOTXBUSD"
    },
    {
        "description": "Binance PHAUSDT",
        "displaySymbol": "PHA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PHAUSDT"
    },
    {
        "description": "Binance SOLRUB",
        "displaySymbol": "SOL/RUB",
        "hasWM": true,
        "symbol": "BINANCE:SOLRUB"
    },
    {
        "description": "Binance RUNEAUD",
        "displaySymbol": "RUNE/AUD",
        "hasWM": true,
        "symbol": "BINANCE:RUNEAUD"
    },
    {
        "description": "Binance BUSDUAH",
        "displaySymbol": "BUSD/UAH",
        "hasWM": true,
        "symbol": "BINANCE:BUSDUAH"
    },
    {
        "description": "Binance BONDBTC",
        "displaySymbol": "BOND/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BONDBTC"
    },
    {
        "description": "Binance BONDBNB",
        "displaySymbol": "BOND/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BONDBNB"
    },
    {
        "description": "Binance BONDBUSD",
        "displaySymbol": "BOND/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BONDBUSD"
    },
    {
        "description": "Binance BONDUSDT",
        "displaySymbol": "BOND/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BONDUSDT"
    },
    {
        "description": "Binance MLNBTC",
        "displaySymbol": "MLN/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MLNBTC"
    },
    {
        "description": "Binance MLNBNB",
        "displaySymbol": "MLN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MLNBNB"
    },
    {
        "description": "Binance MLNBUSD",
        "displaySymbol": "MLN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MLNBUSD"
    },
    {
        "description": "Binance MLNUSDT",
        "displaySymbol": "MLN/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MLNUSDT"
    },
    {
        "description": "Binance GRTTRY",
        "displaySymbol": "GRT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:GRTTRY"
    },
    {
        "description": "Binance CAKEBRL",
        "displaySymbol": "CAKE/BRL",
        "hasWM": true,
        "symbol": "BINANCE:CAKEBRL"
    },
    {
        "description": "Binance ICPRUB",
        "displaySymbol": "ICP/RUB",
        "hasWM": true,
        "symbol": "BINANCE:ICPRUB"
    },
    {
        "description": "Binance DOTAUD",
        "displaySymbol": "DOT/AUD",
        "hasWM": true,
        "symbol": "BINANCE:DOTAUD"
    },
    {
        "description": "Binance AAVEBRL",
        "displaySymbol": "AAVE/BRL",
        "hasWM": true,
        "symbol": "BINANCE:AAVEBRL"
    },
    {
        "description": "Binance EOSAUD",
        "displaySymbol": "EOS/AUD",
        "hasWM": true,
        "symbol": "BINANCE:EOSAUD"
    },
    {
        "description": "Binance DEXEUSDT",
        "displaySymbol": "DEXE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DEXEUSDT"
    },
    {
        "description": "Binance LTOBUSD",
        "displaySymbol": "LTO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LTOBUSD"
    },
    {
        "description": "Binance ADXBUSD",
        "displaySymbol": "ADX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ADXBUSD"
    },
    {
        "description": "Binance QUICKBTC",
        "displaySymbol": "QUICK/BTC",
        "hasWM": true,
        "symbol": "BINANCE:QUICKBTC"
    },
    {
        "description": "Binance QUICKBNB",
        "displaySymbol": "QUICK/BNB",
        "hasWM": true,
        "symbol": "BINANCE:QUICKBNB"
    },
    {
        "description": "Binance QUICKBUSD",
        "displaySymbol": "QUICK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:QUICKBUSD"
    },
    {
        "description": "Binance C98USDT",
        "displaySymbol": "C98/USDT",
        "hasWM": true,
        "symbol": "BINANCE:C98USDT"
    },
    {
        "description": "Binance C98BUSD",
        "displaySymbol": "C98/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:C98BUSD"
    },
    {
        "description": "Binance C98BNB",
        "displaySymbol": "C98/BNB",
        "hasWM": true,
        "symbol": "BINANCE:C98BNB"
    },
    {
        "description": "Binance C98BTC",
        "displaySymbol": "C98/BTC",
        "hasWM": true,
        "symbol": "BINANCE:C98BTC"
    },
    {
        "description": "Binance CLVBTC",
        "displaySymbol": "CLV/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CLVBTC"
    },
    {
        "description": "Binance CLVBNB",
        "displaySymbol": "CLV/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CLVBNB"
    },
    {
        "description": "Binance CLVBUSD",
        "displaySymbol": "CLV/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CLVBUSD"
    },
    {
        "description": "Binance CLVUSDT",
        "displaySymbol": "CLV/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CLVUSDT"
    },
    {
        "description": "Binance QNTBTC",
        "displaySymbol": "QNT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:QNTBTC"
    },
    {
        "description": "Binance QNTBNB",
        "displaySymbol": "QNT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:QNTBNB"
    },
    {
        "description": "Binance QNTBUSD",
        "displaySymbol": "QNT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:QNTBUSD"
    },
    {
        "description": "Binance QNTUSDT",
        "displaySymbol": "QNT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:QNTUSDT"
    },
    {
        "description": "Binance FLOWBTC",
        "displaySymbol": "FLOW/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FLOWBTC"
    },
    {
        "description": "Binance FLOWBNB",
        "displaySymbol": "FLOW/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FLOWBNB"
    },
    {
        "description": "Binance FLOWBUSD",
        "displaySymbol": "FLOW/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FLOWBUSD"
    },
    {
        "description": "Binance FLOWUSDT",
        "displaySymbol": "FLOW/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FLOWUSDT"
    },
    {
        "description": "Binance XECBUSD",
        "displaySymbol": "XEC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XECBUSD"
    },
    {
        "description": "Binance AXSBRL",
        "displaySymbol": "AXS/BRL",
        "hasWM": true,
        "symbol": "BINANCE:AXSBRL"
    },
    {
        "description": "Binance AXSAUD",
        "displaySymbol": "AXS/AUD",
        "hasWM": true,
        "symbol": "BINANCE:AXSAUD"
    },
    {
        "description": "Binance TVKUSDT",
        "displaySymbol": "TVK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TVKUSDT"
    },
    {
        "description": "Binance MINABTC",
        "displaySymbol": "MINA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MINABTC"
    },
    {
        "description": "Binance MINABNB",
        "displaySymbol": "MINA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MINABNB"
    },
    {
        "description": "Binance MINABUSD",
        "displaySymbol": "MINA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MINABUSD"
    },
    {
        "description": "Binance MINAUSDT",
        "displaySymbol": "MINA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MINAUSDT"
    },
    {
        "description": "Binance RAYBNB",
        "displaySymbol": "RAY/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RAYBNB"
    },
    {
        "description": "Binance RAYBUSD",
        "displaySymbol": "RAY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RAYBUSD"
    },
    {
        "description": "Binance RAYUSDT",
        "displaySymbol": "RAY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RAYUSDT"
    },
    {
        "description": "Binance FARMBTC",
        "displaySymbol": "FARM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FARMBTC"
    },
    {
        "description": "Binance FARMBNB",
        "displaySymbol": "FARM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FARMBNB"
    },
    {
        "description": "Binance FARMBUSD",
        "displaySymbol": "FARM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FARMBUSD"
    },
    {
        "description": "Binance FARMUSDT",
        "displaySymbol": "FARM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FARMUSDT"
    },
    {
        "description": "Binance ALPACABTC",
        "displaySymbol": "ALPACA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ALPACABTC"
    },
    {
        "description": "Binance ALPACABNB",
        "displaySymbol": "ALPACA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ALPACABNB"
    },
    {
        "description": "Binance ALPACABUSD",
        "displaySymbol": "ALPACA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ALPACABUSD"
    },
    {
        "description": "Binance ALPACAUSDT",
        "displaySymbol": "ALPACA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ALPACAUSDT"
    },
    {
        "description": "Binance TLMTRY",
        "displaySymbol": "TLM/TRY",
        "hasWM": true,
        "symbol": "BINANCE:TLMTRY"
    },
    {
        "description": "Binance QUICKUSDT",
        "displaySymbol": "QUICK/USDT",
        "hasWM": true,
        "symbol": "BINANCE:QUICKUSDT"
    },
    {
        "description": "Binance ORNBUSD",
        "displaySymbol": "ORN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ORNBUSD"
    },
    {
        "description": "Binance MBOXBTC",
        "displaySymbol": "MBOX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MBOXBTC"
    },
    {
        "description": "Binance MBOXBNB",
        "displaySymbol": "MBOX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MBOXBNB"
    },
    {
        "description": "Binance MBOXBUSD",
        "displaySymbol": "MBOX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MBOXBUSD"
    },
    {
        "description": "Binance MBOXUSDT",
        "displaySymbol": "MBOX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MBOXUSDT"
    },
    {
        "description": "Binance VGXBTC",
        "displaySymbol": "VGX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VGXBTC"
    },
    {
        "description": "Binance VGXETH",
        "displaySymbol": "VGX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:VGXETH"
    },
    {
        "description": "Binance FORUSDT",
        "displaySymbol": "FOR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FORUSDT"
    },
    {
        "description": "Binance REQUSDT",
        "displaySymbol": "REQ/USDT",
        "hasWM": true,
        "symbol": "BINANCE:REQUSDT"
    },
    {
        "description": "Binance GHSTUSDT",
        "displaySymbol": "GHST/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GHSTUSDT"
    },
    {
        "description": "Binance TRURUB",
        "displaySymbol": "TRU/RUB",
        "hasWM": true,
        "symbol": "BINANCE:TRURUB"
    },
    {
        "description": "Binance FISBRL",
        "displaySymbol": "FIS/BRL",
        "hasWM": true,
        "symbol": "BINANCE:FISBRL"
    },
    {
        "description": "Binance WAXPUSDT",
        "displaySymbol": "WAXP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WAXPUSDT"
    },
    {
        "description": "Binance WAXPBUSD",
        "displaySymbol": "WAXP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:WAXPBUSD"
    },
    {
        "description": "Binance WAXPBNB",
        "displaySymbol": "WAXP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WAXPBNB"
    },
    {
        "description": "Binance WAXPBTC",
        "displaySymbol": "WAXP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WAXPBTC"
    },
    {
        "description": "Binance TRIBEBTC",
        "displaySymbol": "TRIBE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:TRIBEBTC"
    },
    {
        "description": "Binance TRIBEBNB",
        "displaySymbol": "TRIBE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TRIBEBNB"
    },
    {
        "description": "Binance TRIBEBUSD",
        "displaySymbol": "TRIBE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TRIBEBUSD"
    },
    {
        "description": "Binance TRIBEUSDT",
        "displaySymbol": "TRIBE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TRIBEUSDT"
    },
    {
        "description": "Binance GNOUSDT",
        "displaySymbol": "GNO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GNOUSDT"
    },
    {
        "description": "Binance GNOBUSD",
        "displaySymbol": "GNO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GNOBUSD"
    },
    {
        "description": "Binance GNOBNB",
        "displaySymbol": "GNO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GNOBNB"
    },
    {
        "description": "Binance GNOBTC",
        "displaySymbol": "GNO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GNOBTC"
    },
    {
        "description": "Binance ARPATRY",
        "displaySymbol": "ARPA/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ARPATRY"
    },
    {
        "description": "Binance PROMBTC",
        "displaySymbol": "PROM/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PROMBTC"
    },
    {
        "description": "Binance MTLBUSD",
        "displaySymbol": "MTL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MTLBUSD"
    },
    {
        "description": "Binance OGNBUSD",
        "displaySymbol": "OGN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OGNBUSD"
    },
    {
        "description": "Binance XECUSDT",
        "displaySymbol": "XEC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XECUSDT"
    },
    {
        "description": "Binance C98BRL",
        "displaySymbol": "C98/BRL",
        "hasWM": true,
        "symbol": "BINANCE:C98BRL"
    },
    {
        "description": "Binance SOLAUD",
        "displaySymbol": "SOL/AUD",
        "hasWM": true,
        "symbol": "BINANCE:SOLAUD"
    },
    {
        "description": "Binance XRPBIDR",
        "displaySymbol": "XRP/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:XRPBIDR"
    },
    {
        "description": "Binance POLYBUSD",
        "displaySymbol": "POLY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:POLYBUSD"
    },
    {
        "description": "Binance ELFUSDT",
        "displaySymbol": "ELF/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ELFUSDT"
    },
    {
        "description": "Binance DYDXUSDT",
        "displaySymbol": "DYDX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DYDXUSDT"
    },
    {
        "description": "Binance DYDXBUSD",
        "displaySymbol": "DYDX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DYDXBUSD"
    },
    {
        "description": "Binance DYDXBNB",
        "displaySymbol": "DYDX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DYDXBNB"
    },
    {
        "description": "Binance DYDXBTC",
        "displaySymbol": "DYDX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DYDXBTC"
    },
    {
        "description": "Binance ELFBUSD",
        "displaySymbol": "ELF/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ELFBUSD"
    },
    {
        "description": "Binance POLYUSDT",
        "displaySymbol": "POLY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:POLYUSDT"
    },
    {
        "description": "Binance IDEXUSDT",
        "displaySymbol": "IDEX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:IDEXUSDT"
    },
    {
        "description": "Binance VIDTUSDT",
        "displaySymbol": "VIDT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:VIDTUSDT"
    },
    {
        "description": "Binance SOLBIDR",
        "displaySymbol": "SOL/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:SOLBIDR"
    },
    {
        "description": "Binance AXSBIDR",
        "displaySymbol": "AXS/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:AXSBIDR"
    },
    {
        "description": "Binance BTCUSDP",
        "displaySymbol": "BTC/USDP",
        "hasWM": true,
        "symbol": "BINANCE:BTCUSDP"
    },
    {
        "description": "Binance ETHUSDP",
        "displaySymbol": "ETH/USDP",
        "hasWM": true,
        "symbol": "BINANCE:ETHUSDP"
    },
    {
        "description": "Binance BNBUSDP",
        "displaySymbol": "BNB/USDP",
        "hasWM": true,
        "symbol": "BINANCE:BNBUSDP"
    },
    {
        "description": "Binance USDPBUSD",
        "displaySymbol": "USDP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:USDPBUSD"
    },
    {
        "description": "Binance USDPUSDT",
        "displaySymbol": "USDP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:USDPUSDT"
    },
    {
        "description": "Binance GALAUSDT",
        "displaySymbol": "GALA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GALAUSDT"
    },
    {
        "description": "Binance GALABUSD",
        "displaySymbol": "GALA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GALABUSD"
    },
    {
        "description": "Binance GALABNB",
        "displaySymbol": "GALA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GALABNB"
    },
    {
        "description": "Binance GALABTC",
        "displaySymbol": "GALA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GALABTC"
    },
    {
        "description": "Binance FTMBIDR",
        "displaySymbol": "FTM/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:FTMBIDR"
    },
    {
        "description": "Binance ALGOBIDR",
        "displaySymbol": "ALGO/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:ALGOBIDR"
    },
    {
        "description": "Binance CAKEAUD",
        "displaySymbol": "CAKE/AUD",
        "hasWM": true,
        "symbol": "BINANCE:CAKEAUD"
    },
    {
        "description": "Binance KSMAUD",
        "displaySymbol": "KSM/AUD",
        "hasWM": true,
        "symbol": "BINANCE:KSMAUD"
    },
    {
        "description": "Binance WAVESRUB",
        "displaySymbol": "WAVES/RUB",
        "hasWM": true,
        "symbol": "BINANCE:WAVESRUB"
    },
    {
        "description": "Binance SUNBUSD",
        "displaySymbol": "SUN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SUNBUSD"
    },
    {
        "description": "Binance ILVUSDT",
        "displaySymbol": "ILV/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ILVUSDT"
    },
    {
        "description": "Binance ILVBUSD",
        "displaySymbol": "ILV/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ILVBUSD"
    },
    {
        "description": "Binance ILVBNB",
        "displaySymbol": "ILV/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ILVBNB"
    },
    {
        "description": "Binance ILVBTC",
        "displaySymbol": "ILV/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ILVBTC"
    },
    {
        "description": "Binance RENBUSD",
        "displaySymbol": "REN/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RENBUSD"
    },
    {
        "description": "Binance YGGUSDT",
        "displaySymbol": "YGG/USDT",
        "hasWM": true,
        "symbol": "BINANCE:YGGUSDT"
    },
    {
        "description": "Binance YGGBUSD",
        "displaySymbol": "YGG/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:YGGBUSD"
    },
    {
        "description": "Binance YGGBNB",
        "displaySymbol": "YGG/BNB",
        "hasWM": true,
        "symbol": "BINANCE:YGGBNB"
    },
    {
        "description": "Binance YGGBTC",
        "displaySymbol": "YGG/BTC",
        "hasWM": true,
        "symbol": "BINANCE:YGGBTC"
    },
    {
        "description": "Binance STXBUSD",
        "displaySymbol": "STX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:STXBUSD"
    },
    {
        "description": "Binance SYSUSDT",
        "displaySymbol": "SYS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SYSUSDT"
    },
    {
        "description": "Binance DFUSDT",
        "displaySymbol": "DF/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DFUSDT"
    },
    {
        "description": "Binance SOLUSDC",
        "displaySymbol": "SOL/USDC",
        "hasWM": true,
        "symbol": "BINANCE:SOLUSDC"
    },
    {
        "description": "Binance ARPARUB",
        "displaySymbol": "ARPA/RUB",
        "hasWM": true,
        "symbol": "BINANCE:ARPARUB"
    },
    {
        "description": "Binance LTCUAH",
        "displaySymbol": "LTC/UAH",
        "hasWM": true,
        "symbol": "BINANCE:LTCUAH"
    },
    {
        "description": "Binance FETBUSD",
        "displaySymbol": "FET/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FETBUSD"
    },
    {
        "description": "Binance ARPABUSD",
        "displaySymbol": "ARPA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ARPABUSD"
    },
    {
        "description": "Binance LSKBUSD",
        "displaySymbol": "LSK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LSKBUSD"
    },
    {
        "description": "Binance AVAXBIDR",
        "displaySymbol": "AVAX/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:AVAXBIDR"
    },
    {
        "description": "Binance ALICEBIDR",
        "displaySymbol": "ALICE/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:ALICEBIDR"
    },
    {
        "description": "Binance FIDAUSDT",
        "displaySymbol": "FIDA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FIDAUSDT"
    },
    {
        "description": "Binance FIDABUSD",
        "displaySymbol": "FIDA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FIDABUSD"
    },
    {
        "description": "Binance FIDABNB",
        "displaySymbol": "FIDA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FIDABNB"
    },
    {
        "description": "Binance FIDABTC",
        "displaySymbol": "FIDA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FIDABTC"
    },
    {
        "description": "Binance DENTBUSD",
        "displaySymbol": "DENT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DENTBUSD"
    },
    {
        "description": "Binance FRONTUSDT",
        "displaySymbol": "FRONT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FRONTUSDT"
    },
    {
        "description": "Binance CVPUSDT",
        "displaySymbol": "CVP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CVPUSDT"
    },
    {
        "description": "Binance AGLDBTC",
        "displaySymbol": "AGLD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AGLDBTC"
    },
    {
        "description": "Binance AGLDBNB",
        "displaySymbol": "AGLD/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AGLDBNB"
    },
    {
        "description": "Binance AGLDBUSD",
        "displaySymbol": "AGLD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AGLDBUSD"
    },
    {
        "description": "Binance AGLDUSDT",
        "displaySymbol": "AGLD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AGLDUSDT"
    },
    {
        "description": "Binance RADBTC",
        "displaySymbol": "RAD/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RADBTC"
    },
    {
        "description": "Binance RADBNB",
        "displaySymbol": "RAD/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RADBNB"
    },
    {
        "description": "Binance RADBUSD",
        "displaySymbol": "RAD/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RADBUSD"
    },
    {
        "description": "Binance RADUSDT",
        "displaySymbol": "RAD/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RADUSDT"
    },
    {
        "description": "Binance UNIAUD",
        "displaySymbol": "UNI/AUD",
        "hasWM": true,
        "symbol": "BINANCE:UNIAUD"
    },
    {
        "description": "Binance HIVEBUSD",
        "displaySymbol": "HIVE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:HIVEBUSD"
    },
    {
        "description": "Binance STPTBUSD",
        "displaySymbol": "STPT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:STPTBUSD"
    },
    {
        "description": "Binance BETABTC",
        "displaySymbol": "BETA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BETABTC"
    },
    {
        "description": "Binance BETABNB",
        "displaySymbol": "BETA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BETABNB"
    },
    {
        "description": "Binance BETABUSD",
        "displaySymbol": "BETA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BETABUSD"
    },
    {
        "description": "Binance BETAUSDT",
        "displaySymbol": "BETA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BETAUSDT"
    },
    {
        "description": "Binance SHIBAUD",
        "displaySymbol": "SHIB/AUD",
        "hasWM": true,
        "symbol": "BINANCE:SHIBAUD"
    },
    {
        "description": "Binance RAREBTC",
        "displaySymbol": "RARE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RAREBTC"
    },
    {
        "description": "Binance RAREBNB",
        "displaySymbol": "RARE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RAREBNB"
    },
    {
        "description": "Binance RAREBUSD",
        "displaySymbol": "RARE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RAREBUSD"
    },
    {
        "description": "Binance RAREUSDT",
        "displaySymbol": "RARE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RAREUSDT"
    },
    {
        "description": "Binance AVAXBRL",
        "displaySymbol": "AVAX/BRL",
        "hasWM": true,
        "symbol": "BINANCE:AVAXBRL"
    },
    {
        "description": "Binance AVAXAUD",
        "displaySymbol": "AVAX/AUD",
        "hasWM": true,
        "symbol": "BINANCE:AVAXAUD"
    },
    {
        "description": "Binance LUNAAUD",
        "displaySymbol": "LUNA/AUD",
        "hasWM": true,
        "symbol": "BINANCE:LUNAAUD"
    },
    {
        "description": "Binance TROYBUSD",
        "displaySymbol": "TROY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TROYBUSD"
    },
    {
        "description": "Binance AXSETH",
        "displaySymbol": "AXS/ETH",
        "hasWM": true,
        "symbol": "BINANCE:AXSETH"
    },
    {
        "description": "Binance FTMETH",
        "displaySymbol": "FTM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:FTMETH"
    },
    {
        "description": "Binance SOLETH",
        "displaySymbol": "SOL/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SOLETH"
    },
    {
        "description": "Binance SSVBTC",
        "displaySymbol": "SSV/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SSVBTC"
    },
    {
        "description": "Binance SSVETH",
        "displaySymbol": "SSV/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SSVETH"
    },
    {
        "description": "Binance LAZIOTRY",
        "displaySymbol": "LAZIO/TRY",
        "hasWM": true,
        "symbol": "BINANCE:LAZIOTRY"
    },
    {
        "description": "Binance LAZIOEUR",
        "displaySymbol": "LAZIO/EUR",
        "hasWM": true,
        "symbol": "BINANCE:LAZIOEUR"
    },
    {
        "description": "Binance LAZIOBTC",
        "displaySymbol": "LAZIO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LAZIOBTC"
    },
    {
        "description": "Binance LAZIOUSDT",
        "displaySymbol": "LAZIO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LAZIOUSDT"
    },
    {
        "description": "Binance CHESSBTC",
        "displaySymbol": "CHESS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CHESSBTC"
    },
    {
        "description": "Binance CHESSBNB",
        "displaySymbol": "CHESS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CHESSBNB"
    },
    {
        "description": "Binance CHESSBUSD",
        "displaySymbol": "CHESS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CHESSBUSD"
    },
    {
        "description": "Binance CHESSUSDT",
        "displaySymbol": "CHESS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CHESSUSDT"
    },
    {
        "description": "Binance FTMAUD",
        "displaySymbol": "FTM/AUD",
        "hasWM": true,
        "symbol": "BINANCE:FTMAUD"
    },
    {
        "description": "Binance FTMBRL",
        "displaySymbol": "FTM/BRL",
        "hasWM": true,
        "symbol": "BINANCE:FTMBRL"
    },
    {
        "description": "Binance SCRTBUSD",
        "displaySymbol": "SCRT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SCRTBUSD"
    },
    {
        "description": "Binance ADXUSDT",
        "displaySymbol": "ADX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ADXUSDT"
    },
    {
        "description": "Binance AUCTIONUSDT",
        "displaySymbol": "AUCTION/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AUCTIONUSDT"
    },
    {
        "description": "Binance CELOBUSD",
        "displaySymbol": "CELO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CELOBUSD"
    },
    {
        "description": "Binance FTMRUB",
        "displaySymbol": "FTM/RUB",
        "hasWM": true,
        "symbol": "BINANCE:FTMRUB"
    },
    {
        "description": "Binance NUAUD",
        "displaySymbol": "NU/AUD",
        "hasWM": true,
        "symbol": "BINANCE:NUAUD"
    },
    {
        "description": "Binance NURUB",
        "displaySymbol": "NU/RUB",
        "hasWM": true,
        "symbol": "BINANCE:NURUB"
    },
    {
        "description": "Binance REEFTRY",
        "displaySymbol": "REEF/TRY",
        "hasWM": true,
        "symbol": "BINANCE:REEFTRY"
    },
    {
        "description": "Binance REEFBIDR",
        "displaySymbol": "REEF/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:REEFBIDR"
    },
    {
        "description": "Binance SHIBDOGE",
        "displaySymbol": "SHIB/DOGE",
        "hasWM": true,
        "symbol": "BINANCE:SHIBDOGE"
    },
    {
        "description": "Binance DARUSDT",
        "displaySymbol": "DAR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:DARUSDT"
    },
    {
        "description": "Binance DARBUSD",
        "displaySymbol": "DAR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DARBUSD"
    },
    {
        "description": "Binance DARBNB",
        "displaySymbol": "DAR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:DARBNB"
    },
    {
        "description": "Binance DARBTC",
        "displaySymbol": "DAR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:DARBTC"
    },
    {
        "description": "Binance BNXBTC",
        "displaySymbol": "BNX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BNXBTC"
    },
    {
        "description": "Binance BNXBNB",
        "displaySymbol": "BNX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BNXBNB"
    },
    {
        "description": "Binance BNXBUSD",
        "displaySymbol": "BNX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BNXBUSD"
    },
    {
        "description": "Binance BNXUSDT",
        "displaySymbol": "BNX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BNXUSDT"
    },
    {
        "description": "Binance RGTUSDT",
        "displaySymbol": "RGT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RGTUSDT"
    },
    {
        "description": "Binance RGTBTC",
        "displaySymbol": "RGT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RGTBTC"
    },
    {
        "description": "Binance RGTBUSD",
        "displaySymbol": "RGT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RGTBUSD"
    },
    {
        "description": "Binance RGTBNB",
        "displaySymbol": "RGT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:RGTBNB"
    },
    {
        "description": "Binance LAZIOBUSD",
        "displaySymbol": "LAZIO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LAZIOBUSD"
    },
    {
        "description": "Binance OXTBUSD",
        "displaySymbol": "OXT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OXTBUSD"
    },
    {
        "description": "Binance MANATRY",
        "displaySymbol": "MANA/TRY",
        "hasWM": true,
        "symbol": "BINANCE:MANATRY"
    },
    {
        "description": "Binance ALGORUB",
        "displaySymbol": "ALGO/RUB",
        "hasWM": true,
        "symbol": "BINANCE:ALGORUB"
    },
    {
        "description": "Binance SHIBUAH",
        "displaySymbol": "SHIB/UAH",
        "hasWM": true,
        "symbol": "BINANCE:SHIBUAH"
    },
    {
        "description": "Binance LUNABIDR",
        "displaySymbol": "LUNA/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:LUNABIDR"
    },
    {
        "description": "Binance AUDUSDC",
        "displaySymbol": "AUD/USDC",
        "hasWM": true,
        "symbol": "BINANCE:AUDUSDC"
    },
    {
        "description": "Binance MOVRBTC",
        "displaySymbol": "MOVR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MOVRBTC"
    },
    {
        "description": "Binance MOVRBNB",
        "displaySymbol": "MOVR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MOVRBNB"
    },
    {
        "description": "Binance MOVRBUSD",
        "displaySymbol": "MOVR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MOVRBUSD"
    },
    {
        "description": "Binance MOVRUSDT",
        "displaySymbol": "MOVR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MOVRUSDT"
    },
    {
        "description": "Binance CITYBTC",
        "displaySymbol": "CITY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CITYBTC"
    },
    {
        "description": "Binance CITYBNB",
        "displaySymbol": "CITY/BNB",
        "hasWM": true,
        "symbol": "BINANCE:CITYBNB"
    },
    {
        "description": "Binance CITYBUSD",
        "displaySymbol": "CITY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CITYBUSD"
    },
    {
        "description": "Binance CITYUSDT",
        "displaySymbol": "CITY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CITYUSDT"
    },
    {
        "description": "Binance ENSBTC",
        "displaySymbol": "ENS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ENSBTC"
    },
    {
        "description": "Binance ENSBNB",
        "displaySymbol": "ENS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ENSBNB"
    },
    {
        "description": "Binance ENSBUSD",
        "displaySymbol": "ENS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ENSBUSD"
    },
    {
        "description": "Binance ENSUSDT",
        "displaySymbol": "ENS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ENSUSDT"
    },
    {
        "description": "Binance SANDETH",
        "displaySymbol": "SAND/ETH",
        "hasWM": true,
        "symbol": "BINANCE:SANDETH"
    },
    {
        "description": "Binance DOTETH",
        "displaySymbol": "DOT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DOTETH"
    },
    {
        "description": "Binance MATICETH",
        "displaySymbol": "MATIC/ETH",
        "hasWM": true,
        "symbol": "BINANCE:MATICETH"
    },
    {
        "description": "Binance ANKRBUSD",
        "displaySymbol": "ANKR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ANKRBUSD"
    },
    {
        "description": "Binance SANDTRY",
        "displaySymbol": "SAND/TRY",
        "hasWM": true,
        "symbol": "BINANCE:SANDTRY"
    },
    {
        "description": "Binance MANABRL",
        "displaySymbol": "MANA/BRL",
        "hasWM": true,
        "symbol": "BINANCE:MANABRL"
    },
    {
        "description": "Binance KP3RUSDT",
        "displaySymbol": "KP3R/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KP3RUSDT"
    },
    {
        "description": "Binance QIUSDT",
        "displaySymbol": "QI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:QIUSDT"
    },
    {
        "description": "Binance QIBUSD",
        "displaySymbol": "QI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:QIBUSD"
    },
    {
        "description": "Binance QIBNB",
        "displaySymbol": "QI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:QIBNB"
    },
    {
        "description": "Binance QIBTC",
        "displaySymbol": "QI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:QIBTC"
    },
    {
        "description": "Binance PORTOBTC",
        "displaySymbol": "PORTO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PORTOBTC"
    },
    {
        "description": "Binance PORTOUSDT",
        "displaySymbol": "PORTO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PORTOUSDT"
    },
    {
        "description": "Binance PORTOTRY",
        "displaySymbol": "PORTO/TRY",
        "hasWM": true,
        "symbol": "BINANCE:PORTOTRY"
    },
    {
        "description": "Binance PORTOEUR",
        "displaySymbol": "PORTO/EUR",
        "hasWM": true,
        "symbol": "BINANCE:PORTOEUR"
    },
    {
        "description": "Binance POWRUSDT",
        "displaySymbol": "POWR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:POWRUSDT"
    },
    {
        "description": "Binance POWRBUSD",
        "displaySymbol": "POWR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:POWRBUSD"
    },
    {
        "description": "Binance AVAXETH",
        "displaySymbol": "AVAX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:AVAXETH"
    },
    {
        "description": "Binance SLPTRY",
        "displaySymbol": "SLP/TRY",
        "hasWM": true,
        "symbol": "BINANCE:SLPTRY"
    },
    {
        "description": "Binance FISTRY",
        "displaySymbol": "FIS/TRY",
        "hasWM": true,
        "symbol": "BINANCE:FISTRY"
    },
    {
        "description": "Binance LRCTRY",
        "displaySymbol": "LRC/TRY",
        "hasWM": true,
        "symbol": "BINANCE:LRCTRY"
    },
    {
        "description": "Binance CHRETH",
        "displaySymbol": "CHR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CHRETH"
    },
    {
        "description": "Binance FISBIDR",
        "displaySymbol": "FIS/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:FISBIDR"
    },
    {
        "description": "Binance VGXUSDT",
        "displaySymbol": "VGX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:VGXUSDT"
    },
    {
        "description": "Binance GALAETH",
        "displaySymbol": "GALA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GALAETH"
    },
    {
        "description": "Binance JASMYUSDT",
        "displaySymbol": "JASMY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:JASMYUSDT"
    },
    {
        "description": "Binance JASMYBUSD",
        "displaySymbol": "JASMY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:JASMYBUSD"
    },
    {
        "description": "Binance JASMYBNB",
        "displaySymbol": "JASMY/BNB",
        "hasWM": true,
        "symbol": "BINANCE:JASMYBNB"
    },
    {
        "description": "Binance JASMYBTC",
        "displaySymbol": "JASMY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:JASMYBTC"
    },
    {
        "description": "Binance AMPBTC",
        "displaySymbol": "AMP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:AMPBTC"
    },
    {
        "description": "Binance AMPBNB",
        "displaySymbol": "AMP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:AMPBNB"
    },
    {
        "description": "Binance AMPBUSD",
        "displaySymbol": "AMP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AMPBUSD"
    },
    {
        "description": "Binance AMPUSDT",
        "displaySymbol": "AMP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:AMPUSDT"
    },
    {
        "description": "Binance PLABTC",
        "displaySymbol": "PLA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PLABTC"
    },
    {
        "description": "Binance PLABNB",
        "displaySymbol": "PLA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PLABNB"
    },
    {
        "description": "Binance PLABUSD",
        "displaySymbol": "PLA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PLABUSD"
    },
    {
        "description": "Binance PLAUSDT",
        "displaySymbol": "PLA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PLAUSDT"
    },
    {
        "description": "Binance PYRBTC",
        "displaySymbol": "PYR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PYRBTC"
    },
    {
        "description": "Binance PYRBUSD",
        "displaySymbol": "PYR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PYRBUSD"
    },
    {
        "description": "Binance PYRUSDT",
        "displaySymbol": "PYR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PYRUSDT"
    },
    {
        "description": "Binance RNDRBTC",
        "displaySymbol": "RNDR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:RNDRBTC"
    },
    {
        "description": "Binance RNDRUSDT",
        "displaySymbol": "RNDR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:RNDRUSDT"
    },
    {
        "description": "Binance RNDRBUSD",
        "displaySymbol": "RNDR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:RNDRBUSD"
    },
    {
        "description": "Binance ALCXBTC",
        "displaySymbol": "ALCX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ALCXBTC"
    },
    {
        "description": "Binance ALCXBUSD",
        "displaySymbol": "ALCX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ALCXBUSD"
    },
    {
        "description": "Binance ALCXUSDT",
        "displaySymbol": "ALCX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ALCXUSDT"
    },
    {
        "description": "Binance SANTOSBTC",
        "displaySymbol": "SANTOS/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SANTOSBTC"
    },
    {
        "description": "Binance SANTOSUSDT",
        "displaySymbol": "SANTOS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SANTOSUSDT"
    },
    {
        "description": "Binance SANTOSBRL",
        "displaySymbol": "SANTOS/BRL",
        "hasWM": true,
        "symbol": "BINANCE:SANTOSBRL"
    },
    {
        "description": "Binance SANTOSTRY",
        "displaySymbol": "SANTOS/TRY",
        "hasWM": true,
        "symbol": "BINANCE:SANTOSTRY"
    },
    {
        "description": "Binance MCBTC",
        "displaySymbol": "MC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MCBTC"
    },
    {
        "description": "Binance MCBUSD",
        "displaySymbol": "MC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MCBUSD"
    },
    {
        "description": "Binance MCUSDT",
        "displaySymbol": "MC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MCUSDT"
    },
    {
        "description": "Binance BELTRY",
        "displaySymbol": "BEL/TRY",
        "hasWM": true,
        "symbol": "BINANCE:BELTRY"
    },
    {
        "description": "Binance COCOSBUSD",
        "displaySymbol": "COCOS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:COCOSBUSD"
    },
    {
        "description": "Binance DENTTRY",
        "displaySymbol": "DENT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:DENTTRY"
    },
    {
        "description": "Binance ENJTRY",
        "displaySymbol": "ENJ/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ENJTRY"
    },
    {
        "description": "Binance NEORUB",
        "displaySymbol": "NEO/RUB",
        "hasWM": true,
        "symbol": "BINANCE:NEORUB"
    },
    {
        "description": "Binance SANDAUD",
        "displaySymbol": "SAND/AUD",
        "hasWM": true,
        "symbol": "BINANCE:SANDAUD"
    },
    {
        "description": "Binance SLPBIDR",
        "displaySymbol": "SLP/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:SLPBIDR"
    },
    {
        "description": "Binance ANYBTC",
        "displaySymbol": "ANY/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ANYBTC"
    },
    {
        "description": "Binance ANYBUSD",
        "displaySymbol": "ANY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ANYBUSD"
    },
    {
        "description": "Binance ANYUSDT",
        "displaySymbol": "ANY/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ANYUSDT"
    },
    {
        "description": "Binance BICOBTC",
        "displaySymbol": "BICO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:BICOBTC"
    },
    {
        "description": "Binance BICOBUSD",
        "displaySymbol": "BICO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BICOBUSD"
    },
    {
        "description": "Binance BICOUSDT",
        "displaySymbol": "BICO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BICOUSDT"
    },
    {
        "description": "Binance FLUXBTC",
        "displaySymbol": "FLUX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:FLUXBTC"
    },
    {
        "description": "Binance FLUXBUSD",
        "displaySymbol": "FLUX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:FLUXBUSD"
    },
    {
        "description": "Binance FLUXUSDT",
        "displaySymbol": "FLUX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FLUXUSDT"
    },
    {
        "description": "Binance ALICETRY",
        "displaySymbol": "ALICE/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ALICETRY"
    },
    {
        "description": "Binance FXSUSDT",
        "displaySymbol": "FXS/USDT",
        "hasWM": true,
        "symbol": "BINANCE:FXSUSDT"
    },
    {
        "description": "Binance GALABRL",
        "displaySymbol": "GALA/BRL",
        "hasWM": true,
        "symbol": "BINANCE:GALABRL"
    },
    {
        "description": "Binance GALATRY",
        "displaySymbol": "GALA/TRY",
        "hasWM": true,
        "symbol": "BINANCE:GALATRY"
    },
    {
        "description": "Binance LUNATRY",
        "displaySymbol": "LUNA/TRY",
        "hasWM": true,
        "symbol": "BINANCE:LUNATRY"
    },
    {
        "description": "Binance REQBUSD",
        "displaySymbol": "REQ/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:REQBUSD"
    },
    {
        "description": "Binance SANDBRL",
        "displaySymbol": "SAND/BRL",
        "hasWM": true,
        "symbol": "BINANCE:SANDBRL"
    },
    {
        "description": "Binance MANABIDR",
        "displaySymbol": "MANA/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:MANABIDR"
    },
    {
        "description": "Binance SANDBIDR",
        "displaySymbol": "SAND/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:SANDBIDR"
    },
    {
        "description": "Binance VOXELBTC",
        "displaySymbol": "VOXEL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:VOXELBTC"
    },
    {
        "description": "Binance VOXELBNB",
        "displaySymbol": "VOXEL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:VOXELBNB"
    },
    {
        "description": "Binance VOXELBUSD",
        "displaySymbol": "VOXEL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:VOXELBUSD"
    },
    {
        "description": "Binance VOXELUSDT",
        "displaySymbol": "VOXEL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:VOXELUSDT"
    },
    {
        "description": "Binance COSBUSD",
        "displaySymbol": "COS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:COSBUSD"
    },
    {
        "description": "Binance CTXCBUSD",
        "displaySymbol": "CTXC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CTXCBUSD"
    },
    {
        "description": "Binance FTMTRY",
        "displaySymbol": "FTM/TRY",
        "hasWM": true,
        "symbol": "BINANCE:FTMTRY"
    },
    {
        "description": "Binance MANABNB",
        "displaySymbol": "MANA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MANABNB"
    },
    {
        "description": "Binance MINATRY",
        "displaySymbol": "MINA/TRY",
        "hasWM": true,
        "symbol": "BINANCE:MINATRY"
    },
    {
        "description": "Binance XTZTRY",
        "displaySymbol": "XTZ/TRY",
        "hasWM": true,
        "symbol": "BINANCE:XTZTRY"
    },
    {
        "description": "Binance HIGHBTC",
        "displaySymbol": "HIGH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:HIGHBTC"
    },
    {
        "description": "Binance HIGHBUSD",
        "displaySymbol": "HIGH/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:HIGHBUSD"
    },
    {
        "description": "Binance HIGHUSDT",
        "displaySymbol": "HIGH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:HIGHUSDT"
    },
    {
        "description": "Binance CVXBTC",
        "displaySymbol": "CVX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:CVXBTC"
    },
    {
        "description": "Binance CVXBUSD",
        "displaySymbol": "CVX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CVXBUSD"
    },
    {
        "description": "Binance CVXUSDT",
        "displaySymbol": "CVX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:CVXUSDT"
    },
    {
        "description": "Binance PEOPLEBTC",
        "displaySymbol": "PEOPLE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:PEOPLEBTC"
    },
    {
        "description": "Binance PEOPLEBUSD",
        "displaySymbol": "PEOPLE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PEOPLEBUSD"
    },
    {
        "description": "Binance PEOPLEUSDT",
        "displaySymbol": "PEOPLE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:PEOPLEUSDT"
    },
    {
        "description": "Binance OOKIBUSD",
        "displaySymbol": "OOKI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OOKIBUSD"
    },
    {
        "description": "Binance OOKIUSDT",
        "displaySymbol": "OOKI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OOKIUSDT"
    },
    {
        "description": "Binance COCOSTRY",
        "displaySymbol": "COCOS/TRY",
        "hasWM": true,
        "symbol": "BINANCE:COCOSTRY"
    },
    {
        "description": "Binance GXSBNB",
        "displaySymbol": "GXS/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GXSBNB"
    },
    {
        "description": "Binance LINKBNB",
        "displaySymbol": "LINK/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LINKBNB"
    },
    {
        "description": "Binance LUNAETH",
        "displaySymbol": "LUNA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:LUNAETH"
    },
    {
        "description": "Binance MDTBUSD",
        "displaySymbol": "MDT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MDTBUSD"
    },
    {
        "description": "Binance NULSBUSD",
        "displaySymbol": "NULS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:NULSBUSD"
    },
    {
        "description": "Binance SPELLBTC",
        "displaySymbol": "SPELL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:SPELLBTC"
    },
    {
        "description": "Binance SPELLUSDT",
        "displaySymbol": "SPELL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SPELLUSDT"
    },
    {
        "description": "Binance SPELLBUSD",
        "displaySymbol": "SPELL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SPELLBUSD"
    },
    {
        "description": "Binance USTBTC",
        "displaySymbol": "UST/BTC",
        "hasWM": true,
        "symbol": "BINANCE:USTBTC"
    },
    {
        "description": "Binance USTBUSD",
        "displaySymbol": "UST/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:USTBUSD"
    },
    {
        "description": "Binance USTUSDT",
        "displaySymbol": "UST/USDT",
        "hasWM": true,
        "symbol": "BINANCE:USTUSDT"
    },
    {
        "description": "Binance JOEBTC",
        "displaySymbol": "JOE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:JOEBTC"
    },
    {
        "description": "Binance JOEBUSD",
        "displaySymbol": "JOE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:JOEBUSD"
    },
    {
        "description": "Binance JOEUSDT",
        "displaySymbol": "JOE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:JOEUSDT"
    },
    {
        "description": "Binance ATOMETH",
        "displaySymbol": "ATOM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ATOMETH"
    },
    {
        "description": "Binance DUSKBUSD",
        "displaySymbol": "DUSK/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DUSKBUSD"
    },
    {
        "description": "Binance EGLDETH",
        "displaySymbol": "EGLD/ETH",
        "hasWM": true,
        "symbol": "BINANCE:EGLDETH"
    },
    {
        "description": "Binance ICPETH",
        "displaySymbol": "ICP/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ICPETH"
    },
    {
        "description": "Binance LUNABRL",
        "displaySymbol": "LUNA/BRL",
        "hasWM": true,
        "symbol": "BINANCE:LUNABRL"
    },
    {
        "description": "Binance LUNAUST",
        "displaySymbol": "LUNA/UST",
        "hasWM": true,
        "symbol": "BINANCE:LUNAUST"
    },
    {
        "description": "Binance NEARETH",
        "displaySymbol": "NEAR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:NEARETH"
    },
    {
        "description": "Binance ROSEBNB",
        "displaySymbol": "ROSE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ROSEBNB"
    },
    {
        "description": "Binance VOXELETH",
        "displaySymbol": "VOXEL/ETH",
        "hasWM": true,
        "symbol": "BINANCE:VOXELETH"
    },
    {
        "description": "Binance ALICEBNB",
        "displaySymbol": "ALICE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ALICEBNB"
    },
    {
        "description": "Binance ATOMTRY",
        "displaySymbol": "ATOM/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ATOMTRY"
    },
    {
        "description": "Binance ETHUST",
        "displaySymbol": "ETH/UST",
        "hasWM": true,
        "symbol": "BINANCE:ETHUST"
    },
    {
        "description": "Binance GALAAUD",
        "displaySymbol": "GALA/AUD",
        "hasWM": true,
        "symbol": "BINANCE:GALAAUD"
    },
    {
        "description": "Binance LRCBNB",
        "displaySymbol": "LRC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LRCBNB"
    },
    {
        "description": "Binance ONEETH",
        "displaySymbol": "ONE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ONEETH"
    },
    {
        "description": "Binance OOKIBNB",
        "displaySymbol": "OOKI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:OOKIBNB"
    },
    {
        "description": "Binance ACHBTC",
        "displaySymbol": "ACH/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ACHBTC"
    },
    {
        "description": "Binance ACHBUSD",
        "displaySymbol": "ACH/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ACHBUSD"
    },
    {
        "description": "Binance ACHUSDT",
        "displaySymbol": "ACH/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ACHUSDT"
    },
    {
        "description": "Binance IMXBTC",
        "displaySymbol": "IMX/BTC",
        "hasWM": true,
        "symbol": "BINANCE:IMXBTC"
    },
    {
        "description": "Binance IMXBUSD",
        "displaySymbol": "IMX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:IMXBUSD"
    },
    {
        "description": "Binance IMXUSDT",
        "displaySymbol": "IMX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:IMXUSDT"
    },
    {
        "description": "Binance GLMRBTC",
        "displaySymbol": "GLMR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GLMRBTC"
    },
    {
        "description": "Binance GLMRBUSD",
        "displaySymbol": "GLMR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GLMRBUSD"
    },
    {
        "description": "Binance GLMRUSDT",
        "displaySymbol": "GLMR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GLMRUSDT"
    },
    {
        "description": "Binance ATOMBIDR",
        "displaySymbol": "ATOM/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:ATOMBIDR"
    },
    {
        "description": "Binance DYDXETH",
        "displaySymbol": "DYDX/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DYDXETH"
    },
    {
        "description": "Binance FARMETH",
        "displaySymbol": "FARM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:FARMETH"
    },
    {
        "description": "Binance FORBNB",
        "displaySymbol": "FOR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FORBNB"
    },
    {
        "description": "Binance ICPTRY",
        "displaySymbol": "ICP/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ICPTRY"
    },
    {
        "description": "Binance JASMYETH",
        "displaySymbol": "JASMY/ETH",
        "hasWM": true,
        "symbol": "BINANCE:JASMYETH"
    },
    {
        "description": "Binance LINABNB",
        "displaySymbol": "LINA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LINABNB"
    },
    {
        "description": "Binance OOKIETH",
        "displaySymbol": "OOKI/ETH",
        "hasWM": true,
        "symbol": "BINANCE:OOKIETH"
    },
    {
        "description": "Binance ROSEETH",
        "displaySymbol": "ROSE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ROSEETH"
    },
    {
        "description": "Binance UMABUSD",
        "displaySymbol": "UMA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:UMABUSD"
    },
    {
        "description": "Binance UNIETH",
        "displaySymbol": "UNI/ETH",
        "hasWM": true,
        "symbol": "BINANCE:UNIETH"
    },
    {
        "description": "Binance XTZETH",
        "displaySymbol": "XTZ/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XTZETH"
    },
    {
        "description": "Binance LOKABTC",
        "displaySymbol": "LOKA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LOKABTC"
    },
    {
        "description": "Binance LOKABNB",
        "displaySymbol": "LOKA/BNB",
        "hasWM": true,
        "symbol": "BINANCE:LOKABNB"
    },
    {
        "description": "Binance LOKABUSD",
        "displaySymbol": "LOKA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LOKABUSD"
    },
    {
        "description": "Binance LOKAUSDT",
        "displaySymbol": "LOKA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LOKAUSDT"
    },
    {
        "description": "Binance ATOMBRL",
        "displaySymbol": "ATOM/BRL",
        "hasWM": true,
        "symbol": "BINANCE:ATOMBRL"
    },
    {
        "description": "Binance BNBUST",
        "displaySymbol": "BNB/UST",
        "hasWM": true,
        "symbol": "BINANCE:BNBUST"
    },
    {
        "description": "Binance CRVETH",
        "displaySymbol": "CRV/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CRVETH"
    },
    {
        "description": "Binance HIGHBNB",
        "displaySymbol": "HIGH/BNB",
        "hasWM": true,
        "symbol": "BINANCE:HIGHBNB"
    },
    {
        "description": "Binance NEARRUB",
        "displaySymbol": "NEAR/RUB",
        "hasWM": true,
        "symbol": "BINANCE:NEARRUB"
    },
    {
        "description": "Binance ROSETRY",
        "displaySymbol": "ROSE/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ROSETRY"
    },
    {
        "description": "Binance SCRTUSDT",
        "displaySymbol": "SCRT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:SCRTUSDT"
    },
    {
        "description": "Binance API3BTC",
        "displaySymbol": "API3/BTC",
        "hasWM": true,
        "symbol": "BINANCE:API3BTC"
    },
    {
        "description": "Binance API3BUSD",
        "displaySymbol": "API3/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:API3BUSD"
    },
    {
        "description": "Binance API3USDT",
        "displaySymbol": "API3/USDT",
        "hasWM": true,
        "symbol": "BINANCE:API3USDT"
    },
    {
        "description": "Binance BTTCUSDT",
        "displaySymbol": "BTTC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BTTCUSDT"
    },
    {
        "description": "Binance BTTCUSDC",
        "displaySymbol": "BTTC/USDC",
        "hasWM": true,
        "symbol": "BINANCE:BTTCUSDC"
    },
    {
        "description": "Binance BTTCTRY",
        "displaySymbol": "BTTC/TRY",
        "hasWM": true,
        "symbol": "BINANCE:BTTCTRY"
    },
    {
        "description": "Binance ACABTC",
        "displaySymbol": "ACA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ACABTC"
    },
    {
        "description": "Binance ACABUSD",
        "displaySymbol": "ACA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ACABUSD"
    },
    {
        "description": "Binance ACAUSDT",
        "displaySymbol": "ACA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ACAUSDT"
    },
    {
        "description": "Binance ANCBTC",
        "displaySymbol": "ANC/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ANCBTC"
    },
    {
        "description": "Binance ANCBUSD",
        "displaySymbol": "ANC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ANCBUSD"
    },
    {
        "description": "Binance ANCUSDT",
        "displaySymbol": "ANC/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ANCUSDT"
    },
    {
        "description": "Binance BDOTDOT",
        "displaySymbol": "BDOT/DOT",
        "hasWM": true,
        "symbol": "BINANCE:BDOTDOT"
    },
    {
        "description": "Binance XNOBTC",
        "displaySymbol": "XNO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:XNOBTC"
    },
    {
        "description": "Binance XNOETH",
        "displaySymbol": "XNO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:XNOETH"
    },
    {
        "description": "Binance XNOBUSD",
        "displaySymbol": "XNO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:XNOBUSD"
    },
    {
        "description": "Binance XNOUSDT",
        "displaySymbol": "XNO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:XNOUSDT"
    },
    {
        "description": "Binance COSTRY",
        "displaySymbol": "COS/TRY",
        "hasWM": true,
        "symbol": "BINANCE:COSTRY"
    },
    {
        "description": "Binance KAVAETH",
        "displaySymbol": "KAVA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:KAVAETH"
    },
    {
        "description": "Binance MCBNB",
        "displaySymbol": "MC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:MCBNB"
    },
    {
        "description": "Binance ONETRY",
        "displaySymbol": "ONE/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ONETRY"
    },
    {
        "description": "Binance WOOBTC",
        "displaySymbol": "WOO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:WOOBTC"
    },
    {
        "description": "Binance WOOBNB",
        "displaySymbol": "WOO/BNB",
        "hasWM": true,
        "symbol": "BINANCE:WOOBNB"
    },
    {
        "description": "Binance WOOBUSD",
        "displaySymbol": "WOO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:WOOBUSD"
    },
    {
        "description": "Binance WOOUSDT",
        "displaySymbol": "WOO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:WOOUSDT"
    },
    {
        "description": "Binance CELRETH",
        "displaySymbol": "CELR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:CELRETH"
    },
    {
        "description": "Binance PEOPLEBNB",
        "displaySymbol": "PEOPLE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:PEOPLEBNB"
    },
    {
        "description": "Binance SLPBNB",
        "displaySymbol": "SLP/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SLPBNB"
    },
    {
        "description": "Binance SPELLBNB",
        "displaySymbol": "SPELL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:SPELLBNB"
    },
    {
        "description": "Binance SPELLTRY",
        "displaySymbol": "SPELL/TRY",
        "hasWM": true,
        "symbol": "BINANCE:SPELLTRY"
    },
    {
        "description": "Binance TFUELBUSD",
        "displaySymbol": "TFUEL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TFUELBUSD"
    },
    {
        "description": "Binance AXSTRY",
        "displaySymbol": "AXS/TRY",
        "hasWM": true,
        "symbol": "BINANCE:AXSTRY"
    },
    {
        "description": "Binance DARTRY",
        "displaySymbol": "DAR/TRY",
        "hasWM": true,
        "symbol": "BINANCE:DARTRY"
    },
    {
        "description": "Binance NEARTRY",
        "displaySymbol": "NEAR/TRY",
        "hasWM": true,
        "symbol": "BINANCE:NEARTRY"
    },
    {
        "description": "Binance IDEXBNB",
        "displaySymbol": "IDEX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:IDEXBNB"
    },
    {
        "description": "Binance ALPINEEUR",
        "displaySymbol": "ALPINE/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ALPINEEUR"
    },
    {
        "description": "Binance ALPINETRY",
        "displaySymbol": "ALPINE/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ALPINETRY"
    },
    {
        "description": "Binance ALPINEUSDT",
        "displaySymbol": "ALPINE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ALPINEUSDT"
    },
    {
        "description": "Binance ALPINEBTC",
        "displaySymbol": "ALPINE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ALPINEBTC"
    },
    {
        "description": "Binance TUSDT",
        "displaySymbol": "T/USDT",
        "hasWM": true,
        "symbol": "BINANCE:TUSDT"
    },
    {
        "description": "Binance TBUSD",
        "displaySymbol": "T/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:TBUSD"
    },
    {
        "description": "Binance API3BNB",
        "displaySymbol": "API3/BNB",
        "hasWM": true,
        "symbol": "BINANCE:API3BNB"
    },
    {
        "description": "Binance BETAETH",
        "displaySymbol": "BETA/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BETAETH"
    },
    {
        "description": "Binance INJTRY",
        "displaySymbol": "INJ/TRY",
        "hasWM": true,
        "symbol": "BINANCE:INJTRY"
    },
    {
        "description": "Binance TLMBNB",
        "displaySymbol": "TLM/BNB",
        "hasWM": true,
        "symbol": "BINANCE:TLMBNB"
    },
    {
        "description": "Binance ASTRBUSD",
        "displaySymbol": "ASTR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ASTRBUSD"
    },
    {
        "description": "Binance ASTRUSDT",
        "displaySymbol": "ASTR/USDT",
        "hasWM": true,
        "symbol": "BINANCE:ASTRUSDT"
    },
    {
        "description": "Binance API3TRY",
        "displaySymbol": "API3/TRY",
        "hasWM": true,
        "symbol": "BINANCE:API3TRY"
    },
    {
        "description": "Binance GLMRBNB",
        "displaySymbol": "GLMR/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GLMRBNB"
    },
    {
        "description": "Binance MBOXTRY",
        "displaySymbol": "MBOX/TRY",
        "hasWM": true,
        "symbol": "BINANCE:MBOXTRY"
    },
    {
        "description": "Binance NBTBIDR",
        "displaySymbol": "NBT/BIDR",
        "hasWM": true,
        "symbol": "BINANCE:NBTBIDR"
    },
    {
        "description": "Binance NBTUSDT",
        "displaySymbol": "NBT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NBTUSDT"
    },
    {
        "description": "Binance GMTBTC",
        "displaySymbol": "GMT/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GMTBTC"
    },
    {
        "description": "Binance GMTBNB",
        "displaySymbol": "GMT/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GMTBNB"
    },
    {
        "description": "Binance GMTBUSD",
        "displaySymbol": "GMT/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GMTBUSD"
    },
    {
        "description": "Binance GMTUSDT",
        "displaySymbol": "GMT/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GMTUSDT"
    },
    {
        "description": "Binance ANCBNB",
        "displaySymbol": "ANC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:ANCBNB"
    },
    {
        "description": "Binance ATOMEUR",
        "displaySymbol": "ATOM/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ATOMEUR"
    },
    {
        "description": "Binance GALAEUR",
        "displaySymbol": "GALA/EUR",
        "hasWM": true,
        "symbol": "BINANCE:GALAEUR"
    },
    {
        "description": "Binance KSMETH",
        "displaySymbol": "KSM/ETH",
        "hasWM": true,
        "symbol": "BINANCE:KSMETH"
    },
    {
        "description": "Binance UMATRY",
        "displaySymbol": "UMA/TRY",
        "hasWM": true,
        "symbol": "BINANCE:UMATRY"
    },
    {
        "description": "Binance KDABTC",
        "displaySymbol": "KDA/BTC",
        "hasWM": true,
        "symbol": "BINANCE:KDABTC"
    },
    {
        "description": "Binance KDABUSD",
        "displaySymbol": "KDA/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KDABUSD"
    },
    {
        "description": "Binance KDAUSDT",
        "displaySymbol": "KDA/USDT",
        "hasWM": true,
        "symbol": "BINANCE:KDAUSDT"
    },
    {
        "description": "Binance APEUSDT",
        "displaySymbol": "APE/USDT",
        "hasWM": true,
        "symbol": "BINANCE:APEUSDT"
    },
    {
        "description": "Binance APEBUSD",
        "displaySymbol": "APE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:APEBUSD"
    },
    {
        "description": "Binance APEBTC",
        "displaySymbol": "APE/BTC",
        "hasWM": true,
        "symbol": "BINANCE:APEBTC"
    },
    {
        "description": "Binance ALPINEBUSD",
        "displaySymbol": "ALPINE/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ALPINEBUSD"
    },
    {
        "description": "Binance LUNAGBP",
        "displaySymbol": "LUNA/GBP",
        "hasWM": true,
        "symbol": "BINANCE:LUNAGBP"
    },
    {
        "description": "Binance NEAREUR",
        "displaySymbol": "NEAR/EUR",
        "hasWM": true,
        "symbol": "BINANCE:NEAREUR"
    },
    {
        "description": "Binance TWTTRY",
        "displaySymbol": "TWT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:TWTTRY"
    },
    {
        "description": "Binance WAVESEUR",
        "displaySymbol": "WAVES/EUR",
        "hasWM": true,
        "symbol": "BINANCE:WAVESEUR"
    },
    {
        "description": "Binance APEEUR",
        "displaySymbol": "APE/EUR",
        "hasWM": true,
        "symbol": "BINANCE:APEEUR"
    },
    {
        "description": "Binance APEGBP",
        "displaySymbol": "APE/GBP",
        "hasWM": true,
        "symbol": "BINANCE:APEGBP"
    },
    {
        "description": "Binance APETRY",
        "displaySymbol": "APE/TRY",
        "hasWM": true,
        "symbol": "BINANCE:APETRY"
    },
    {
        "description": "Binance BSWUSDT",
        "displaySymbol": "BSW/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BSWUSDT"
    },
    {
        "description": "Binance BSWBUSD",
        "displaySymbol": "BSW/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BSWBUSD"
    },
    {
        "description": "Binance BSWBNB",
        "displaySymbol": "BSW/BNB",
        "hasWM": true,
        "symbol": "BINANCE:BSWBNB"
    },
    {
        "description": "Binance APEBNB",
        "displaySymbol": "APE/BNB",
        "hasWM": true,
        "symbol": "BINANCE:APEBNB"
    },
    {
        "description": "Binance GMTBRL",
        "displaySymbol": "GMT/BRL",
        "hasWM": true,
        "symbol": "BINANCE:GMTBRL"
    },
    {
        "description": "Binance GMTETH",
        "displaySymbol": "GMT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GMTETH"
    },
    {
        "description": "Binance JASMYTRY",
        "displaySymbol": "JASMY/TRY",
        "hasWM": true,
        "symbol": "BINANCE:JASMYTRY"
    },
    {
        "description": "Binance SANTOSBUSD",
        "displaySymbol": "SANTOS/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:SANTOSBUSD"
    },
    {
        "description": "Binance APEAUD",
        "displaySymbol": "APE/AUD",
        "hasWM": true,
        "symbol": "BINANCE:APEAUD"
    },
    {
        "description": "Binance BIFIUSDT",
        "displaySymbol": "BIFI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:BIFIUSDT"
    },
    {
        "description": "Binance GMTEUR",
        "displaySymbol": "GMT/EUR",
        "hasWM": true,
        "symbol": "BINANCE:GMTEUR"
    },
    {
        "description": "Binance IMXBNB",
        "displaySymbol": "IMX/BNB",
        "hasWM": true,
        "symbol": "BINANCE:IMXBNB"
    },
    {
        "description": "Binance RUNEETH",
        "displaySymbol": "RUNE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:RUNEETH"
    },
    {
        "description": "Binance AVAXGBP",
        "displaySymbol": "AVAX/GBP",
        "hasWM": true,
        "symbol": "BINANCE:AVAXGBP"
    },
    {
        "description": "Binance MULTIBTC",
        "displaySymbol": "MULTI/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MULTIBTC"
    },
    {
        "description": "Binance MULTIBUSD",
        "displaySymbol": "MULTI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MULTIBUSD"
    },
    {
        "description": "Binance MULTIUSDT",
        "displaySymbol": "MULTI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MULTIUSDT"
    },
    {
        "description": "Binance APEETH",
        "displaySymbol": "APE/ETH",
        "hasWM": true,
        "symbol": "BINANCE:APEETH"
    },
    {
        "description": "Binance BSWETH",
        "displaySymbol": "BSW/ETH",
        "hasWM": true,
        "symbol": "BINANCE:BSWETH"
    },
    {
        "description": "Binance FILTRY",
        "displaySymbol": "FIL/TRY",
        "hasWM": true,
        "symbol": "BINANCE:FILTRY"
    },
    {
        "description": "Binance FTMEUR",
        "displaySymbol": "FTM/EUR",
        "hasWM": true,
        "symbol": "BINANCE:FTMEUR"
    },
    {
        "description": "Binance GMTGBP",
        "displaySymbol": "GMT/GBP",
        "hasWM": true,
        "symbol": "BINANCE:GMTGBP"
    },
    {
        "description": "Binance ZILTRY",
        "displaySymbol": "ZIL/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ZILTRY"
    },
    {
        "description": "Binance GMTTRY",
        "displaySymbol": "GMT/TRY",
        "hasWM": true,
        "symbol": "BINANCE:GMTTRY"
    },
    {
        "description": "Binance WAVESTRY",
        "displaySymbol": "WAVES/TRY",
        "hasWM": true,
        "symbol": "BINANCE:WAVESTRY"
    },
    {
        "description": "Binance BTCUST",
        "displaySymbol": "BTC/UST",
        "hasWM": true,
        "symbol": "BINANCE:BTCUST"
    },
    {
        "description": "Binance ASTRBTC",
        "displaySymbol": "ASTR/BTC",
        "hasWM": true,
        "symbol": "BINANCE:ASTRBTC"
    },
    {
        "description": "Binance ASTRETH",
        "displaySymbol": "ASTR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ASTRETH"
    },
    {
        "description": "Binance BSWTRY",
        "displaySymbol": "BSW/TRY",
        "hasWM": true,
        "symbol": "BINANCE:BSWTRY"
    },
    {
        "description": "Binance FTTETH",
        "displaySymbol": "FTT/ETH",
        "hasWM": true,
        "symbol": "BINANCE:FTTETH"
    },
    {
        "description": "Binance FUNBNB",
        "displaySymbol": "FUN/BNB",
        "hasWM": true,
        "symbol": "BINANCE:FUNBNB"
    },
    {
        "description": "Binance PORTOBUSD",
        "displaySymbol": "PORTO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PORTOBUSD"
    },
    {
        "description": "Binance STEEMUSDT",
        "displaySymbol": "STEEM/USDT",
        "hasWM": true,
        "symbol": "BINANCE:STEEMUSDT"
    },
    {
        "description": "Binance ZILEUR",
        "displaySymbol": "ZIL/EUR",
        "hasWM": true,
        "symbol": "BINANCE:ZILEUR"
    },
    {
        "description": "Binance APEBRL",
        "displaySymbol": "APE/BRL",
        "hasWM": true,
        "symbol": "BINANCE:APEBRL"
    },
    {
        "description": "Binance AUDIOTRY",
        "displaySymbol": "AUDIO/TRY",
        "hasWM": true,
        "symbol": "BINANCE:AUDIOTRY"
    },
    {
        "description": "Binance BTTCBUSD",
        "displaySymbol": "BTTC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:BTTCBUSD"
    },
    {
        "description": "Binance GMTAUD",
        "displaySymbol": "GMT/AUD",
        "hasWM": true,
        "symbol": "BINANCE:GMTAUD"
    },
    {
        "description": "Binance MBLBUSD",
        "displaySymbol": "MBL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MBLBUSD"
    },
    {
        "description": "Binance MOBUSDT",
        "displaySymbol": "MOB/USDT",
        "hasWM": true,
        "symbol": "BINANCE:MOBUSDT"
    },
    {
        "description": "Binance MOBBUSD",
        "displaySymbol": "MOB/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:MOBBUSD"
    },
    {
        "description": "Binance MOBBTC",
        "displaySymbol": "MOB/BTC",
        "hasWM": true,
        "symbol": "BINANCE:MOBBTC"
    },
    {
        "description": "Binance NEXOUSDT",
        "displaySymbol": "NEXO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:NEXOUSDT"
    },
    {
        "description": "Binance NEXOBUSD",
        "displaySymbol": "NEXO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:NEXOBUSD"
    },
    {
        "description": "Binance NEXOBTC",
        "displaySymbol": "NEXO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:NEXOBTC"
    },
    {
        "description": "Binance REIUSDT",
        "displaySymbol": "REI/USDT",
        "hasWM": true,
        "symbol": "BINANCE:REIUSDT"
    },
    {
        "description": "Binance REIBNB",
        "displaySymbol": "REI/BNB",
        "hasWM": true,
        "symbol": "BINANCE:REIBNB"
    },
    {
        "description": "Binance REIETH",
        "displaySymbol": "REI/ETH",
        "hasWM": true,
        "symbol": "BINANCE:REIETH"
    },
    {
        "description": "Binance GALUSDT",
        "displaySymbol": "GAL/USDT",
        "hasWM": true,
        "symbol": "BINANCE:GALUSDT"
    },
    {
        "description": "Binance GALBUSD",
        "displaySymbol": "GAL/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:GALBUSD"
    },
    {
        "description": "Binance GALBNB",
        "displaySymbol": "GAL/BNB",
        "hasWM": true,
        "symbol": "BINANCE:GALBNB"
    },
    {
        "description": "Binance GALBTC",
        "displaySymbol": "GAL/BTC",
        "hasWM": true,
        "symbol": "BINANCE:GALBTC"
    },
    {
        "description": "Binance JASMYEUR",
        "displaySymbol": "JASMY/EUR",
        "hasWM": true,
        "symbol": "BINANCE:JASMYEUR"
    },
    {
        "description": "Binance KNCBNB",
        "displaySymbol": "KNC/BNB",
        "hasWM": true,
        "symbol": "BINANCE:KNCBNB"
    },
    {
        "description": "Binance SHIBGBP",
        "displaySymbol": "SHIB/GBP",
        "hasWM": true,
        "symbol": "BINANCE:SHIBGBP"
    },
    {
        "description": "Binance GALEUR",
        "displaySymbol": "GAL/EUR",
        "hasWM": true,
        "symbol": "BINANCE:GALEUR"
    },
    {
        "description": "Binance GALTRY",
        "displaySymbol": "GAL/TRY",
        "hasWM": true,
        "symbol": "BINANCE:GALTRY"
    },
    {
        "description": "Binance LDOBUSD",
        "displaySymbol": "LDO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LDOBUSD"
    },
    {
        "description": "Binance LDOUSDT",
        "displaySymbol": "LDO/USDT",
        "hasWM": true,
        "symbol": "BINANCE:LDOUSDT"
    },
    {
        "description": "Binance LDOBTC",
        "displaySymbol": "LDO/BTC",
        "hasWM": true,
        "symbol": "BINANCE:LDOBTC"
    },
    {
        "description": "Binance ENSTRY",
        "displaySymbol": "ENS/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ENSTRY"
    },
    {
        "description": "Binance DAREUR",
        "displaySymbol": "DAR/EUR",
        "hasWM": true,
        "symbol": "BINANCE:DAREUR"
    },
    {
        "description": "Binance DARETH",
        "displaySymbol": "DAR/ETH",
        "hasWM": true,
        "symbol": "BINANCE:DARETH"
    },
    {
        "description": "Binance ALGOETH",
        "displaySymbol": "ALGO/ETH",
        "hasWM": true,
        "symbol": "BINANCE:ALGOETH"
    },
    {
        "description": "Binance ALGOTRY",
        "displaySymbol": "ALGO/TRY",
        "hasWM": true,
        "symbol": "BINANCE:ALGOTRY"
    },
    {
        "description": "Binance GALETH",
        "displaySymbol": "GAL/ETH",
        "hasWM": true,
        "symbol": "BINANCE:GALETH"
    },
    {
        "description": "Binance EPXUSDT",
        "displaySymbol": "EPX/USDT",
        "hasWM": true,
        "symbol": "BINANCE:EPXUSDT"
    },
    {
        "description": "Binance EPXBUSD",
        "displaySymbol": "EPX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:EPXBUSD"
    },
    {
        "description": "Binance RUNETRY",
        "displaySymbol": "RUNE/TRY",
        "hasWM": true,
        "symbol": "BINANCE:RUNETRY"
    },
    {
        "description": "Binance GALBRL",
        "displaySymbol": "GAL/BRL",
        "hasWM": true,
        "symbol": "BINANCE:GALBRL"
    },
    {
        "description": "Binance STEEMBUSD",
        "displaySymbol": "STEEM/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:STEEMBUSD"
    },
    {
        "description": "Binance CVCBUSD",
        "displaySymbol": "CVC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:CVCBUSD"
    },
    {
        "description": "Binance REIBUSD",
        "displaySymbol": "REI/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:REIBUSD"
    },
    {
        "description": "Binance DREPBUSD",
        "displaySymbol": "DREP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:DREPBUSD"
    },
    {
        "description": "Binance AKROBUSD",
        "displaySymbol": "AKRO/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:AKROBUSD"
    },
    {
        "description": "Binance PUNDIXBUSD",
        "displaySymbol": "PUNDIX/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:PUNDIXBUSD"
    },
    {
        "description": "Binance LUNCBUSD",
        "displaySymbol": "LUNC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:LUNCBUSD"
    },
    {
        "description": "Binance USTCBUSD",
        "displaySymbol": "USTC/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:USTCBUSD"
    },
    {
        "description": "Binance OPBTC",
        "displaySymbol": "OP/BTC",
        "hasWM": true,
        "symbol": "BINANCE:OPBTC"
    },
    {
        "description": "Binance OPBUSD",
        "displaySymbol": "OP/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OPBUSD"
    },
    {
        "description": "Binance OPUSDT",
        "displaySymbol": "OP/USDT",
        "hasWM": true,
        "symbol": "BINANCE:OPUSDT"
    },
    {
        "description": "Binance OGBUSD",
        "displaySymbol": "OG/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:OGBUSD"
    },
    {
        "description": "Binance KEYBUSD",
        "displaySymbol": "KEY/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:KEYBUSD"
    },
    {
        "description": "Binance ASRBUSD",
        "displaySymbol": "ASR/BUSD",
        "hasWM": true,
        "symbol": "BINANCE:ASRBUSD"
    }
];