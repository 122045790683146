import axios from 'axios';
import { binanceSymbolsData, oandaSymbolsData } from './symbols';
import { BACKEND_API } from '../../utils/config';
import { cryptoSymbolsPolygon, forexSymbolsPolygon, indicesSymbolsPolygon } from './symbolsPolygon';
import { w3cwebsocket as W3CWebSocket } from "websocket";

// Setup config
const supportedResolutions = ["1", "5", "15", "30", "60", "240", "D", "2D", "3D", "W", "3W", "M", "6M"]; 
const config = {
    supported_resolutions: supportedResolutions
}; 

// history data
const history = {};

let availableSymbols = [];

const oandaSymbols = oandaSymbolsData;
const binanceSymbols = binanceSymbolsData;

// Add this counter outside the datafeed object
let getBarsCallCount = 0;
let lastCallTime = 0;
const CALL_THRESHOLD = 2000; // 2 seconds threshold

// Add these variables at the top level
let websocket = null;
let subscribers = {};
let currentParams = null;

const datafeed = {
    onReady: cb => {
        
        availableSymbols = [...oandaSymbols, ...binanceSymbols]
        // setTimeout(() => 
        cb(config)
        // , 0);

    },    

    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
        const results = [];
        const lowerCaseInput = userInput.toLowerCase();
        for (const v of availableSymbols) {
            const exchange_name = v.symbol.split(':')[0];
            if (exchange_name === "OANDA") {
                if (v.description.toLowerCase().indexOf(lowerCaseInput) > -1) {
                const symbol = v.symbol.split(':')[1];
                const r = {
                    symbol: symbol,
                    full_name: v.symbol,
                    description: v.description,
                    exchange: exchange_name,
                    ticker: v.symbol
                };
                results.push(r);
                }
            }
            if (exchange_name === "BINANCE") {
                if (v.description.toLowerCase().indexOf(lowerCaseInput) > -1) {
                    const r = {
                        symbol: v.displaySymbol,
                        full_name: v.symbol,
                        description: v.description,
                        exchange: exchange_name,
                        ticker: v.symbol
                    };
                    results.push(r);
                }
            }
        }
        onResultReadyCallback(results);
    },
    

    resolveSymbol: (symbolName, ResolveCallback, ErrorCallback ) => {
        const exchange_name = symbolName.split(':')[0];
        const symbol = symbolName.split(':')[1];
        const symbol_stub = {}
        if (exchange_name === 'OANDA') {
            symbol.replace('_', '/');
            const symbol_stub_item = {
                name: symbol,
                description: symbolName,
                type: 'Forex',
                session: '24x7',
                timezone: 'Etc/UTC',
                ticker: symbolName,
                exchange: exchange_name,
                minmov: 1,
                pricescale: 100000000,
                has_intraday: true,
                intraday_multipliers: ['1', '5', '15', '30', '60', '240'],
                supported_resolution: supportedResolutions,
                volume_precision: 8,
                data_status: 'streaming',
            };
            symbol_stub.data = symbol_stub_item;
        }

        if (exchange_name === 'BINANCE') {
            const symbol_stub_item = {
                name: symbol,
                description: symbolName,
                type: 'Crypto',
                session: '24x7',
                timezone: 'Etc/UTC',
                ticker: symbolName,
                exchange: exchange_name,
                minmov: 1,
                pricescale: 100000000,
                has_intraday: true,
                intraday_multipliers: ['1', '5', '15', '30', '60', '240'],
                supported_resolution: supportedResolutions,

                volume_precision: 8,
                data_status: 'streaming',
            };
            symbol_stub.data = symbol_stub_item;
        }
    
        ResolveCallback(symbol_stub.data);
    },

    getBars: function(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
        const currentTime = Date.now();
        
        // Reset counter if too much time has passed since last call
        if (currentTime - lastCallTime > CALL_THRESHOLD) {
            getBarsCallCount = 0;
        }
        
        getBarsCallCount++;
        lastCallTime = currentTime;

        // Reload page if called 3 times in sequence
        if (getBarsCallCount >= 5) {
            window.location.reload();
            return;
        }

        const symbol = symbolInfo.ticker.split(':')[1].replace('_', '');
        
        // Convert resolution to standardized format
        let timespan = 'minute';  // default timespan
        let multiplier = 1;       // default multiplier
        
        switch(resolution) {
            case '1':
                multiplier = 1;
                timespan = 'minute';
                break;
            case '5':
                multiplier = 5;
                timespan = 'minute';
                break;
            case '15':
                multiplier = 15;
                timespan = 'minute';
                break;
            case '30':
                multiplier = 30;
                timespan = 'minute';
                break;
            case '60':
                multiplier = 1;
                timespan = 'hour';
                break;
            case '240':
                multiplier = 4;
                timespan = 'hour';
                break;
            case '1D':
            case 'D':
                multiplier = 1;
                timespan = 'day';
                break;
            case '2D':
                multiplier = 2;
                timespan = 'day';
                break;
            case '3D':
                multiplier = 3;
                timespan = 'day';
                break;
            case 'W':
                multiplier = 1;
                timespan = 'week';
                break;
            case '3W':
                multiplier = 3;
                timespan = 'week';
                break;
            case 'M':
                multiplier = 1;
                timespan = 'month';
                break;
            case '6M':
                multiplier = 6;
                timespan = 'month';
                break;

            default:
                multiplier = 1;
                timespan = 'minute';
                break;
        }

        if (symbolInfo.exchange === 'OANDA') {
            symbol.replace('/', '_');
        }

        let toDateObj = new Date(periodParams.to * 1000);
        toDateObj.setDate(toDateObj.getDate() + 2);  // Add one day
        let toDate = toDateObj.toISOString().split('T')[0];

        // Calculate fromDate as 1 year before toDate
        let fromDateFull = new Date(periodParams.to * 1000);

        let fromDate = fromDateFull;

        fromDate.setFullYear(fromDate.getFullYear() - 1);

        fromDate = fromDate.toISOString().split('T')[0];


        if (fromDateFull.getFullYear() > 2021) {

            let usingPolygon = true;

            let polygonUrl = `${BACKEND_API}v2/aggs/ticker/`;

            if (forexSymbolsPolygon.includes(`C:${symbol}`)) {
                polygonUrl += `C:${symbol}`;
            } else if (cryptoSymbolsPolygon.includes(`X:${symbol}`)) {
                polygonUrl += `X:${symbol}`;
            } else if (indicesSymbolsPolygon.includes(`I:${symbol}`)) {
                polygonUrl += `I:${symbol}`;
            }else {
                usingPolygon = false;
            }

            if (usingPolygon) {

                polygonUrl += `/range/${multiplier}/${timespan}/${fromDate}/${toDate}?adjusted=true&sort=desc&limit=50000`;
                
                axios.get(polygonUrl)
                    .then(response => {
                        const data = response.data;
                            // Transforming the API response into the correct format
                        const bars = data.results.map(item => ({
                            time: item.t, // Assuming time is already in milliseconds
                            low: item.l,
                            high: item.h,
                            open: item.o,
                            close: item.c
                        })).reverse();

                        const lastBar = bars[bars.length - 1];
                        history[symbol] = { lastBar: lastBar };

                        onHistoryCallback(bars);
                    })
                    .catch(err => {
                        onErrorCallback(err);
                    });
            }else{
                const api_key = 'cpbk8f9r01qqbq2a9i20cpbk8f9r01qqbq2a9i2g'; 
                const api_root = 'https://finnhub.io/api/v1';
                const symbol = symbolInfo.ticker

                if (resolution === '1D') {
                    resolution = 'D';
                }
                if (symbolInfo.exchange === 'OANDA') {
                    symbol.replace('/', '_');
                }
                // Convert dates to timestamps
                const fromTimestamp = Math.floor(new Date(fromDate).getTime() / 1000);
                const toTimestamp = Math.floor(new Date(toDate).getTime() / 1000);

                const qs = {
                    symbol: symbol,
                    resolution: resolution,
                    from: fromTimestamp,
                    to: toTimestamp,
                    token: api_key,
                };

                var URL = '';
                if (symbolInfo.type === 'Crypto') {
                    URL = '/crypto/candle';
                }
                if (symbolInfo.type === 'Forex') {
                    URL = '/forex/candle';
                }
                axios.get(api_root + URL, { params: qs })
                    .then(response => {
                        const data = response.data;
                        if (data['s'] === 'ok') {
                            // Transforming the API response into the correct format
                            const bars = data.t.map((time, index) => ({
                                time: time * 1000, // Assuming time is in seconds and needs to be in milliseconds
                                low: data.l[index],
                                high: data.h[index],
                                open: data.o[index],
                                close: data.c[index]
                            }));

                            const lastBar = bars[bars.length - 1];
                            history[symbol] = { lastBar: lastBar };


                            onHistoryCallback(bars);

                        } else {

                            onHistoryCallback([], { noData: true });
                    
                        }
                    })
                    .catch(err => {
                        onErrorCallback(err);
                    });
            }
        }
    },

    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        // Close existing websocket if it exists
        if (!websocket) {
            websocket = new W3CWebSocket('wss://socket.polygon.io/forex');
        }

        if (currentParams){
            websocket.send(JSON.stringify({
                action: "unsubscribe",
                params: currentParams
            }));

            currentParams = `CAS.${symbolInfo.ticker.split(':')[1].replace('_', '/')}`;

            websocket.send(JSON.stringify({
                action: "subscribe",
                params: currentParams
            }));
        }
        
        websocket.onopen = () => {
            currentParams = `CAS.${symbolInfo.ticker.split(':')[1].replace('_', '/')}`;
            // Authenticate with your API key
            websocket.send(JSON.stringify({ 
                action: "auth", 
                params: "zMQuLKl1AA1O9Y36NZRfqlEo36K3uwDM" 
            }));
            
            // Subscribe to the symbol
            websocket.send(JSON.stringify({
                action: "subscribe",
                params: currentParams
            }));
        };

        websocket.onmessage = (message) => {
            if (message.data.length > 0) {
                var data = JSON.parse(message.data);
                data = data[0];
                
                if (symbolInfo.ticker.split(':')[1].replace('_', '/') === data.pair) {
                    // Convert the Polygon data format to TradingView bar format
                    const bar = {
                        time: data.s, // timestamp
                        open: data.o,
                        high: data.h,
                        low: data.l,
                        close: data.c,
                        volume: data.v
                    };

                    if (subscribers[symbolInfo.ticker]) {
                        subscribers[symbolInfo.ticker].forEach(cb => cb(bar));
                    }
                }
            }
        };

        websocket.onerror = (error) => {
        };

        websocket.onclose = () => {
            websocket = null;
        };

        // Store the callback for this subscriber
        if (!subscribers[symbolInfo.ticker]) {
            subscribers[symbolInfo.ticker] = [];
        }
        subscribers[symbolInfo.ticker].push(onRealtimeCallback);

        return subscriberUID;
    },
};

export default datafeed;